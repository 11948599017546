import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icone-lupa',
  templateUrl: './icone-lupa.component.html',
  styleUrls: ['./icone-lupa.component.css']
})
export class IconeLupaComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
