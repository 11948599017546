<div class="visualizacao-normal">
<mat-table [dataSource]="dataSource" >

  <ng-container matColumnDef="linha">
    <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.linha}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="chave" >
    <mat-header-cell *matHeaderCellDef> CHAVE </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="chave"> {{row.chave}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="erro" >
    <mat-header-cell *matHeaderCellDef> ERRO </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="erro"><span class="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded
        bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900"
    > {{row.erro}} </span> </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>


  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="7">Nenhum erro encontrado na importação do arquivo</td>
  </tr>
</mat-table>
</div>
<div class="visualizacao-mobile">
<mat-card *ngFor="let data of dataSource.data">
  <div class="flex-column">
    <div class="row">
      <div class="col-4 flex justify-content-start">#{{data.linha}}</div>
      <div class="col-8 flex justify-content-end">
        <span class="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded
        bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900"
        >{{data.erro}}</span></div>
    </div>
    <div class="row texto-cinza text-break"><label>{{data.chave}}</label></div>
  </div>
</mat-card>
</div>
