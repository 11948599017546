<div class="row mt-3">
  <h4 class="color-primary fw-bold">{{titulo}}</h4>
  <div class=" col-12 col-lg-4 col-md-6 mb-3" *ngFor="let card of cards">
    <mat-card class="card-selecao-empresa background-white" [class.cursor-pointer]="card.chave" (click)="abrirCard(card.chave)" [matTooltip]="card.tooltip">
      <div class="flex h-100">
        <div class="div-icone align-self-center justify-content-start" >
          <div class="matriz-background bg-dashboard color-white text-center flex">
            <app-icone-nota class="m-auto"></app-icone-nota>
          </div>
        </div>
        <div class="col-9">
          <mat-card-title-group>
            <mat-card-title class="mb-0">
              <h3 class="card-title mb-0 card-texto">
                {{card.texto}}
              </h3>
            </mat-card-title>
            <mat-card-subtitle class="mt-0 mb-0">
              <h1 class="card-title card-contador fw-bold pb-0 mb-0">{{card!.valor | number}}</h1>
            </mat-card-subtitle>
          </mat-card-title-group>
        </div>
      </div>
    </mat-card>
  </div>
</div>
