import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LoadingConstant} from "../../_core/model/loading-constant";
import {FormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AutenticacaoService} from "../../_service/autenticacao.service";
import {StorageService} from "../../_service/storage.service";
import {MensagemService} from "../../_service/mensagem.service";
import {TokenService} from "../../_service/token.service";
import {InatividadeService} from "../../_service/inatividade.service";

@Component({
  selector: 'app-formulario-esqueceu-senha',
  templateUrl: './formulario-esqueceu-senha.component.html',
  styleUrls: ['./formulario-esqueceu-senha.component.css']
})
export class FormularioEsqueceuSenhaComponent implements OnInit {
  formularioRecuperarSenha = this.formBuilder.group({
    email: [null, Validators.required],
  });

  @Output() avancarEmailEnviado: EventEmitter<any> = new EventEmitter<any>();
  @Output() retornarTelaLogin: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public formBuilder: FormBuilder,
    public autenticacaoService: AutenticacaoService,
    public mensagemService: MensagemService,
    public tokenService: TokenService,
  ) { }

  ngOnInit(): void {
  }

  recuperarSenha() {
    LoadingConstant.loading = true;
    if (this.formularioRecuperarSenha.valid) {
      this.autenticacaoService.solicitarSenhaRecuperacao(this.formularioRecuperarSenha.value).then( response => {
        this.mensagemService.sucesso(response.mensagem);
        this.formularioRecuperarSenha.patchValue({
          email: null
        })
        this.avancarEmailEnviado.emit();
        LoadingConstant.loading = false;
      }).catch( error => {
        this.mensagemService.falha(error)
        LoadingConstant.loading = false;
      })
    }
  }

  retornaLogin() {
    this.retornarTelaLogin.emit();
  }
}
