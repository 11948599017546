import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {SortDirection} from "@angular/material/sort";
import * as moment from "moment";
import {BaseService} from "../common/base.service";

@Injectable({
  providedIn: 'root'
})
export class RelatorioService extends BaseService{

  constructor(public http: HttpClient
  ) {
    super(http);
  }

  public downloadRelatorioNotasUltimasManifestacao(empresaId: number, chave: string, dataInicio: string, dataFim: string, status: string,
                                                   field: string | undefined, order: SortDirection | undefined) {
    let params = new HttpParams()
      .set('chave', chave)

    if (status != null && status != '0') {
      params = params.set('status', status)

    }
    if (field && order) {
      params = params.set("field", field).set("order", order);
    }
    if (dataInicio != null) { params = params.set('dataInicio', moment(dataInicio).format('DD/MM/YYYY')); }
    if (dataFim != null) { params = params.set('dataFim', moment(dataFim).format('DD/MM/YYYY')); }
    const options = {
      responseType: 'blob',
      params: params
    };
    return super.get(`relatorio/empresa/${empresaId}/manifestacao`, options);
  }
}
