import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StorageService} from "../_service/storage.service";

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoGuard implements CanActivate {
  constructor(
    public router: Router,
    public storageService: StorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole = route.data.expectedRole;
    const usuario = this.storageService.buscarUsuario();
    if (usuario != null) {
      if (!expectedRole.includes(usuario.perfil)) {
        alert('PERFIL NÃO AUTORIZADO');
        return false;
      }
      return true;
    }
    console.log(state.url);
    this.router.navigate(['/login'], {queryParams: {callback: state.url}});

    return false;
  }

}
