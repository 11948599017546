<div class="flex">
  <div>
    <div class="div-icone-modal div-icone-{{data.color}}">
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
        <path d="M10 7V9M10 13H10.01M3.07183 17H16.9282C18.4678 17 19.4301 15.3333 18.6603 14L11.7321
        2C10.9623 0.666667 9.03778 0.666667 8.26798 2L1.33978 14C0.56998 15.3333 1.53223 17 3.07183
        17Z" class="svg-{{data.color}}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
  <div>
    <h1 mat-dialog-title class="text-break">{{ data.titulo}} </h1>
    <div mat-dialog-content class="text-break">
      {{ data.texto }}
    </div>
  </div>
</div>
    <div mat-dialog-actions class="flex justify-content-end">
      <button mat-button class="bt-form bt-cancelar" type="button" (click)="fechar(false)">
        CANCELAR
      </button>
      <button cdkFocusInitial mat-button color="{{data.color}}" class="bt-form" type="button" (click)="fechar(true)">
        {{data.operacao}}
      </button>
    </div>

