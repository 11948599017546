import {Component, Input, OnInit} from '@angular/core';
import { LoadingConstant } from "../../_core/model/loading-constant";

@Component({
  selector: 'app-modal-loading',
  templateUrl: './modal-loading.component.html',
  styleUrls: ['./modal-loading.component.css']
})
export class ModalLoadingComponent implements OnInit {
  displayStyle = "block";

  constructor() { }

  ngOnInit(): void {
  }

  getLoading(): boolean {
    return LoadingConstant.loading;
  }
}
