import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmpresaDto} from "../../_core/model/empresa-dto";
import {StorageService} from "../../_service/storage.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Input() empresaSelecionada: EmpresaDto | null = null;

  @Output() emitirToggle = new EventEmitter;
  @Output() emitirTrocaEmpresa = new EventEmitter;
  usuarioNome: string = ''

  constructor(
    public storageService: StorageService,
    public router: Router
  ) { }

  ngOnInit(): void {
    let usuario = this.storageService.buscarUsuario();
    this.usuarioNome = usuario.nome;
  }

  isSelecao(): boolean {
    return this.router.url == "/cliente/selecao";
  }

  toggleNavbar() {
    this.emitirToggle.emit();
  }

  trocarEmpresa() {
    this.emitirTrocaEmpresa.emit();
  }
}
