import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icone-manifestacao-lote',
  templateUrl: './icone-manifestacao-lote.component.html',
  styleUrls: ['./icone-manifestacao-lote.component.css']
})
export class IconeManifestacaoLoteComponent implements OnInit {
  @Input() centralizar = false;
  @Input() color = '#1D3C85';
  constructor() { }

  ngOnInit(): void {
  }

}
