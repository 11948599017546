import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ClienteRoutingModule} from './cliente-routing.module';
import {ClienteComponent} from './cliente.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {EmpresaListaComponent} from './empresa/empresa-lista/empresa-lista.component';
import {EmpresaFormComponent} from './empresa/empresa-form/empresa-form.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {HttpClientModule} from "@angular/common/http";
import {ComponentsModule} from "../_componentes/components.module";
import {NotaFiscalListaEmpresaComponent} from './nota-fiscal/nota-fiscal-lista-empresa/nota-fiscal-lista-empresa.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCardModule} from "@angular/material/card";
import {SelecaoEmpresaComponent} from './selecao-empresa/selecao-empresa.component';
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatMenuModule} from "@angular/material/menu";
import {MatSortModule} from "@angular/material/sort";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {InserirNotaComponent} from './nota-fiscal/inserir-nota/inserir-nota.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatDividerModule} from "@angular/material/divider";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatStepperModule} from "@angular/material/stepper";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {MatRadioModule} from "@angular/material/radio";
import { UsuarioListaComponent } from './usuario/usuario-lista/usuario-lista.component';
import { UsuarioFormComponent } from './usuario/usuario-form/usuario-form.component';
import { AcaoEmpresaComponent } from './empresa/acao-empresa/acao-empresa.component';
import { DatePickerFiltroNotasComponent } from './nota-fiscal/componentes/date-picker-filtro-notas/date-picker-filtro-notas.component';
import { ModalInfoMobileComponent } from './nota-fiscal/componentes/modal-info-mobile/modal-info-mobile.component';
import {TabelaNotasComponent} from "./nota-fiscal/componentes/tabela-notas/tabela-notas.component";
import {ModalManifestacaoComponent} from "./nota-fiscal/componentes/modal-manifestacao/modal-manifestacao.component";
import {AcoesNotasComponent} from "./nota-fiscal/componentes/acoes-notas/acoes-notas.component";
import {MatDialogModule} from "@angular/material/dialog";
import { ModalListaManifestacaoComponent } from './nota-fiscal/componentes/modal-lista-manifestacao/modal-lista-manifestacao.component';
import { NotaFiscalUltimaManifestacaoComponent } from './nota-fiscal/nota-fiscal-ultima-manifestacao/nota-fiscal-ultima-manifestacao.component';
import { RedirecionamentoCancelamentoComponent } from './redirecionamento-cancelamento/redirecionamento-cancelamento.component';
import { NotaFiscalExportacaoComponent } from './nota-fiscal/nota-fiscal-exportacao/nota-fiscal-exportacao.component';
import {
  TabelaArquivoExportadoComponent
} from "./nota-fiscal/componentes/tabela-arquivo-exportado/tabela-arquivo-exportado.component";



export const FORMATO_DATA = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
@NgModule({
    declarations: [
        ClienteComponent,
        DashboardComponent,
        EmpresaListaComponent,
        EmpresaFormComponent,
        NotaFiscalListaEmpresaComponent,
        SelecaoEmpresaComponent,
        InserirNotaComponent,
        UsuarioListaComponent,
        UsuarioFormComponent,
        AcaoEmpresaComponent,
        DatePickerFiltroNotasComponent,
        ModalInfoMobileComponent,
        TabelaNotasComponent,
        ModalManifestacaoComponent,
        AcoesNotasComponent,
        ModalListaManifestacaoComponent,
        NotaFiscalUltimaManifestacaoComponent,
        RedirecionamentoCancelamentoComponent,
        NotaFiscalExportacaoComponent,
        TabelaArquivoExportadoComponent
    ],
    imports: [
        CommonModule,
        ClienteRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        NgxMaskModule.forRoot(),
        ComponentsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatPaginatorModule,
        MatCardModule,
        MatIconModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FlexLayoutModule,
        MatMenuModule,
        MatSortModule,
        MatCheckboxModule,
        MatTabsModule,
        MatDividerModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatStepperModule,
        MatRadioModule,
        MatDialogModule,

    ],
    exports: [
        ModalInfoMobileComponent
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: FORMATO_DATA},
    ]

})
export class ClienteModule { }
