import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalCertificadoComponent} from './modal-certificado/modal-certificado.component';
import {NgxMaskModule} from "ngx-mask";
import {ReactiveFormsModule} from "@angular/forms";
import {ModalLoadingComponent} from './modal-loading/modal-loading.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SidebarComponent} from './sidebar/sidebar.component';
import {MatDividerModule} from "@angular/material/divider";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {AlterarSenhaComponent} from './alterar-senha/alterar-senha.component';
import {MatIconModule} from "@angular/material/icon";
import {DialogoConfirmacaoComponent} from './dialogo-confirmacao/dialogo-confirmacao.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSelectModule} from "@angular/material/select";
import {InputArquivoComponent} from './input-arquivo/input-arquivo.component';
import {TabelaUsuariosComponent} from './tabela-usuarios/tabela-usuarios.component';
import {FormularioUsuariosComponent} from './formulario-usuarios/formulario-usuarios.component';
import {CardDashboardComponent} from './card-dashboard/card-dashboard.component';
import {MatCardModule} from "@angular/material/card";
import {ToggleComponent} from './tailwind/toggle/toggle.component';
import {PaginatorComponent} from './tailwind/paginator/paginator.component';
import {SnackbarComponent} from './material/snackbar/snackbar.component';
import {IconeSairComponent} from './icones/icone-sair/icone-sair.component';
import {IconeEmpresaComponent} from './icones/icone-empresa/icone-empresa.component';
import {IconeCertificadoExpiradoComponent} from './icones/icone-certificado-expirado/icone-certificado-expirado.component';
import {IconeNotaComponent} from './icones/icone-nota/icone-nota.component';
import {IconeLupaComponent} from './icones/icone-lupa/icone-lupa.component';
import {IconeDashboardComponent} from './icones/icone-dashboard/icone-dashboard.component';
import {IconeUsuariosComponent} from './icones/icone-usuarios/icone-usuarios.component';
import {IconeManifestacaoComponent} from './icones/icone-manifestacao/icone-manifestacao.component';
import {TabelaExcecaoArquivoComponent} from './tabela-excecao-arquivo/tabela-excecao-arquivo.component';
import {IconeFiltroComponent} from './icones/icone-filtro/icone-filtro.component';
import {IconeManifestacaoLoteComponent} from './icones/icone-manifestacao-lote/icone-manifestacao-lote.component';
import { TelaNenhumResultadoComponent } from './tela-nenhum-resultado/tela-nenhum-resultado.component';
import { BotaoLimparComponent } from './botao-limpar/botao-limpar.component';
import { AcoesUsuariosComponent } from './acoes-usuarios/acoes-usuarios.component';
import { IconeChaveComponent } from './icones/icone-chave/icone-chave.component';
import { IconeConfirmacaoComponent } from './icones/icone-confirmacao/icone-confirmacao.component';
import { NavbarComponent } from './navbar/navbar.component';
import {MatMenuModule} from "@angular/material/menu";
import { BotaoExportarNotaComponent } from './botao-exportar-nota/botao-exportar-nota.component';


@NgModule({
  declarations: [
    ModalCertificadoComponent,
    ModalLoadingComponent,
    SidebarComponent,
    AlterarSenhaComponent,
    DialogoConfirmacaoComponent,
    InputArquivoComponent,
    TabelaUsuariosComponent,
    FormularioUsuariosComponent,
    CardDashboardComponent,
    ToggleComponent,
    PaginatorComponent,
    SnackbarComponent,
    IconeSairComponent,
    IconeEmpresaComponent,
    IconeCertificadoExpiradoComponent,
    IconeNotaComponent,
    IconeLupaComponent,
    IconeDashboardComponent,
    IconeUsuariosComponent,
    IconeManifestacaoComponent,
    TabelaExcecaoArquivoComponent,
    IconeFiltroComponent,
    IconeManifestacaoLoteComponent,
    TelaNenhumResultadoComponent,
    BotaoLimparComponent,
    AcoesUsuariosComponent,
    IconeChaveComponent,
    IconeConfirmacaoComponent,
    NavbarComponent,
    BotaoExportarNotaComponent
  ],
    imports: [
        CommonModule,
        NgxMaskModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatInputModule,
        MatButtonModule,
        RouterModule,
        MatIconModule,
        MatDialogModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSelectModule,
        MatCardModule,
        MatMenuModule
    ],
  exports: [
    ModalCertificadoComponent,
    ModalLoadingComponent,
    SidebarComponent,
    InputArquivoComponent,
    TabelaUsuariosComponent,
    FormularioUsuariosComponent,
    CardDashboardComponent,
    ToggleComponent,
    PaginatorComponent,
    IconeSairComponent,
    IconeEmpresaComponent,
    IconeCertificadoExpiradoComponent,
    IconeLupaComponent,
    TabelaExcecaoArquivoComponent,
    IconeFiltroComponent,
    TelaNenhumResultadoComponent,
    BotaoLimparComponent,
    IconeConfirmacaoComponent,
    IconeManifestacaoLoteComponent,
    IconeChaveComponent,
    IconeManifestacaoComponent,
    NavbarComponent,
    BotaoExportarNotaComponent
  ]
})
export class ComponentsModule { }
