<div class="row">
  <div class="col-12">
    <h1 class="titulo-pagina">Arquivos de Nota Fiscal Exportador</h1>
  </div>
</div>
<form class="corpo-pagina" (submit)="filtrar()" [formGroup]="formulario">
  <div class="searchcontainer ">
    <div class="search-part">
      <div class="row">

        <app-date-picker-filtro-notas class="w-auto" (alterarDatas)="alterarData($event)"
                                      [dataInicio]="this.formulario.value.dataInicio" [dataFim]="hoje"></app-date-picker-filtro-notas>

        <button mat-raised-button class="bt-form botao-tamanho-limitado" color="primary" type="submit"
                [disabled]="formulario.invalid">
          <app-icone-lupa></app-icone-lupa>
        </button>
        <app-botao-limpar class="w-auto  me-auto" *ngIf="filtrado" (click)="limparFiltros()"></app-botao-limpar>
      </div>
    </div>
  </div>
</form>
<app-tabela-arquivo-exportado
  [dataSource]="dataSource" [selecionados]="selecionados" [tamanho]="tamanho" (busca)="buscar($event.field, $event.order)"
  [totalItens]="totalItens" [pagina]="pagina" [allChecked]="allChecked" [sortActive]="'dataCriacao'"
  (limpaSelecao)="limparSelecao()" (selecionaTodos)="selecionarTodos($event)" (alteraPagina)="paginacao($event)"
></app-tabela-arquivo-exportado>




<div class="modal modal-backdrop modal-form" id="exampleModal" role="dialog" [ngStyle]="{'display':displayStyleModal}">
  <div class="modal-dialog" role="document">
    <h2 mat-dialog-title>Exportação de Notas para Excel</h2>
    <div class="modal-content">
        <p>Você deseja iniciar o processo de exportação de notas para excel?</p>
        <p>Este processo pode demorar a depender no número de notas selecionadas pelo pesquisa.</p>
        <p>Você poderá acompanhar o processo na oção Notas Exportadas no menu. <br>Assim que concluída a exportação o arquivo ficará disponível para download.</p>
    </div>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
    </mat-dialog-actions>
  </div>
</div>


<div class="modal modal-backdrop modal-form" id="modalFilro" role="dialog" [ngStyle]="{'display':displayStyleModalFiltro}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="needs-validation"  [formGroup]=" formulario" novalidate>
        <div class="modal-body">

          <app-date-picker-filtro-notas class="w-auto" (alterarDatas)="alterarData($event)"
                                        [dataInicio]="this.formulario.value.dataInicio"
                                        [dataFim]="hoje" [classe]="'input-mobile'" [label]="'Data Emissão'"
          ></app-date-picker-filtro-notas>

          <button mat-raised-button color="primary" class="bt-cadastro bt-modal-100" type="button"
                  (click)="filtrar()">
            <app-icone-lupa></app-icone-lupa>
            <label>Filtrar</label>
          </button>

          <button mat-button class="color-primary bt-modal-100" type="button" (click)="limparFiltros()">
            <label>Limpar Filtro</label>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
