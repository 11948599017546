import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-botao-exportar-nota',
  templateUrl: './botao-exportar-nota.component.html',
  styleUrls: ['./botao-exportar-nota.component.css']
})
export class BotaoExportarNotaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
