import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ValidacaoCnpjService} from "../../../_service/validacao/validacao-cnpj.service";
import {EmpresasService} from "../../../_service/web/empresas.service";
import {EmpresaDto} from "../../../_core/model/empresa-dto";
import {LoadingConstant} from "../../../_core/model/loading-constant";
import {MensagemService} from "../../../_service/mensagem.service";
import {UF} from "../../../_core/model/uf";
import {MatStepper} from "@angular/material/stepper";
import {MatRadioChange} from "@angular/material/radio";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-empresa-form',
  templateUrl: './empresa-form.component.html',
  styleUrls: ['./empresa-form.component.css']
})
export class EmpresaFormComponent implements OnInit {
  id!: number;
  ufs = new UF().ufs;
  empresas: EmpresaDto[] = [];
  tipos = [{value: 'MATRIZ', label: 'Matriz'}, {value: 'FILIAL', label: 'Filial'}]
  etapa: 'certificado' | 'empresa' = 'certificado';
  arquivoInput: File | null = null;
  esconderSenha = true;
  cnpjAnterior: String = '';
  formularioPesquisa: FormGroup = this.formBuilder.group({
    arquivo: [null, Validators.required],
    tipoEmpresa: [null, Validators.required],
    senha: [null, Validators.compose([Validators.required])],
    matrizId: [null]
  })
  formulario: FormGroup = this.formBuilder.group({
    cnpj: [null, Validators.compose([Validators.required, ValidacaoCnpjService.validaCnpj])],
    razaoSocial: [null, Validators.compose([Validators.maxLength(100)])],
    nomeFantasia: [null, Validators.compose([Validators.maxLength(100)])],
    email: [null, Validators.compose([Validators.email])],
    telefone: [null, Validators.compose([Validators.maxLength(15)])],
    logradouro: [null, Validators.compose([Validators.maxLength(100)])],
    numero: [null, Validators.compose([Validators.maxLength(50)])],
    cep: [null, Validators.compose([Validators.maxLength(9)])],
    bairro: [null, Validators.compose([Validators.maxLength(50)])],
    municipio: [null, Validators.compose([Validators.maxLength(50)])],
    uf: [null, Validators.compose([Validators.maxLength(2), Validators.required])],
    senhaCertificado: [null],
    tipoEmpresa: [null, Validators.required],
    matrizId: [null]
  })
  // @ts-ignore
  @ViewChild('stepper',{read:MatStepper}) stepper:MatStepper;
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public empresaService: EmpresasService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.listarEmpresas();
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.etapa = 'empresa';
        this.id = params.id;
        this.carregarDadosEmpresa(params.id);
      }
    });
  }


  listarEmpresas(): void {
    let empresas = []
    LoadingConstant.loading = true;
    this.empresaService.listarEmpresas(true).then(empresasResponse => {
      empresas = empresasResponse;
      this.empresas = empresas;
      LoadingConstant.loading = false;
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  pesquisar(): void {
    if (this.formularioPesquisa.valid) {
      this.formulario.patchValue({
        tipoEmpresa: this.formularioPesquisa.value.tipoEmpresa,
        matrizId: this.formularioPesquisa.value.matrizId
      })
      if (this.formularioPesquisa.value.arquivo != null) {
        LoadingConstant.loading = true;
        this.empresaService.buscarDadosCertificado(this.formularioPesquisa.value).then((empresa: EmpresaDto) => {
          this.formulario.patchValue({
            cnpj: empresa.cnpj,
            senhaCertificado: this.formularioPesquisa.value.senha
          })
          this.etapa = 'empresa';
          LoadingConstant.loading = false;
        }).catch(error => {
          this.mensagemService.falha(error);
          LoadingConstant.loading = false;
        })
      } else {
        this.etapa = 'empresa'
      }
    }
  }

  limpar(): void {
    this.formularioPesquisa.reset();
    this.etapa = 'certificado';
    this.formulario.reset();
  }

  selecionarArquivo(file: File) {
    this.arquivoInput = file
    this.formularioPesquisa.patchValue({
      arquivo: file
    });
  }

  removerArquivo() {
    this.arquivoInput = null;
    this.formularioPesquisa.patchValue({
      arquivo: null
    });
  }

  carregarDadosEmpresa(id: number): void {
    LoadingConstant.loading = true;
    this.empresaService.buscarEmpresaPeloId(id).then( (empresa: EmpresaDto) => {
      this.formulario.patchValue({
        cnpj: empresa.cnpj,
        razaoSocial: empresa.razaoSocial,
        nomeFantasia: empresa.nomeFantasia,
        email: empresa.email,
        telefone: empresa.telefone,
        logradouro: empresa.logradouro,
        numero: empresa.numero,
        cep: empresa.cep,
        bairro: empresa.bairro,
        municipio: empresa.municipio,
        uf: empresa.uf,
        matrizId: empresa.matrizId,
        tipoEmpresa: empresa.tipoEmpresa
      })
      this.cnpjAnterior = empresa.cnpj;
      LoadingConstant.loading = false;
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  salvar(): void {
    if (this.formulario.valid) {
      LoadingConstant.loading = true;
      this.empresaService.buscarEmpresaPeloCnpj(this.formulario.value.cnpj).then( response => {
        if(this.formulario.value.cnpj == this.cnpjAnterior){
          this.editar();
        } else {
          this.mensagemService.falhaSimples('CNPJ já utilizado por outra empresa.');
          LoadingConstant.loading = false;
        }

      }).catch( result => {
        if(result.status == 400){
          if (this.id != null) {
            this.editar();
          } else {
            this.criar();
          }
        }

      })



    }
  }

  criar(): void {
    this.empresaService.criarEmpresa(this.formulario.value, this.formularioPesquisa.value).then( response => {
      this.sucesso();
    }).catch(error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  editar(): void {
    this.empresaService.editarEmpresa(this.formulario.value, this.id).then( response => {
      this.sucesso();
    }).catch(error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  sucesso(): void {
    LoadingConstant.loading = false;
    this.cancelar();
  }

  cancelar(): void {
    this.router.navigate(['/cliente/empresas']);
  }

  selecionaTipo(evento: MatSelectChange) {
    if (evento.value == 'MATRIZ') {
      this.formularioPesquisa.controls.arquivo.addValidators([Validators.required]);
      this.formularioPesquisa.controls.senha.addValidators([Validators.required]);
      this.formularioPesquisa.controls.arquivo.updateValueAndValidity();
      this.formularioPesquisa.controls.senha.updateValueAndValidity();
    } else if (evento.value == 'FILIAL') {
      this.formularioPesquisa.controls.arquivo.clearValidators();
      this.formularioPesquisa.controls.senha.clearValidators();
      this.formularioPesquisa.controls.arquivo.updateValueAndValidity();
      this.formularioPesquisa.controls.senha.updateValueAndValidity();
    }
  }

  formularioInvalido(): boolean {
    if (this.formularioPesquisa.value.tipoEmpresa == 'FILIAL' &&
            (this.formularioPesquisa.value.arquivo == null && this.formularioPesquisa.value.matrizId == null) ) {
      return true;
    }
    return this.formularioPesquisa.invalid
  }
}
