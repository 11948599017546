import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-date-picker-filtro-notas',
  templateUrl: './date-picker-filtro-notas.component.html',
  styleUrls: ['./date-picker-filtro-notas.component.css']
})
export class DatePickerFiltroNotasComponent implements OnInit {
  @Input() label = 'Emissão'
  @Input() classe = 'input-filtro-data';
  @Input() dataInicio = new Date();
  @Input() dataFim = new Date();

  @Output() alterarDatas = new EventEmitter<{dataInicio: Date, dataFim: Date}>();
  constructor() { }

  ngOnInit(): void {
  }

  alterarData() {
    this.alterarDatas.emit({dataInicio: this.dataInicio, dataFim: this.dataFim})
  }
}
