import { Injectable } from '@angular/core';
import {BaseService} from "../common/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DocumentoService extends BaseService{

  constructor(public http: HttpClient
  ) {
    super(http);
  }

  public downloadDocumento(chave: string) {
    const options = {
      responseType: 'blob',
      params: new HttpParams().append('nome', chave)
    };
    return super.get(`documentos`, options);
  }

  getFileName(response: any): string {
    const contentDisposition = response.headers.get('content-disposition');
    return contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"+/g, '');
  }
}
