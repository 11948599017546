<svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!filtroAtivo">
  <path d="M13 6.33333C19.6274 6.33333 25 5.13943 25 3.66667C25 2.19391 19.6274 1 13 1C6.37258 1 1 2.19391 1
  3.66667C1 5.13943 6.37258 6.33333 13 6.33333Z" stroke="#1D3C85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 3.6665C1 6.63984 6.16133 12.5652 8.808 15.4065C9.77888 16.4357 10.3239 17.7944 10.3333
  19.2092V26.3332L15.6667 23.6665V19.2092C15.6667 17.7945 16.228 16.4425 17.192 15.4065C19.84 12.5652 25
  6.64117 25 3.6665" stroke="#1D3C85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="filtroAtivo">
  <path d="M25.9 5.4251V2.1251C25.9 2.1251 19.9357 0.475098 13.4 0.475098C6.86431 0.475098 0.900025 2.1251
  0.900025 2.1251V5.4251L9.8286 17.0246V28.5251C9.8286
  28.5251 12.0072 28.3766 13.8465 27.5516C15.6857 26.7266 16.9715 25.2251 16.9715 25.2251V17.0246L25.9 5.4251Z" fill="#1D3C85"/>
</svg>
