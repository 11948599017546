import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EmpresaFormComponent} from "./empresa/empresa-form/empresa-form.component";
import {EmpresaListaComponent} from "./empresa/empresa-lista/empresa-lista.component";
import {NotaFiscalListaEmpresaComponent} from "./nota-fiscal/nota-fiscal-lista-empresa/nota-fiscal-lista-empresa.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {SelecaoEmpresaComponent} from "./selecao-empresa/selecao-empresa.component";
import {AlterarSenhaComponent} from "../_componentes/alterar-senha/alterar-senha.component";
import {InserirNotaComponent} from "./nota-fiscal/inserir-nota/inserir-nota.component";
import {UsuarioListaComponent} from "./usuario/usuario-lista/usuario-lista.component";
import {UsuarioFormComponent} from "./usuario/usuario-form/usuario-form.component";
import {NotaFiscalUltimaManifestacaoComponent} from "./nota-fiscal/nota-fiscal-ultima-manifestacao/nota-fiscal-ultima-manifestacao.component";
import {RedirecionamentoCancelamentoComponent} from "./redirecionamento-cancelamento/redirecionamento-cancelamento.component";
import {NotaFiscalExportacaoComponent} from "./nota-fiscal/nota-fiscal-exportacao/nota-fiscal-exportacao.component";

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'dashboard', component: DashboardComponent},
  { path: 'selecao', component: SelecaoEmpresaComponent},
  { path: 'empresas', component: EmpresaListaComponent},
  { path: 'empresas/novo', component: EmpresaFormComponent},
  { path: 'empresas/editar/:id', component: EmpresaFormComponent},
  { path: 'usuarios', component: UsuarioListaComponent},
  { path: 'usuarios/novo', component: UsuarioFormComponent},
  { path: 'usuarios/editar/:id', component: UsuarioFormComponent},
  { path: 'notas/lista', component: NotaFiscalListaEmpresaComponent},
  { path: 'notas/canceladas/:idEmpresa', component: RedirecionamentoCancelamentoComponent},
  { path: 'notas/manifestacao/lista', component: NotaFiscalUltimaManifestacaoComponent},
  { path: 'notas/arquivosexportados/lista', component: NotaFiscalExportacaoComponent},
  { path: 'notas/lista/:chave', component: NotaFiscalListaEmpresaComponent},
  { path: 'notas/inserir', component: InserirNotaComponent},
  { path: 'alterar-senha', component: AlterarSenhaComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClienteRoutingModule { }
