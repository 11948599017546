import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PaginatorDto} from "../../../../_core/model/paginator-dto";
import {MatTableDataSource} from "@angular/material/table";
import {NotaFiscalDto} from "../../../../_core/model/nota-fiscal-dto";
import {SelectionModel} from "@angular/cdk/collections";
import {TipoManifestacaoDto} from "../../../../_core/model/tipo-manifestacao-dto";
import {BuscaDTO} from "../tabela-notas/tabela-notas.component";
import {ArquivosExportadoDto} from "../../../../_core/model/arquivos-exportado-dto";
import {NotaFiscalService} from "../../../../_service/web/nota-fiscal.service";
import {MensagemService} from "../../../../_service/mensagem.service";
import {Clipboard} from "@angular/cdk/clipboard";
import {MatDialog} from "@angular/material/dialog";
import {Sort, SortDirection} from "@angular/material/sort";

export interface BuscaXLSDTO { field: string, order: SortDirection}
@Component({
  selector: 'app-tabela-arquivo-exportado',
  templateUrl: './tabela-arquivo-exportado.component.html',
  styleUrls: ['./tabela-arquivo-exportado.component.css']
})
export class TabelaArquivoExportadoComponent implements OnInit {

  @Output() busca: EventEmitter<BuscaXLSDTO> = new EventEmitter<BuscaXLSDTO>();
  @Output() limpaSelecao: EventEmitter<any> = new EventEmitter<any>();
  @Output() selecionaTodos: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() alteraPagina: EventEmitter<PaginatorDto> = new EventEmitter<PaginatorDto>();
  @Output() selecionaUm: EventEmitter<{checked: boolean, chave: string}> = new EventEmitter<any>();
  @Output() atualizarLista: EventEmitter<{selecaoTodos: boolean}> = new EventEmitter<{selecaoTodos: boolean}>();
  @Output() clickModalFiltro: EventEmitter<any> = new EventEmitter<any>();
  @Input() dataSource: MatTableDataSource<ArquivosExportadoDto> = new MatTableDataSource<ArquivosExportadoDto>();
  @Input() selecionados = new SelectionModel<string>(true, []);
  @Input() tamanho = 5;
  @Input() totalItens = 0;
  @Input() pagina = 0;
  @Input() allChecked = false;
  @Input() paginacao = true;
  @Input() ordenacao = true;
  @Input() textoVazio = 'Nenhuma nota fiscal encontrada para os parâmetros de busca';
  @Input() local: 'lista' | 'insercao' = 'lista';
  @Input() sortActive = '';
  @Input() filtrado = false;
  @Input() exibirFiltro = true;
  @Input() exibirBotaoAcaoLote = true;
  @Input() opcoesExibidas = ['geradorPor', 'dataCriacao', 'dataGeracao', 'status', 'pathArquivo' ]

  tipos: TipoManifestacaoDto[] = [];

  aquivos: ArquivosExportadoDto[] = [];

  @ViewChild('checkbox') checkbox: any;

  @Input() colunasExibidas: string[] = ['geradorPor', 'dataCriacao', 'dataGeracao', 'status', 'pathArquivo',  'download'];
  displayStyleModalManifestacao = "none";
  displayStylePopupMobileInfo = 'none';
  displayStyleListaManifestacao = 'none';
  chavesSelecionadas: string[] = [];
  chaveListaManifestacao: string = '';
  constructor(
    public notaFiscalService: NotaFiscalService,
    public mensagemService: MensagemService,
    public clipboard: Clipboard,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  paginar(evento: PaginatorDto): void {
    this.limparSelecao();
    this.alteraPagina.emit(evento);
  }

  isAllSelected() {
    const numSelected = this.selecionados.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected >= numRows && this.allChecked;
  }


  selecionarTodos() {
    this.selecionaTodos.emit(true);
  }

  selecionarUm(checked: boolean, chave: string) {
    this.selecionaUm.emit({checked, chave})
  }

  selecionarUmMobile(chave: string, podeManifestar: boolean) {
    if (podeManifestar) {
      this.selecionarUm(!this.selecionados.isSelected(chave), chave);
    } else {
      this.mensagemService.falhaSimples('Você não consegue realizar ação de manifestação nessa nota')
    }
  }

  ordenar(sortState: Sort) {
    this.busca.emit({ field: sortState.active, order: sortState.direction})
  }

  limparSelecao() {
    this.checkbox.checked = false;
    this.selecionados.clear();
    this.allChecked = false;
    this.limpaSelecao.emit();
  }

  downloadArquivo(id:number, nameFile:string){
    this.notaFiscalService.downloadArquivExportado(id).then((response) => {
      const blob = new Blob([response], { type: 'application/vnd.ms-excel' });
      const url= window.URL.createObjectURL(blob);

      var anchor = document.createElement("a");
      anchor.download = nameFile;
      anchor.href = url;
      anchor.click();
      // window.open(url);
    });
  }

  width() {
    const total = 120 * this.colunasExibidas.length;
    let minimo = 80;
    if (total > minimo) {
      minimo = total;
    }
    // return 'max-width: ' + minimo + 'px !important';
    // return '';
  }

  exibirModalFiltro() {
    this.clickModalFiltro.emit();
  }
}
