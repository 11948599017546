import { Component, OnInit } from '@angular/core';
import {LoadingConstant} from "../../_core/model/loading-constant";
import {ActivatedRoute, Router} from "@angular/router";
import {EmpresasService} from "../../_service/web/empresas.service";
import {StorageService} from "../../_service/storage.service";
import {EmpresaDto} from "../../_core/model/empresa-dto";
import {MensagemService} from "../../_service/mensagem.service";

@Component({
  selector: 'app-redirecionamento-cancelamento',
  templateUrl: './redirecionamento-cancelamento.component.html',
  styleUrls: ['./redirecionamento-cancelamento.component.css']
})
export class RedirecionamentoCancelamentoComponent implements OnInit {

  constructor(
    public activatedRoute: ActivatedRoute,
    public empresaService: EmpresasService,
    public storage: StorageService,
    public router: Router,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    LoadingConstant.loading = true;
    this.activatedRoute.params.subscribe( params => {
      if (params.idEmpresa) {
        this.empresaService.buscarEmpresaPeloId(params.idEmpresa).then( (response: EmpresaDto) => {
          this.storage.salvarEmpresa(response);
          LoadingConstant.loading = false;
          this.router.navigate(['/cliente/notas/lista/CANCELADA'])
        }).catch(error => {
          LoadingConstant.loading = false;
          this.mensagemService.falha(error);
          this.router.navigate(['/cliente/selecao'])
        })
      }
    })
  }

}
