<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.43302 15.325L0.0790197 17.8C0.0571423 17.9536 0.0713152 18.1102 0.120417
    18.2574C0.169518 18.4046 0.252201 18.5384 0.361924 18.6481C0.471647 18.7578 0.605398 18.8405
    0.752596 18.8896C0.899793 18.9387 1.0564 18.9529 1.21002 18.931L3.67682 18.577C4.06002 18.524 5.00002
    16 5.00002 16C5.00002 16 5.47202 16.405 5.66502 16.466C6.07702 16.596 6.47802 16.192 6.61302 15.782L7.00002
    14.01C7.00002 14.01 7.57702 14.302 7.78602 14.345C8.05202 14.4 8.31002 14.236 8.49302 14.052C8.60295
    13.9424 8.68552 13.8085 8.73402 13.661L9.00002 12.01C9.00002 12.01 9.67502 12.197 9.90602 12.224C10.169
    12.254 10.425 12.12 10.613 11.931L11.751 10.794C12.7143 11.1062 13.7451 11.1464 14.7298 10.9103C15.7145
    10.6743 16.615 10.171 17.332 9.456C18.3617 8.42365 18.94 7.02508 18.94 5.567C18.94 4.10892 18.3617 2.71035
     17.332 1.678C16.2997 0.648316 14.9011 0.0700684 13.443 0.0700684C11.9849 0.0700684 10.5864 0.648316 9.55402
     1.678C8.83882 2.39493 8.33544 3.29539 8.09936 4.28014C7.86327 5.2649 7.90364 6.29572 8.21602 7.259L0.71502
      14.759C0.561913 14.9119 0.462901 15.1107 0.43302 15.325ZM15.504 3.506C16.0496 4.05317 16.3559 4.79432 16.3559
      5.567C16.3559 6.33968 16.0496 7.08083 15.504 7.628L11.382 3.506C11.9292 2.96044 12.6703 2.65409 13.443
      2.65409C14.2157 2.65409 14.9569 2.96044 15.504 3.506Z" [attr.fill]="fill"/>
</svg>
