import {Component, OnInit} from '@angular/core';
import {StorageService} from "../_service/storage.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  pagina: 'formulario-login' | 'formulario-esqueceu-senha' |
    'email-senha-enviado' | 'formulario-nova-senha' | 'senha-alterada' = 'formulario-login';
  hide = true;
  nomeAplicacao = environment.nomeAplicacao;
  callback = null;

  constructor(
    public storageService: StorageService,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      if (params.id) {
        this.pagina = 'formulario-nova-senha';
      }
    });
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.callback) {
        this.callback = queryParams.callback;
      }
    })
    this.storageService.limparUsuario();
    this.storageService.limparEmpresa();
  }

  retornarTelaLogin() {
    this.pagina = 'formulario-login';
  }



}
