<div class="m-auto text-recuperacao-senha">
  <h3>Receba em seu e-mail um link para recriar uma nova senha.</h3>
</div>
<form (submit)="recuperarSenha()" [formGroup]="formularioRecuperarSenha">
  <!--    <img class="mb-4" src="../assets/brand/bootstrap-logo.svg" alt="" width="72" height="57">-->

  <div class="mb-6">
    <label for="emailRecuperacao" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Digite seu Email</label>
    <input type="email" id="emailRecuperacao"
           class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                  focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-formulario-login"
           formControlName="email">
  </div>
  <button class="w-100 bt-form" mat-raised-button color="primary" type="submit">RECUPERAR SENHA</button>
  <button mat-button color="primary" class="w-100" type="button" (click)="retornaLogin()">
    <label>LEMBREI MINHA SENHA</label>
  </button>
</form>
