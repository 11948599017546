import {Component, OnInit} from '@angular/core';
import {EmpresasService} from "../../_service/web/empresas.service";
import {EmpresaDto} from "../../_core/model/empresa-dto";
import {StorageService} from "../../_service/storage.service";
import {Router} from "@angular/router";
import {LoadingConstant} from "../../_core/model/loading-constant";
import {MensagemService} from "../../_service/mensagem.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AutenticacaoService} from "../../_service/autenticacao.service";

@Component({
  selector: 'app-selecao-empresa',
  templateUrl: './selecao-empresa.component.html',
  styleUrls: ['./selecao-empresa.component.css']
})
export class SelecaoEmpresaComponent implements OnInit {
  empresas: EmpresaDto[] = [];
  status = [
    {label: 'Todas', value: 'TODAS'},
    {label: 'Ativas', value: 'ATIVAS'},
    {label: 'Inativas', value: 'INATIVAS'}
  ]
  formulario: FormGroup = this.formBuilder.group({
    filtro: [''],
    status: ['TODAS'],
    exibirApenasPendentes: [false]
  });
  pagina = 0;
  tamanho = this.storageService.buscarPaginacao('selecao-empresa');
  totalItens = 0;
  displayStyleModal = 'none';
  filtrado = false;
  pesquisado = false;
  constructor(
    public empresaService: EmpresasService,
    public storageService: StorageService,
    public autenticacaoService: AutenticacaoService,
    public router: Router,
    public mensagemService: MensagemService,
    public formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.listarEmpresas();
  }

  listarEmpresas(): void {
    let empresas = []
    LoadingConstant.loading = true;
    this.storageService.salvarPaginacao('selecao-empresa', this.tamanho);
    this.displayStyleModal = 'none'
    this.empresaService.listarEmpresasSelecao(
      this.pagina, this.tamanho,
      this.formulario.controls.filtro.value,
      this.formulario.controls.exibirApenasPendentes.value,
      this.formulario.controls.status.value
    ).then( empresasResponse => {
      empresas = empresasResponse.content;
      this.totalItens = empresasResponse.totalElements;
      this.empresas = empresas;
      this.pesquisado = true;
      LoadingConstant.loading = false;
      if (empresas.length == 0 && this.formularioSemFiltro()) {
        this.router.navigate(['/cliente/empresas/novo'])
      }
    }).catch( error => {
      this.mensagemService.falha(error);
      this.pesquisado = true;
      LoadingConstant.loading = false;
    })
  }

  selecionarEmpresa(empresa: EmpresaDto): void {
    this.storageService.salvarEmpresa(empresa);
    this.router.navigate(['/cliente/notas/lista'])
  }


  limparFiltros() {
    this.formulario.patchValue({
      filtro: '',
      status: 'TODAS',
      exibirApenasPendentes: false
    });
    this.filtrado = false;
    this.listarEmpresas();
  }

  formularioSemFiltro(): boolean {
    return this.formulario.value.filtro == '' &&
      this.formulario.value.status == 'TODAS' &&
      this.formulario.value.exibirApenasPendentes == false;
  }

  toggleManifestacaoPendente() {
    this.formulario.patchValue({
      exibirApenasPendentes: !this.formulario.value.exibirApenasPendentes
    });
  }

  paginar(evento: {pagina: number, tamanho: number}) {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarEmpresas();
  }

  logout() {
    this.autenticacaoService.logout();
  }

  abrirModalPesquisa() {
    this.displayStyleModal = 'block'
  }

  aplicandoFiltros() {
    if (this.formulario.value.filtro != '' || this.formulario.value.status != 'TODAS' || this.formulario.value.exibirApenasPendentes == true) {
      this.filtrado = true;
    }
    this.listarEmpresas();
  }
}
