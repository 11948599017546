<div class="page-header login min-vh-100 flex-column no-flex-normal">
  <div class="main-login w-100 h-100 align-self-center background-white">
    <mat-card class="card z-index-0 fadeIn3 card-border fadeInBottom card-login">
      <mat-card-header class="card-login-header">
        <div class="flex flex-column div-header-login">
          <div class="avatar-login logo w-auto">
            <img src="./assets/images/login-logo.png" width="207" height="68"/>
          </div>
          <h1 class="titulo-pagina visualizacao-normal fw-bold" *ngIf="pagina == 'formulario-login'">{{nomeAplicacao}}</h1>
          <p class="texto-developed visualizacao-normal" *ngIf="pagina == 'formulario-login'">Developed by <span>
            <a href="https://10i9.com.br/" class="color-primary" target="_blank">10i9</a>
          </span></p>
        </div>
      </mat-card-header>
      <mat-card-content class="card-body card-login-body">
        <div class="m-auto">
          <app-formulario-login
            *ngIf="pagina == 'formulario-login'" [callback]="callback"
            (abrirRecuperacaoSenha)="pagina = 'formulario-esqueceu-senha'">
          </app-formulario-login>
          <app-formulario-esqueceu-senha
            *ngIf="pagina == 'formulario-esqueceu-senha'"
            (avancarEmailEnviado)="pagina = 'email-senha-enviado'" (retornarTelaLogin)="retornarTelaLogin()">
          </app-formulario-esqueceu-senha>
          <app-email-senha-enviado
            *ngIf="pagina == 'email-senha-enviado'"
            (retornarLogin)="retornarTelaLogin()"
          ></app-email-senha-enviado>
          <app-formulario-nova-senha
            *ngIf="pagina == 'formulario-nova-senha'"
            (retornarLogin)="retornarTelaLogin()"
            (concluirNovaSenha)="this.pagina = 'senha-alterada'"
          ></app-formulario-nova-senha>
          <app-senha-alterada
            *ngIf="pagina == 'senha-alterada'"
            (retornarLogin)="retornarTelaLogin()"
          ></app-senha-alterada>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="w-100 h-100 imagem-login">
  </div>

</div>
