import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReportDto} from "../../_core/model/report-dto";

@Component({
  selector: 'app-card-dashboard',
  templateUrl: './card-dashboard.component.html',
  styleUrls: ['./card-dashboard.component.css']
})
export class CardDashboardComponent implements OnInit {
  @Input() cards: ReportDto[] = [];
  @Input() titulo: string = '';
  @Output() abreCard: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  abrirCard(chave: string) {
    this.abreCard.emit(chave)
  }

}
