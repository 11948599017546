import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  texto: string;
  titulo: string;
  color: string;
  operacao: string;
}

@Component({
  selector: 'app-dialogo-confirmacao',
  templateUrl: './dialogo-confirmacao.component.html',
  styleUrls: ['./dialogo-confirmacao.component.css']
})
export class DialogoConfirmacaoComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogoConfirmacaoComponent>
  ) { }

  ngOnInit(): void {
  }

  fechar(operacao: boolean): void {
    this.dialogRef.close(operacao);
  }
}
