import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icone-empresa',
  templateUrl: './icone-empresa.component.html',
  styleUrls: ['./icone-empresa.component.css']
})
export class IconeEmpresaComponent implements OnInit {
  @Input() color = '#1D3C85';
  constructor() { }

  ngOnInit(): void {
  }

}
