<div class="modal modal-backdrop modal-form" id="exampleModal" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

        <div class="flex total-modal text-center text-md-start">
          <div class="w-100">
            <h1 mat-dialog-title class="text-break titulo">Lista de Manifestações</h1>
            <div>
              <div mat-dialog-content class="text-break mb-3">
                <b>chave:</b> {{_chave}}
              </div>
              <div mat-dialog-content class="text-break" *ngFor="let manifestacao of manifestacoes">
                <b>{{manifestacao.tipoManifestacao}}</b> - {{manifestacao.dataManifestacao}}
              </div>
            </div>
          </div>
        </div>
        <div mat-dialog-actions class="flex justify-content-center">
          <div>
            <button mat-raised-button class="color-primary" type="button" (click)="fecharPopup()">Voltar</button>
          </div>
        </div>
    </div>
  </div>
</div>
