<label for="toggle-{{classe}}" class="inline-flex relative items-center cursor-pointer">
  <input type="checkbox" id="toggle-{{classe}}" class="sr-only peer" [disabled]="disabled" [checked]="checked" (click)="click()" >
  <div class="w-14 h-7 peer-focus:outline-none rounded-full
            peer dark:bg-gray-700 peer-checked:after:translate-x-full
            after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:rounded-full
             after:h-6 after:w-6 after:transition-all"
       [ngClass]="disabled ? 'bg-disabled-300' : isPink ? 'bg-pink-300' : 'bg-disabled-300' "
       [class.peer-checked:bg-primary]="!disabled"
  ></div>
</label>
<!--[ngClass]="{'peer-checked:bg-disabled-300' : disabled, 'bg-pink-300': isPink && d, 'bg-disabled-300': !isPink , 'peer-checked:bg-primary': !disabled}"-->
