import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {UsuarioDto} from "../../../_core/model/usuario-dto";
import {SortDirection} from "@angular/material/sort";
import {LoadingConstant} from "../../../_core/model/loading-constant";
import {UsuarioService} from "../../../_service/web/usuario.service";
import {MensagemService} from "../../../_service/mensagem.service";
import {Router} from "@angular/router";
import {MensagemSucessoDTO} from "../../../_core/model/mensagem-sucesso-dto";
import {StorageService} from "../../../_service/storage.service";

@Component({
  selector: 'app-usuario-lista',
  templateUrl: './usuario-lista.component.html',
  styleUrls: ['./usuario-lista.component.css']
})
export class UsuarioListaComponent implements OnInit {
  dataSource: MatTableDataSource<UsuarioDto> = new MatTableDataSource<UsuarioDto>();
  totalItens = 0;
  pagina = 0;
  tamanho = this.storageService.buscarPaginacao('usuario');
  constructor(
    public usuarioService: UsuarioService,
    public mensagemService: MensagemService,
    public router: Router,
    public storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.listarUsuarios(this.tamanho, this.pagina)
  }

  listarUsuarios(pageSize: number, pageIndex: number, filtro?: string, field?: string, order?: SortDirection): void {
    LoadingConstant.loading = true;
    this.pagina = pageIndex;
    this.tamanho = pageSize;
    this.storageService.salvarPaginacao('usuario', pageSize);
    this.usuarioService.listarUsuarios(filtro, pageIndex, pageSize, field, order).then( response => {
      this.dataSource.data = response.content;
      this.totalItens = response.totalElements;
      LoadingConstant.loading = false;
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  abrirCadastro() {
    this.router.navigate(['/cliente/usuarios/novo'])
  }

  abrirEdicao(id: number) {
    this.router.navigate(['/cliente/usuarios/editar/',id])
  }

  alterarStatusUsuario(operacao: 'ativar' | 'desativar', empresaId: number) {
    LoadingConstant.loading = true;
    this.usuarioService.alterarStatusUsuario(operacao, empresaId).then( (response: MensagemSucessoDTO) => {
      this.mensagemService.sucesso(response.mensagem);
      LoadingConstant.loading = false;
      this.listarUsuarios(this.tamanho, this.pagina);
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }
}
