<svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.5601 13.6836C14.1509 13.3662 14.8271 13.1855 15.5474 13.1855H15.5498C15.623 13.1855 15.6572 13.0977
  15.6035 13.0488C14.8545 12.3767 13.9989 11.8338 13.0718 11.4424C13.062 11.4375 13.0522 11.4351 13.0425
  11.4302C14.5586 10.3291 15.5449 8.53955 15.5449 6.52051C15.5449 3.17578 12.8398 0.46582 9.50244
  0.46582C6.16504 0.46582 3.4624 3.17578 3.4624 6.52051C3.4624 8.53955 4.44873 10.3291 5.96729 11.4302C5.95752
  11.4351 5.94775 11.4375 5.93799 11.4424C4.84668 11.9038 3.86768 12.5654 3.02539 13.4102C2.18797 14.2461
  1.52127 15.237 1.0625 16.3276C0.611116 17.3957 0.367509 18.54 0.344727 19.6992C0.344075 19.7253 0.348645
  19.7512 0.358169 19.7755C0.367692 19.7997 0.381975 19.8218 0.400177 19.8405C0.418379 19.8591 0.44013
   19.874 0.464151 19.8841C0.488171 19.8942 0.513974 19.8994 0.540039 19.8994H2.00244C2.10742 19.8994
   2.19531 19.814 2.19775 19.709C2.24658 17.8242 3.00098 16.0591 4.33643 14.7212C5.71582 13.3369 7.55176
   12.5752 9.50488 12.5752C10.8892 12.5752 12.2173 12.9585 13.3623 13.6763C13.3917 13.6948 13.4255 13.7052
   13.4602 13.7064C13.4949 13.7077 13.5294 13.6999 13.5601 13.6836ZM9.50488 10.7197C8.38672 10.7197 7.33447
   10.2827 6.54102 9.48926C6.15062 9.09988 5.84113 8.6371 5.63037 8.12758C5.41961 7.61807 5.31174 7.07189 5.31299
    6.52051C5.31299 5.3999 5.75 4.34521 6.54102 3.55176C7.33203 2.7583 8.38428 2.32129 9.50488 2.32129C10.6255
    2.32129 11.6753 2.7583 12.4687 3.55176C12.8591 3.94114 13.1686 4.40392 13.3794 4.91343C13.5902 5.42295 13.698
    5.96912 13.6968 6.52051C13.6968 7.64111 13.2598 8.6958 12.4687 9.48926C11.6753 10.2827 10.623 10.7197 9.50488
    10.7197ZM18.4844 16.5303H16.4336V14.4795C16.4336 14.3721 16.3457 14.2842 16.2383 14.2842H14.8711C14.7637 14.2842
    14.6758 14.3721 14.6758 14.4795V16.5303H12.625C12.5176 16.5303 12.4297 16.6182 12.4297 16.7256V18.0928C12.4297
    18.2002 12.5176 18.2881 12.625 18.2881H14.6758V20.3389C14.6758 20.4463 14.7637 20.5342 14.8711 20.5342H16.2383C16.3457
    20.5342 16.4336 20.4463 16.4336 20.3389V18.2881H18.4844C18.5918 18.2881 18.6797 18.2002 18.6797 18.0928V16.7256C18.6797
    16.6182 18.5918 16.5303 18.4844 16.5303Z" [attr.fill]="color"/>
</svg>
