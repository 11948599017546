<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.1914 15.8711H17.4751C17.3579 15.8711 17.248 15.9224 17.1748 16.0127C17.0039 16.2203
          16.8208 16.4204 16.6279 16.6109C15.8391 17.4005 14.9047 18.0298 13.8765 18.4639C12.8112 18.9139
          11.6662 19.1447 10.5098 19.1426C9.34033 19.1426 8.20751 18.9131 7.14306 18.4639C6.11481 18.0298 5.18043
          17.4005 4.39159 16.6109C3.60135 15.8239 2.97123 14.8912 2.53613 13.8643C2.08447 12.7998 1.85742 11.6695
          1.85742 10.5C1.85742 9.3306 2.08691 8.20023 2.53613 7.13578C2.9707 6.10795 3.5957 5.18265 4.39159
          4.3892C5.18749 3.59574 6.11279 2.97074 7.14306 2.53617C8.20751 2.08695 9.34033 1.85746 10.5098
          1.85746C11.6792 1.85746 12.812 2.08451 13.8765 2.53617C14.9067 2.97074 15.832 3.59574 16.6279 4.3892C16.8208
          4.58207 17.0015 4.78226 17.1748 4.98734C17.248 5.07767 17.3603 5.12894 17.4751 5.12894H19.1914C19.3452
          5.12894 19.4404 4.95805 19.355 4.82865C17.4824 1.91849 14.206 -0.00777554 10.4829 0.00199008C4.63329 0.0166385
          -0.0566468 4.76517 0.00194691 10.6075C0.0605407 16.357 4.74316 20.9981 10.5098 20.9981C14.2231 20.9981 17.4849
          19.0743 19.355 16.1714C19.438 16.042 19.3452 15.8711 19.1914 15.8711ZM21.3618 10.3462L17.8975 7.61185C17.7681
          7.50932 17.5801 7.60209 17.5801 7.76566V9.62113H9.91406C9.80663 9.62113 9.71874 9.70902 9.71874
          9.81644V11.1836C9.71874 11.2911 9.80663 11.3789 9.91406 11.3789H17.5801V13.2344C17.5801 13.398 17.7705
          13.4908 17.8975 13.3882L21.3618 10.6538C21.3852 10.6356 21.404 10.6122 21.417 10.5856C21.43 10.5589
          21.4367 10.5297 21.4367 10.5C21.4367 10.4704 21.43 10.4411 21.417 10.4145C21.404 10.3878 21.3852 10.3645 21.3618 10.3462Z" fill="#1D3C85"/>
</svg>
