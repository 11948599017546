<div class="m-auto text-recuperacao-senha">
  <h3>Crie uma nova senha.</h3>
</div>
<div *ngIf="!exibirFormulario">
  <div class="m-auto text-recuperacao-senha">
    <h3>{{ textoErro }}</h3>
  </div>
  <button class="w-100 bt-form" mat-raised-button color="primary" type="button" (click)="retornarParaLogin()">VOLTAR</button>
</div>
<form (submit)="enviar()" [formGroup]="formulario" *ngIf="exibirFormulario">
  <h5>Nome: {{nome}}<br>Email: {{email}}</h5>
  <div class="mb-6">
    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nova Senha</label>
    <input type="password" id="password"
           class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                  focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-formulario-login" formControlName="novaSenha">
  </div>
  <!--          <div class="form-floating">-->
  <!--            <mat-form-field appearance="fill">-->
  <!--              <mat-label for="novaSenha">Nova Senha</mat-label>-->
  <!--              <input id="novaSenha" matInput [type]="exibirSenha ? 'password' : 'text'" formControlName="novaSenha">-->
  <!--              <button mat-icon-button matSuffix (click)="exibirSenha = !exibirSenha" [attr.aria-label]="'Esconder Senha'" [attr.aria-pressed]="exibirSenha">-->
  <!--                <mat-icon>{{exibirSenha ? 'visibility_off' : 'visibility'}}</mat-icon>-->
  <!--              </button>-->
  <!--            </mat-form-field>-->
  <!--          </div>-->
  <div class="mb-6">
    <label for="confirmar" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Confirmar Nova Senha</label>
    <input type="password" id="confirmar"
           class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
                  focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-formulario-login" formControlName="confirmacaoNovaSenha">
  </div>
  <div class="invalid-feedback"
       *ngIf="formulario.errors?.confirmacao && (formulario.controls.confirmacaoNovaSenha?.touched || formulario.controls.confirmacaoNovaSenha?.dirty)">
    Senhas não conferem.
  </div>
  <!--          <div class="form-floating">-->
  <!--            <mat-form-field appearance="fill">-->
  <!--              <mat-label for="confirmarSenha">Confirmar Nova Senha</mat-label>-->
  <!--              <input for="confirmarSenha" matInput [type]="exibirSenha ? 'password' : 'text'" formControlName="confirmacaoNovaSenha">-->
  <!--              <button mat-icon-button matSuffix (click)="exibirSenha = !exibirSenha" [attr.aria-label]="'Esconder Senha'" [attr.aria-pressed]="exibirSenha">-->
  <!--                <mat-icon>{{exibirSenha ? 'visibility_off' : 'visibility'}}</mat-icon>-->
  <!--              </button>-->
  <!--            </mat-form-field>-->
  <!--            <div class="invalid-feedback"-->
  <!--                 *ngIf="formulario.errors?.confirmacao && (formulario.controls.confirmacaoNovaSenha?.touched || formulario.controls.confirmacaoNovaSenha?.dirty)">-->
  <!--              Senhas não conferem.-->
  <!--            </div>-->
  <!--          </div>-->

  <button class="w-100 bt-form" mat-raised-button color="primary" type="submit" [disabled]="formulario.invalid">Enviar</button>

</form>
