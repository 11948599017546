import { Component, OnInit } from '@angular/core';
import {StorageService} from "../../../_service/storage.service";
import {NotaFiscalService} from "../../../_service/web/nota-fiscal.service";
import {FormBuilder, Validators} from "@angular/forms";
import {MensagemService} from "../../../_service/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {NotaFiscalDto} from "../../../_core/model/nota-fiscal-dto";
import {SelectionModel} from "@angular/cdk/collections";
import {ListaDefaultDto} from "../../../_core/model/lista-default-dto";
import {LoadingConstant} from "../../../_core/model/loading-constant";
import {SortDirection} from "@angular/material/sort";
import {Page} from "../../../_core/model/page-dto";
import {PaginatorDto} from "../../../_core/model/paginator-dto";
import {ArquivosExportadoDto} from "../../../_core/model/arquivos-exportado-dto";

@Component({
  selector: 'app-nota-fiscal-exportacao',
  templateUrl: './nota-fiscal-exportacao.component.html',
  styleUrls: ['./nota-fiscal-exportacao.component.css']
})
export class NotaFiscalExportacaoComponent implements OnInit {

  dataSource: MatTableDataSource<ArquivosExportadoDto> = new MatTableDataSource<ArquivosExportadoDto>();
  selecionados = new SelectionModel<string>(true, []);
  naoSelecionados = new SelectionModel<string>(true, []);
  tamanho = this.storageService.buscarPaginacao('nota');
  totalItens = 0;
  pagina = 0;
  hoje = new Date();
  allChecked = false;
  status: ListaDefaultDto[] = [];
  displayStyleModal = "none";
    displayStyleModalFiltro = "none";
  filtrado = false;
  formulario = this.formBuilder.group({
    empresaId: [null, Validators.required],
    dataInicio: [new Date(this.hoje.getTime() - (30*86400000))],
    dataFim: [this.hoje],
    status: [null]
  })
  constructor(
    public storageService: StorageService,
              public notaFiscalService: NotaFiscalService,
              public formBuilder: FormBuilder,
              public mensagemService: MensagemService,
              public router: Router,
              public activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.carregarEmpresa();
  }

  carregarEmpresa(): void {
    let empresa = this.storageService.buscarEmpresa();
    if (empresa) {
      this.formulario.patchValue({
        empresaId: empresa.id
      });
      this.activatedRoute.params.subscribe(params => {
        if (params.chave) {
          this.filtrado = true;
          if (params.chave == 'AGUARDANDO_DANFE' || params.chave == 'AGUARDANDO_MANIFESTACAO') {
            this.formulario.patchValue({
              status: params.chave,
              dataInicio: null,
              dataFim: null
            });
          } else if (params.chave == 'MES') {
            this.formulario.patchValue({
              dataInicio: new Date(this.hoje.getFullYear(), this.hoje.getMonth(), 1)
            });
          }
          else if (params.chave == 'CANCELADA') {
            this.formulario.patchValue({
              dataInicio: new Date(new Date().setDate(this.hoje.getDate() - 7)),
              dataFim: this.hoje,
              status: params.chave
            });
          }
        }
      });
      this.listarArquivosExportados('dataCriacao','desc');
    } else {
      this.router.navigate(['/cliente/selecao'])
    }
  }

  listarArquivosExportados(field?: string, order?: SortDirection) {
    LoadingConstant.loading = true;
    this.notaFiscalService.listarArquivosExportados(this.formulario.value.empresaId,
      this.formulario.value.dataInicio,
      this.formulario.value.dataFim, field, order).then( (response: Page<ArquivosExportadoDto>) => {
      this.dataSource.data = response.content;
      this.totalItens = response.totalElements;
      LoadingConstant.loading = false;
    }).catch( error => {
      console.log(error)
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  buscar( field?: string, order?: SortDirection ): void {
    this.listarArquivosExportados(field, order)
    this.storageService.salvarPaginacao('nota', this.tamanho);
    this.displayStyleModal = 'none';

  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selecionados.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected >= numRows && this.allChecked;
  }


  selecionarTodos(todosSelecionados: boolean) {
    this.naoSelecionados.clear();
    if (!todosSelecionados) {
      this.selecionados.clear();
      this.allChecked = false;
      return;
    }
    this.allChecked = true;
    this.marcarTodos();
  }

  selecionarUm(checked: boolean, chave: string) {
    this.selecionados.toggle(chave)
    if (checked) {
      this.naoSelecionados.deselect(chave)
    } else {
      this.naoSelecionados.select(chave);
    }
  }

  marcarTodos() {
    //this.selecionados.select(...this.dataSource.data.filter(nota => false).map(nota => 'nota.chave'));
  }

  limparSelecao() {
    this.selecionados.clear();
    this.naoSelecionados.clear();
    this.allChecked = false;
  }

  limparFiltros() {
    this.formulario.patchValue({
      chave: '',
      dataInicio: new Date(this.hoje.getTime() - (30*86400000)),
      dataFim: this.hoje,
      status: null
    });
    this.limparSelecao();
    this.pagina = 0;
    this.filtrado = false;
    this.buscar();
  }

  exibirModalFiltro() {
    this.displayStyleModalFiltro = "block";
  }

  filtrar() {
    if (this.formulario.value.chave != '' || this.formulario.value.status != null ) {
      this.filtrado = true;
    }
    this.buscar()
  }

  alterarData(datas: {dataInicio: Date, dataFim: Date}) {
    this.formulario.patchValue({
      dataInicio: datas.dataInicio,
      dataFim: datas.dataFim
    })
  }


}
