import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icone-confirmacao',
  templateUrl: './icone-confirmacao.component.html',
  styleUrls: ['./icone-confirmacao.component.css']
})
export class IconeConfirmacaoComponent implements OnInit {
  @Input() color = 'white'
  constructor() { }

  ngOnInit(): void {
  }

}
