import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-acoes-notas',
  templateUrl: './acoes-notas.component.html',
  styleUrls: ['./acoes-notas.component.css']
})
export class AcoesNotasComponent implements OnInit {
  @Input() temXml = false;
  @Input() podeManifestar = false;
  @Input() temManifestacao = false;
  @Input() opcoesExibidas = ['manifestacao', 'chave', 'visualizacao', 'download', 'confirmacao_cancelada']
  @Input() cancelada = false;

  @Output() manifestarUm: EventEmitter<any> = new EventEmitter<any>()
  @Output() copiarChave: EventEmitter<any> = new EventEmitter<any>()
  @Output() visualizarNota: EventEmitter<any> = new EventEmitter<any>()
  @Output() baixarNota: EventEmitter<any> = new EventEmitter<any>()
  @Output() abrirListaManifestacao: EventEmitter<any> = new EventEmitter<any>()
  @Output() confirmarCancelada: EventEmitter<any> = new EventEmitter<any>()
  constructor(
  ) { }

  ngOnInit(): void {
  }

  manifestaUm() {
    this.manifestarUm.emit();
  }

  copiaChave() {
    this.copiarChave.emit();
  }

  visualizaNota() {
    this.visualizarNota.emit();
  }

  baixaNota() {
    this.baixarNota.emit();
  }

  abreListaDeManifestacao() {
    this.abrirListaManifestacao.emit();
  }

  confirmaCancelada(): void {
    console.log('confirmacao');
    this.confirmarCancelada.emit()
  }
}
