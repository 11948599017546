<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer">
  <path d="M11.1407 1.26562C11.3608 1.05607 11.6537 0.940224 11.9576 0.942523C12.2615 0.944823 12.5526
              1.06509 12.7695 1.27794C12.9864 1.4908 13.1121 1.77959 13.1202 2.08339C13.1282 2.38719 13.0179
              2.68222 12.8125 2.90624L6.57816 10.7031C6.47096 10.8186 6.34157 10.9112 6.19774 10.9756C6.05391
              11.0399 5.89858 11.0745 5.74105 11.0775C5.58352 11.0804 5.42702 11.0515 5.2809 10.9925C5.13479
              10.9336 5.00206 10.8458 4.89066 10.7344L0.756283 6.59999C0.641147 6.49271 0.5488 6.36333 0.48475
              6.21959C0.4207 6.07584 0.386259 5.92066 0.383483 5.76331C0.380707 5.60596 0.409652 5.44967 0.468591
              5.30375C0.52753 5.15783 0.615256 5.02528 0.726535 4.914C0.837815 4.80272 0.970367 4.71499 1.11629 4.65605C1.26221
              4.59711 1.4185 4.56817 1.57585 4.57094C1.7332 4.57372 1.88837 4.60816 2.03212 4.67221C2.17587 4.73626
              2.30525 4.82861 2.41253 4.94374L5.68441 8.21406L11.111 1.29999C11.1207 1.28796 11.1312 1.27649 11.1422 1.26562H11.1407Z"
        [attr.fill]="color"/>
</svg>
