import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-acoes-usuarios',
  templateUrl: './acoes-usuarios.component.html',
  styleUrls: ['./acoes-usuarios.component.css']
})
export class AcoesUsuariosComponent implements OnInit {
  @Output() editar = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  edita() {
    this.editar.emit();
  }
}
