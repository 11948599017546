import {Injectable} from '@angular/core';
import {EmpresaDto} from "../_core/model/empresa-dto";
import {BehaviorSubject} from "rxjs";
import {UsuarioDto, UsuarioDTO} from "../_core/model/usuario-dto";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public empresaObservable$ = new BehaviorSubject<EmpresaDto | null>(null);
  constructor() { }

  salvarEmpresa(empresa: EmpresaDto): void {
    this.empresaObservable$.next(empresa);
    localStorage.setItem('nfe-empresa', JSON.stringify(empresa))
  }

  buscarEmpresa(): EmpresaDto {
    return JSON.parse(<string> localStorage.getItem('nfe-empresa'));
  }

  limparEmpresa(): void {
    this.empresaObservable$.next(null);
    localStorage.removeItem('nfe-empresa');
  }

  salvarUsuario(usuario: UsuarioDto): void {
    localStorage.setItem('nfe-usuario', JSON.stringify(usuario))
  }

  buscarUsuario(): UsuarioDto {
    return JSON.parse(<string> localStorage.getItem('nfe-usuario'));
  }

  limparUsuario(): void {
    localStorage.removeItem('nfe-usuario');
  }

  salvarPaginacao(pagina: string, total: number): void {
    localStorage.setItem('nfe-total-' + pagina, JSON.stringify(total))
  }

  buscarPaginacao(pagina: string): number {
    const retorno = JSON.parse(<string> localStorage.getItem('nfe-total-' + pagina));
    if (retorno != null) {
      return retorno
    }
    return 15;
  }

  limparPaginacao(pagina: string): void {
    localStorage.removeItem('nfe-total-' + pagina);
  }

  setTokenUsuario(token: string): void {
    let usuario = this.buscarUsuario();
    usuario.token = token;
    this.salvarUsuario(usuario);
  }
}
