<h1 class="titulo-pagina">Cadastrar Empresa</h1>

<div class="row justify-content-center">
  <div class="col-10 col-md-8">
        <form class="needs-validation" (submit)="pesquisar()" [formGroup]="formularioPesquisa" novalidate  *ngIf="etapa == 'certificado'">
          <div class="row g-3">
            <div class="col-12 grupo-input-formulario input-primeira-etapa-empresa flex flex-column justify-content-center mb-5">
              <p class="size-12 mb-0 label-primeira-etapa">* Campos Obrigatórios</p>
            </div>
            <div class="col-12 grupo-input-formulario input-primeira-etapa-empresa flex flex-column justify-content-center">
              <label class="label-primeira-etapa">Tipo Empresa *</label>
              <mat-form-field appearance="fill" class="select-sem-label tamanho-medio">
                <mat-select id="status" formControlName="tipoEmpresa" (selectionChange)="selecionaTipo($event)">
                  <mat-option *ngFor="let stat of tipos"  [value]="stat.value">{{ stat.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 grupo-input-formulario input-primeira-etapa-empresa flex flex-column justify-content-center" *ngIf="formularioPesquisa.controls.tipoEmpresa.value === 'FILIAL'">
              <label class="label-primeira-etapa">Empresa Matriz *</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <mat-select id="matriz" placeholder="Selecione a matriz" formControlName="matrizId">
                  <mat-option *ngFor="let empresa of empresas"  [value]="empresa.id">{{ empresa.cnpj | mask: '00.000.000/0000-00'}} - {{ empresa.nomeFantasia }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 grupo-input-formulario input-primeira-etapa-empresa flex flex-column justify-content-center">
              <label class="label-primeira-etapa">Upload Certificado <span *ngIf="formularioPesquisa.controls.tipoEmpresa.value == 'MATRIZ'">*</span></label>
              <app-input-arquivo  [exibirBotaoEnviar]="false" [file]="arquivoInput"
                                  [icone]="'certificado'"
                                  [tiposAceitosMime]="[
                                    'application/x-pkcs12', 'application/x-pkcs7-certificates', 'application/x-pkcs7-mime',
                                    'application/x-pkcs7-signature', 'application/x-x509-ca-cert', 'application/x-iwork-keynote-sffkey']"
                                  [tiposAceitosExtensao]="['.pfx', '.p12', '.p7b', '.p7c', '.cer', '.der', '.crt', '.key']"
                                  (inserindoArquivo)="selecionarArquivo($event)"
                                  (removendoArquivo)="removerArquivo()"
              ></app-input-arquivo>
            </div>
            <div class="col-12  grupo-input-formulario input-primeira-etapa-empresa flex flex-column justify-content-center">
              <label class="label-primeira-etapa">Digite a Senha *</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input id="senha" matInput [type]="esconderSenha ? 'password' : 'text'" formControlName="senha">
                <button mat-icon-button matSuffix (click)="esconderSenha = !esconderSenha" [attr.aria-label]="'Esconder Senha'" [attr.aria-pressed]="esconderSenha">
                  <mat-icon>{{esconderSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <div class="invalid-feedback"
                   *ngIf="formulario.controls.senha?.invalid && (formulario.controls.senha?.touched || formulario.controls.senha?.dirty)">
                Senha obrigatória.
              </div>
            </div>
          </div>
          <div class="flex row-botoes input-primeira-etapa-empresa justify-content-between justify-content-md-end">
              <div class="flex justify-content-center div-botao-resultado">
                <button mat-button class="color-primary p-md-0 me-md-2" type="button" (click)="cancelar()">
                  <label><span class="notas-retornar fw-bold">VOLTAR LISTA<span class="visualizacao-normal fw-bold"> EMPRESAS</span></span></label>
                </button>
              </div>
              <div class="flex div-botao-resultado justify-content-end">
                <button mat-raised-button color="primary" class="bt-cadastro flex justify-content-center w-auto" type="button" (click)="pesquisar()" [disabled]="formularioPesquisa.invalid">
                  <label class="me-0">AVANÇAR</label>
                </button>
              </div>
          </div>
        </form>

        <form class="needs-validation" (submit)="salvar()" [formGroup]="formulario" novalidate *ngIf="etapa == 'empresa'">
          <div class="row g-3">
            <div class="col-12 grupo-input-formulario input-primeira-etapa-empresa flex flex-column justify-content-center mb-5 visualizacao-mobile">
              <p class="size-12 mb-0 label-primeira-etapa">* Campos Obrigatórios</p>
            </div>
            <div class="col-sm-6 grupo-input-formulario">
              <label for="razaoSocial">Razão Social *</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="razaoSocial" formControlName="razaoSocial">
              </mat-form-field>
              <div class="invalid-feedback"
                   *ngIf="formulario.controls.razaoSocial?.invalid && (formulario.controls.razaoSocial?.touched || formulario.controls.razaoSocial?.dirty)">
                Razão Social Obrigatória.
              </div>
            </div>

            <div class="col-sm-6 grupo-input-formulario">
              <label for="nomeFantasia">Nome Fantasia *</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="nomeFantasia" formControlName="nomeFantasia">
              </mat-form-field>
              <div class="invalid-feedback"
                   *ngIf="formulario.controls.nomeFantasia?.invalid && (formulario.controls.nomeFantasia?.touched || formulario.controls.nomeFantasia?.dirty)">
                Nome Fantasia Obrigatória.
              </div>
            </div>

            <div class="col-sm-4 grupo-input-formulario">
              <label for="tipoEmpresa2">Tipo Empresa *</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <mat-select id="tipoEmpresa2" formControlName="tipoEmpresa" (selectionChange)="selecionaTipo($event)">
                  <mat-option *ngFor="let stat of tipos"  [value]="stat.value">{{ stat.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-4 grupo-input-formulario">
              <label for="matrizForm">Empresa Matriz <span *ngIf="formulario.controls.tipoEmpresa.value == 'FILIAL'">*</span>
              </label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <mat-select id="matrizForm" placeholder="Selecione a matriz" formControlName="matrizId" [disabled]="formulario.controls.tipoEmpresa.value !== 'FILIAL'">
                  <mat-option *ngFor="let empresa of empresas"  [value]="empresa.id">{{ empresa.cnpj | mask: '00.000.000/0000-00'}} - {{ empresa.nomeFantasia }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-sm-4 grupo-input-formulario">
              <label for="cnpj">CNPJ *</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="cnpj" formControlName="cnpj" mask="00.000.000/0000-00"
                       >
              </mat-form-field>
              <div class="invalid-feedback"
                   *ngIf="formulario.controls.cnpj?.invalid && (formulario.controls.cnpj?.touched || formulario.controls.cnpj?.dirty)">
                Por favor insira um CNPJ válido.
              </div>
            </div>

            <div class="col-sm-6 grupo-input-formulario">
              <label for="email">Email</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="email" formControlName="email">
              </mat-form-field>
              <div class="invalid-feedback"
                   *ngIf="formulario.controls.email?.invalid && (formulario.controls.email?.touched || formulario.controls.email?.dirty)">
                Por favor insira um email válido
              </div>
            </div>

            <div class="col-sm-6 grupo-input-formulario">
              <label for="telefone">Telefone</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="telefone" formControlName="telefone" mask="(00)000000000" [validation]="false">
              </mat-form-field>
              <div class="invalid-feedback"
                   *ngIf="formulario.controls.telefone?.invalid && (formulario.controls.telefone?.touched || formulario.controls.telefone?.dirty)">
                Telefone obrigatório.
              </div>
            </div>

            <div class="col-sm-3 grupo-input-formulario">
              <label for="cep">CEP</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="cep" formControlName="cep" mask="00000-000" [validation]="false">
              </mat-form-field>
            </div>

            <div class="col-sm-7 grupo-input-formulario">
              <label for="logradouro">Logradouro</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="logradouro" formControlName="logradouro">
              </mat-form-field>
            </div>

            <div class="col-sm-2 grupo-input-formulario">
              <label for="numero">Número</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="numero" formControlName="numero">
              </mat-form-field>
            </div>

            <div class="col-sm-4 grupo-input-formulario">
              <label for="bairro">Bairro</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="bairro" formControlName="bairro">
              </mat-form-field>
            </div>

            <div class="col-sm-6 grupo-input-formulario">
              <label for="municipio">Município</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <input matInput id="municipio" formControlName="municipio">
              </mat-form-field>
            </div>

            <div class="col-sm-2 grupo-input-formulario">
              <label for="uf">UF *</label>
              <mat-form-field appearance="fill" class="select-sem-label">
                <mat-select id="uf" placeholder="Selecione a uf" formControlName="uf">
                  <mat-option *ngFor="let uf of ufs"  [value]="uf.value">{{ uf.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="invalid-feedback"
                   *ngIf="formulario.controls.uf?.invalid && (formulario.controls.uf?.touched || formulario.controls.uf?.dirty)">
                UF obrigatório.
              </div>
            </div>
          </div>

          <div class="flex justify-content-sm-between">
            <div class="visualizacao-normal flex align-self-center">
              <p class="size-12 mb-0">* Campos Obrigatórios</p>
            </div>
            <div class="flex justify-content-sm-between justify-content-center">
              <div class="flex justify-content-center div-botao-resultado">
                <button mat-button class="color-primary p-md-0 me-md-2" type="button" (click)="cancelar()">
                  <label><span class="notas-retornar fw-bold">VOLTAR LISTA<span class="visualizacao-normal fw-bold"> EMPRESA</span></span></label>
                </button>
              </div>
              <div class="flex div-botao-resultado justify-content-end">
                <button mat-raised-button color="primary" class="bt-cadastro flex justify-content-center w-auto" type="button" (click)="salvar()" [disabled]="formulario.invalid">
                  <label>{{ id != null ? 'EDITAR' : 'CADASTRAR'}}</label>
                  <app-icone-empresa [color]="formulario.invalid ? '#B0B0B0' : 'white'" ></app-icone-empresa>
                </button>
              </div>
            </div>
          </div>
        </form>
  </div>
</div>
