<div class="modal modal-backdrop modal-form" id="exampleModal" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="needs-validation" (submit)="salvar()" [formGroup]="formulario" novalidate>
      <div class="modal-header">
        <div class="row">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="fecharPopup()">
          </button>
        </div>
        <div class="div-modal-title row">
          <h3 class="modal-title">Certificado Digital - {{empresa?.razaoSocial }}</h3>
        </div>
      </div>
      <div class="modal-body">
        <p><b>Data de Expiração do Certificado Atual:</b> {{dataExpiracao}}</p>
         <div class="row g-3">
            <div class="col-12">
              <app-input-arquivo  [exibirBotaoEnviar]="false" [file]="arquivoInput"
                                  [tiposAceitosMime]="[
                                    'application/x-pkcs12', 'application/x-pkcs7-certificates', 'application/x-pkcs7-mime',
                                    'application/x-pkcs7-signature', 'application/x-x509-ca-cert', 'application/x-iwork-keynote-sffkey']"
                                  [tiposAceitosExtensao]="['.pfx', '.p12', '.p7b', '.p7c', '.cer', '.der', '.crt', '.key']"
                                  (inserindoArquivo)="selecionarArquivo($event)"
                                  (removendoArquivo)="removerArquivo()"
              ></app-input-arquivo>
            </div>
            <div class="col-12">
              <mat-form-field appearance="fill">
                <mat-label for="senha">Senha</mat-label>
                <input id="senha" matInput [type]="esconderSenha ? 'password' : 'text'" formControlName="senha">
                <button mat-icon-button matSuffix (click)="esconderSenha = !esconderSenha" [attr.aria-label]="'Esconder Senha'"
                        [attr.aria-pressed]="esconderSenha">
                  <mat-icon>{{esconderSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <div class="invalid-feedback"
                   *ngIf="formulario.controls.senha?.invalid &&
                   (formulario.controls.senha?.touched || formulario.controls.senha?.dirty)">
                Senha obrigatória.
              </div>
            </div>
          </div>
      </div>
      <div class="modal-footer border-top-0">
        <div class="row justify-content-end">
          <div class="col-6 col-md-4 p-1">
            <button mat-stroked-button class="bt-form" color="light" type="button" (click)="fecharPopup()">Cancelar</button>
          </div>
          <div class="col-6 col-md-4 p-1">
            <button mat-flat-button class="bt-form" color="primary" type="submit"
                    [disabled]="formulario.invalid || isLoading()">Salvar</button>
          </div>
        </div>
      </div>
      </form>
    </div>
  </div>
</div>
