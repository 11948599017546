<h1 class="titulo-pagina">Cadastrar Usuário</h1>
<div class="row justify-content-center p-md-5">
  <div class="col-12 col-lg-7 col-md-8 col-sm-9">

    <form [formGroup]="formulario" novalidate>
      <div class="row g-3">
        <div class="col-12 grupo-input-formulario input-primeira-etapa-empresa flex flex-column justify-content-center mb-5 visualizacao-mobile">
          <p class="size-12 mb-0 label-primeira-etapa">* Campos Obrigatórios</p>
        </div>
        <div class="col-12 grupo-input-formulario">
          <label>Email *</label>
          <mat-form-field class="example-full-width select-sem-label" appearance="fill">
            <input id="email" type="email" matInput formControlName="email" [readonly]="possuiSenha">
          </mat-form-field>
        </div>
        <div class="col-12 grupo-input-formulario">
          <label>Nome *</label>
          <mat-form-field class="example-full-width select-sem-label" appearance="fill">
            <input id="nome" type="text" matInput formControlName="nome">
          </mat-form-field>
        </div>
      </div>

      <div class="flex justify-content-md-between justify-content-sm-end justify-content-center">
        <div class="visualizacao-normal flex align-self-center">
          <p class="size-12 mb-0">* Campos Obrigatórios</p>
        </div>
        <div class="flex justify-content-sm-between justify-content-center">
          <div class="flex justify-content-center div-botao-resultado">
            <button mat-button class="color-primary p-md-0 me-md-2" type="button" (click)="cancelar()">
              <label><span class="notas-retornar fw-bold">VOLTAR LISTA<span class="visualizacao-normal fw-bold"> USUÁRIOS</span></span></label>
            </button>
          </div>
          <div class="flex div-botao-resultado justify-content-end">
            <button mat-raised-button color="primary" class="bt-cadastro flex justify-content-center w-auto" type="button" (click)="salvar()" [disabled]="formulario.invalid">
              <label>{{ this.usuarioId != null ? 'EDITAR' : 'CADASTRAR'}}</label>
              <app-icone-usuarios [color]="formulario.invalid ? '#B0B0B0' : 'white'" ></app-icone-usuarios>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
