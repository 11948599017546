import { Injectable } from '@angular/core';
import {BaseService} from "../common/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {SortDirection} from "@angular/material/sort";
import {StorageService} from "../storage.service";
import {CriacaoUsuarioDto} from "../../_core/model/criacao-usuario-dto";
import {MensagemSucessoDTO} from "../../_core/model/mensagem-sucesso-dto";

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends BaseService {

  constructor(public http: HttpClient,
              public storageService: StorageService
  ) { super(http); }

  listarUsuarios(filtro: any, page: number, size: number, field: string | undefined, order: SortDirection | undefined) {
    let params = new HttpParams()
      .append("page", page)
      .append("size", size)
    if (field && order) {
      params = params.append("field", field).append("order", order);
    }
    if (filtro) {
      params = params.append("filtro", filtro);
    }
    return super.get(`usuarios/cliente`, {params: params})
  }

  criarUsuario(formulario: CriacaoUsuarioDto): Promise<MensagemSucessoDTO> {
    return super.post('usuarios', formulario);
  }

  editarUsuario(formulario: CriacaoUsuarioDto, idUsuario: number | null): Promise<MensagemSucessoDTO> {
    return super.put('usuarios/'+idUsuario, formulario);
  }

  buscarPeloId(idUsuario: number) {
    return super.get('usuarios/id/'+idUsuario);
  }

  alterarStatusUsuario(operacao: 'ativar' | 'desativar', usuarioId: number) {
    return super.put(`usuarios/${operacao}/${usuarioId}`, null);
  }
}
