import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {CriacaoUsuarioDto} from "../../_core/model/criacao-usuario-dto";
import {LoadingConstant} from "../../_core/model/loading-constant";
import {UsuarioDto} from "../../_core/model/usuario-dto";
import {UsuarioService} from "../../_service/web/usuario.service";
import {MensagemService} from "../../_service/mensagem.service";

@Component({
  selector: 'app-formulario-usuarios',
  templateUrl: './formulario-usuarios.component.html',
  styleUrls: ['./formulario-usuarios.component.css']
})
export class FormularioUsuariosComponent implements OnInit {
  formulario: FormGroup = this.formBuilder.group({
    idUsuario: [null],
    email: [null, [Validators.required, Validators.email]],
    nome: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
    idCliente: [null],
    perfil: ['GERENTE']
  });
  @Output() criarUsuario: EventEmitter<CriacaoUsuarioDto> = new EventEmitter<CriacaoUsuarioDto>();
  @Output() editarUsuario: EventEmitter<CriacaoUsuarioDto> = new EventEmitter<CriacaoUsuarioDto>();
  @Output() cancelarFormulario: EventEmitter<null | number> = new EventEmitter<null | number>();
  usuarioId: null | number = null;
  possuiSenha: boolean = false;
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public usuarioService: UsuarioService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
  }

  @Input()
  set idUsuario(idUsuario: null | number) {
    if (idUsuario) {
      this.usuarioId = idUsuario;
      this.buscarDadosUsuario(idUsuario);
    }
  }

  buscarDadosUsuario(idUsuario: number) {
    LoadingConstant.loading = true;
    this.usuarioService.buscarPeloId(idUsuario).then( (response: UsuarioDto) => {
      this.formulario.patchValue({
        idUsuario: [null],
        email:response.email,
        nome: response.nome,
        idCliente: response.clienteId,
        perfil: response.perfil
      });
      this.possuiSenha = response.possuiSenha;
      LoadingConstant.loading = false;
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  salvar(): void {
    if (this.formulario.valid) {
      this.formulario.patchValue({
        idUsuario: this.usuarioId
      })
      if (this.usuarioId != null) {
        this.editarUsuario.emit(this.formulario.value)
      } else {
        this.criarUsuario.emit(this.formulario.value)
      }
    }
  }

  cancelar(): void {
    this.cancelarFormulario.emit(this.usuarioId)
  }
}
