import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icone-chave',
  templateUrl: './icone-chave.component.html',
  styleUrls: ['./icone-chave.component.css']
})
export class IconeChaveComponent implements OnInit {
  @Input() fill = '#1D3C85'
  constructor() { }

  ngOnInit(): void {
  }

}
