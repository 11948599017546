<div class="row w-100">
  <div class="col-12 col-lg-6 col-md-6 flex justify-content-start">
    <h1 class="titulo-pagina">Ações em Lote</h1>
  </div>
  <div class="col-12 col-lg-6 col-md-6 flex justify-content-start visualizacao-normal" *ngIf="passo == 'lista'">
    <h1 class="notas-importadas mt-auto mb-auto"><span class="notas-importadas color-primary">Notas Importadas:</span>
    {{retornoArquivo.validas}}</h1>
  </div>
  <div class="col-12 col-lg-6 col-md-6 flex justify-content-start justify-content-lg-end justify-content-md-end"
    *ngIf="passo == 'inicio'">
    <button mat-raised-button color="primary" class="bt-cadastro" type="button" (click)="downloadExemplo()">
      <label>TEMPLATE EXCEL</label>
      <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V7H2V2H18V7H20V0H0ZM2 9L4 13L2 17H4L5 15L6 17H8L6 13L8 9H6L5 11L4 9H2ZM9 9V17H13V15H11V9H9ZM16.5
        9C15.117 9 14 10.117 14 11.5C14 12.883 15.117 14 16.5 14C16.717 14 17 14.283 17 14.5C17 14.8 16.88 15 16.5
        15C16.132 15 16.076 14.92 16.062 14.906C16.049 14.893 16 14.826 16 14.594H14C14 15.16 14.163 15.794 14.625
        16.281C15.087 16.769 15.768 17.001 16.5 17.001C17.92 17.001 19 15.801 19 14.501C19 13.118 17.883 12.001 16.5
        12.001C16.283 12.001 16 11.718 16 11.501C16 11.284 16.283 11.001 16.5 11.001C16.767 11.001 16.848 11.064 16.906
         11.126C16.966 11.188 17 11.296 17 11.406H19C19 10.819 18.785 10.214 18.344 9.751C17.902 9.288 17.234 9.001 16.5
         9.001V9ZM0 19V26H20V19H18V24H2V19H0Z" fill="white"/>
      </svg>
    </button>
  </div>
</div>
<form class="example-form pb-lg-0 pb-md-0 pb-5" [formGroup]="formulario">
      <div class="row w-100" *ngIf="passo == 'inicio'">
        <div>

        </div>
        <div class="col-12 justify-content-center">
          <app-input-arquivo [exibirBotaoEnviar]="true" [file]="arquivoInput"
                             [tiposAceitosMime]="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv']"
                             [tiposAceitosExtensao]="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', '.csv']"
                             (enviandoArquivo)="inserirArquivo()" (inserindoArquivo)="selecionarArquivo($event)"
                             (removendoArquivo)="removerArquivo()"
          ></app-input-arquivo>
        </div>
      </div>

  <div class="row w-100" *ngIf="passo == 'processando'">
    <div class="col-12 flex-column align-items-center">
      <div class="div-texto-upload">
        <p>Analisando Arquivo</p>
      </div>
      <div class="imagem-acoes-lote flex align-items-end justify-content-center">
        <label class="label-importando color-primary">Importando e Analisando Notas...</label>
      </div>
    </div>
  </div>


  <div class="row w-100" *ngIf="passo == 'retorno'">
    <div class="col-12 pt-4">
      <span>Status do upload</span>
    </div>
    <div class="col-12">
      <mat-card class="card-retorno-arquivo">
        <div class="row flex">
          <div class="col-6 col-md-4 flex align-items-center justify-content-center">
            <label class="notas-importadas"><span class="notas-importadas color-primary">Notas Importadas:</span>
            {{retornoArquivo.validas}}</label>
          </div>
          <div class="col-6 col-md-4 flex align-items-center justify-content-center">
            <label class="notas-erros"><span class="notas-erros color-secondary">Notas com erros:
            </span>{{retornoArquivo.invalidas}}</label>
          </div>
          <div class="col-12 col-md-4 flex align-items-center justify-content-center text-center">
            <label class="notas-atencao"><span class="color-secondary">ATENÇÃO:</span> Abra seu arquivo e corrija o erro
              ou avance e você perderá a importação destas notas.</label>
          </div>
        </div>
      </mat-card>

      <app-tabela-excecao-arquivo [dataSource]="dataSourceExcecao"></app-tabela-excecao-arquivo>
    </div>
    <div class="col-6 flex justify-content-md-end justify-content-start div-botao-resultado">
      <button mat-raised-button color="primary" class="bt-cadastro me-md-5" type="button" (click)="passo = 'lista'">
        <label><span class="visualizacao-normal">VER </span>RESULTADO</label>
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.2158 8.40918L8.99219 0.403808C8.92139 0.342773 8.83105 0.308594 8.73584 0.308594H6.5752C6.39453 0.308594 6.31152 0.533203 6.44824 0.650391L14.998 8.07227H0.710937C0.603516 8.07227 0.515625 8.16016 0.515625 8.26758V9.73242C0.515625 9.83984 0.603516 9.92773 0.710937 9.92773H14.9956L6.4458 17.3496C6.30908 17.4692 6.39209 17.6914 6.57275 17.6914H8.80664C8.85303 17.6914 8.89941 17.6743 8.93359 17.6426L18.2158 9.59082C18.3003 9.51733 18.3681 9.42655 18.4145 9.32463C18.461 9.2227 18.485 9.112 18.485 9C18.485 8.888 18.461 8.7773 18.4145 8.67537C18.3681 8.57345 18.3003 8.48267 18.2158 8.40918Z" fill="white"/>
        </svg>
      </button>
    </div>
    <div class="col-6 flex justify-content-md-start div-botao-resultado justify-content-end">
      <button mat-button class="color-primary p-0 ms-md-5" type="button" (click)="cancelarInsercao()">
        <label><b class="notas-retornar">IMPORTAR NOVAMENTE</b></label>
      </button>
    </div>
  </div>
<!--    </mat-tab>-->
<!--  </mat-tab-group>-->
  <app-tabela-notas *ngIf="passo == 'lista'"
    [dataSource]="dataSource" [selecionados]="selecionados" [textoVazio]="'Nenhuma Nota Fiscal Inserida'"
    [paginacao]="false" [ordenacao]="false" [totalItens]="dataSource.data.length"
    (limpaSelecao)="limparSelecao()" (selecionaTodos)="selecionarTodos($event)"
    (selecionaUm)="selecionarUm($event.chave)"
  ></app-tabela-notas>
</form>


