<div class="row justify-content-end max-width-100 searchcontainer pb-3 visualizacao-normal">
  <button mat-raised-button class="bt-cadastro" color="primary" type="button" *ngIf="exibirBotaoAcaoLote"
          [disabled]="selecionados.isEmpty()"
    (click)="acaoLote()">
    <label>AÇÃO LOTE</label>
    <app-icone-manifestacao-lote [centralizar]="true"
                            [color]="selecionados.isEmpty()
                            ? '  rgba(0,0,0,.26)' : 'white' "></app-icone-manifestacao-lote>
  </button>
</div>

<div class="flex-principal justify-content-between visualizacao-mobile pb-3">
  <button mat-raised-button class="bt-cadastro" color="primary" type="button" *ngIf="exibirBotaoAcaoLote"
          (click)="acaoLote()">
    <label>{{ selecionados.isEmpty() ? 'AÇÃO LOTE' : (selecionados.selected.length + ' Selecionados') }}</label>
    <app-icone-manifestacao-lote [centralizar]="true"
                                 [color]="'white'"></app-icone-manifestacao-lote>
  </button>
  <div class="visualizacao-mobile flex-principal" *ngIf="exibirFiltro">
    <h1 class="m-auto">
      <app-icone-filtro  (click)="exibirModalFiltro()" [filtroAtivo]="filtrado"></app-icone-filtro>
    </h1>
  </div>
</div>

<div class="visualizacao-normal">
  <div *ngIf="dataSource.data.length == 0">
    <app-tela-nenhum-resultado></app-tela-nenhum-resultado>
  </div>
  <mat-table [dataSource]="dataSource" (matSortChange)="ordenar($event)" *ngIf="dataSource.data.length > 0"
             matSort matSortDisableClear [matSortActive]="sortActive" matSortDirection="desc">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef class="celula-checkbox">
        <mat-checkbox (change)="$event.checked ? selecionarTodos() : limparSelecao()"
                      [checked]="selecionados.hasValue() && isAllSelected()" #checkbox
                      [indeterminate]="selecionados.hasValue() && !isAllSelected()"
                      [disabled]="dataSource.data.length > 20"
                      color="primary">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="seleção" class="celula-checkbox">
        <mat-checkbox (click)="$event.stopPropagation()"
                      [matTooltip]="selecionados.selected.length > 20 || !row.podeManifestar ?
                          'Você não consegue realizar ação de manifestação nessa nota' :
                          'Selecione esta nota para efetuar uma ação'"
                      (change)="$event ? selecionarUm($event.checked, row.chave) : null"
                      [checked]="selecionados.isSelected(row.chave)"
                      [disabled]="!selecionados.isSelected(row.chave) && (selecionados.selected.length > 20 || !row.podeManifestar)"
                      color="primary">
        </mat-checkbox>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="numero">
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao"> NFE Nº/ SÉRIE</mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="nfe nº/ série"> {{row.numero != null ? row.numero + '/' + row.serie : '-' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="chave"  >
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao"> CHAVE </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="chave"> {{row.chave}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status-texto">
      <mat-header-cell *matHeaderCellDef mat-sort-header=""> STATUS </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="status">
        <span class="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded
            bg-{{row.corManifestacao}}-100 text-{{row.corManifestacao}}-800 dark:bg-{{row.corManifestacao}}-200 dark:text-{{row.corManifestacao}}-900"
        >{{row.status}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dataGeracao" >
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao"> DATA DA EMISSÃO </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="data emissão"> <b>{{row.dataGeracao != null ? row.dataGeracao : '-'}} </b></mat-cell>
    </ng-container>

    <ng-container matColumnDef="limiteManifestacao" >
      <mat-header-cell *matHeaderCellDef> LIMITE MANIFESTAÇÃO </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="limite manifestação"> {{ row.limiteManifestacao != null ? row.limiteManifestacao : '-'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="emissorRazaoSocial" >
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao">RAZÃO SOCIAL</mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="razão social"> {{ row.emissorRazaoSocial != null ? row.emissorRazaoSocial: '-'}}
         </mat-cell>
    </ng-container>

    <ng-container matColumnDef="emissorCnpj" >
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao"> CNPJ </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="cnpj">
        {{ row.emissorCnpj != null ? (row.emissorCnpj | mask: '00.000.000/0000-00') : '-' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="valor">
      <mat-header-cell *matHeaderCellDef  mat-sort-header disableClear [disabled]="!ordenacao"> VALOR </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="valor" >  {{ row.valor != null ? ( row.valor | currency: 'BRL') : '-'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="m.dataManifestacao" >
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao"> DATA MANIFESTAÇÃO </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="data última manifestação"> {{ row.dataUltimaManifestacao != null ? row.dataUltimaManifestacao : '-'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="usuarioUltimaManifestacao" >
      <mat-header-cell *matHeaderCellDef> USUÁRIO MANIFESTAÇÃO </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="usuário manifestação"> {{ row.usuarioUltimaManifestacao != null ? row.usuarioUltimaManifestacao : '-'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="opcoes">
      <mat-header-cell *matHeaderCellDef class="justify-content-start" [style]="width()"> AÇÕES </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="opcões" [style]="width()">
        <app-acoes-notas
          [podeManifestar]="row.podeManifestar" [temXml]="row.temXml" [temManifestacao]="row.temManifestacaoNoSistema"
          (manifestarUm)="manifestarUm(row)" (copiarChave)="copiarChave(row.chave)" (confirmarCancelada)="confirmarVisualizacao(row.chave)"
          (visualizarNota)="visualizarNota(row)" (baixarNota)="baixarNota(row)" [cancelada]="row.status.toUpperCase() == 'CANCELADA NÃO VISUALIZADA'"
          (abrirListaManifestacao)="visualizarManifestacoes(row.chave)" [opcoesExibidas]="opcoesExibidas"
        ></app-acoes-notas>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="colunasExibidas"></mat-header-row>
    <mat-row *matRowDef="let row; columns: colunasExibidas;"></mat-row>

  </mat-table>
</div>
<div class="visualizacao-mobile card-mobile">
  <div *ngIf="dataSource.data.length == 0">
    <app-tela-nenhum-resultado></app-tela-nenhum-resultado>
  </div>
  <mat-card *ngFor="let row of dataSource.data" class="mat-card-table-mobile" (click)="selecionarUmMobile(row.chave, row.podeManifestar)"
    [class.card-selecionado]="selecionados.isSelected(row.chave)">
    <div class="flex-column">
      <div class="row pb-2">
        <div class="col-6 flex justify-content-start"><b>{{row.dataGeracao != null ? row.dataGeracao : '-'}} </b></div>
        <div class="col-6 flex justify-content-end">
          {{ row.valor != null ? ( row.valor | currency: 'BRL') : '-'}}

        </div>
      </div>
      <div class="row texto-cinza text-break"><label class="size-12">{{row.emissorRazaoSocial != null ? row.emissorRazaoSocial: '-'}}</label></div>
      <div class="row texto-cinza text-break"><label>
        <b class="size-12">{{row.emissorCnpj != null ? (row.emissorCnpj | mask: '00.000.000/0000-00') : '-'}}</b></label></div>
      <div class="row">
        <div class="col-6 flex justify-content-start align-items-center">
          <span class="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded
            bg-{{row.corManifestacao}}-100 text-{{row.corManifestacao}}-800 dark:bg-{{row.corManifestacao}}-200 dark:text-{{row.corManifestacao}}-900 small-size"
          >{{row.status}}</span> </div>
        <div class="col-6 flex justify-content-end">
          <app-acoes-notas *ngIf="this.exibirCampo('opcoes')"
            [podeManifestar]="row.podeManifestar" [temXml]="row.temXml" [temManifestacao]="row.temManifestacaoNoSistema"
            (manifestarUm)="manifestarUm(row)" (copiarChave)="copiarChave(row.chave)" (confirmarCancelada)="confirmarVisualizacao(row.chave)"
            (visualizarNota)="visualizarNota(row)" (baixarNota)="baixarNota(row)" [cancelada]="row.status.toUpperCase() == 'CANCELADA NÃO VISUALIZADA'"
            (abrirListaManifestacao)="visualizarManifestacoes(row.chave)" [opcoesExibidas]="opcoesExibidas"
          ></app-acoes-notas>
        </div>
      </div>
      <div class="row">
        <div class="col-6 flex justify-content-start align-items-center" *ngIf="this.exibirCampo('usuarioUltimaManifestacao')">
          <label>{{row.usuarioUltimaManifestacao}}</label>
        </div>
        <div class="col-6 flex justify-content-end" *ngIf="this.exibirCampo('dataUltimaManifestacao')">
          <label>{{row.dataUltimaManifestacao}}</label>
        </div>
      </div>
    </div>
  </mat-card>
</div>

<app-paginator *ngIf="paginacao && dataSource.data.length > 0" [tamanho]="tamanho" [totalElementos]="totalItens" (paginacao)="paginar($event)" [pagina]="pagina"></app-paginator>


  <app-modal-manifestacao
    [displayStyle]="displayStyleModalManifestacao"
    [chavesSelecionadas]="chavesSelecionadas"
    [tipos]="tipos"
    (closePopup)="closePopup()"
  ></app-modal-manifestacao>

<app-modal-info-mobile
  [displayStyle]="displayStylePopupMobileInfo"
  (closePopup)="displayStylePopupMobileInfo = 'none'"
></app-modal-info-mobile>

<app-modal-lista-manifestacao
  [chave]="chaveListaManifestacao"
  [displayStyle]="displayStyleListaManifestacao"
  (closePopup)="closePopupManifestacao()"
></app-modal-lista-manifestacao>
