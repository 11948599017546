<h1 class="titulo-pagina">Empresas</h1>
<form class="corpo-pagina" (submit)="filtrar()" [formGroup]="formulario">
<div class="searchcontainer visualizacao-normal">
  <div class="search-part">
    <div class="justify-content-between flex w-100">
      <mat-form-field class="example-full-width input-filtro-chave tamanho-padrao" appearance="fill">
        <mat-label>CNPJ ou Nome da empresa</mat-label>
        <input matInput formControlName="filtro">
      </mat-form-field>
      <button mat-raised-button class="bt-form ms-2 me-2 botao-tamanho-limitado" color="primary" type="submit"
              [disabled]="formulario.invalid">
        <app-icone-lupa></app-icone-lupa>
      </button>
      <div class="me-auto">
        <app-botao-limpar *ngIf="filtrado" (click)="limparFiltros()"></app-botao-limpar>
      </div>
      <button mat-raised-button color="primary" class="bt-cadastro" type="button" (click)="cadastrar()">
        <label>CADASTRAR</label>
        <app-icone-empresa color="white"></app-icone-empresa>
      </button>
    </div>
  </div>
</div>


  <div class="flex-principal justify-content-between visualizacao-mobile pb-4">
    <button mat-raised-button color="primary" class="bt-cadastro" type="button" (click)="cadastrar()">
      <label>CADASTRAR</label>
      <app-icone-empresa color="white"></app-icone-empresa>
    </button>
    <app-icone-filtro class="mt-auto mb-auto" (click)="openModal()" [filtroAtivo]="filtrado"></app-icone-filtro>
  </div>
</form>

<div class="visualizacao-normal">
  <div *ngIf="dataSource.data.length == 0">
    <app-tela-nenhum-resultado></app-tela-nenhum-resultado>
  </div>
  <mat-table [dataSource]="dataSource" (matSortChange)="ordenacao($event)" *ngIf="dataSource.data.length > 0"
               matSort matSortDisableClear matSortActive="nomeFantasia" matSortDirection="asc">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tipoEmpresa">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> TIPO </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="tipoEmpresa">
          <span class="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded"
            [ngClass]="{'bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900' : row.tipoEmpresa == 'MATRIZ',
                        'bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900' : row.tipoEmpresa == 'FILIAL'}"
          >{{ row.tipoEmpresa }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cnpj">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> CNPJ </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="cnpj" class="fw-bold">{{ row.cnpj | mask: '00.000.000/0000-00'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="razaoSocial">
        <mat-header-cell *matHeaderCellDef  mat-sort-header disableClear> RAZÃO SOCIAL </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="razão Social"> {{row.razaoSocial}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nomeFantasia">
        <mat-header-cell *matHeaderCellDef  mat-sort-header disableClear> FANTASIA </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="Nome Fantasia"> {{row.nomeFantasia}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dataExpiracaoCertificado" >
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> CERT. EXPIRA </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="data Expiração" class="text-center" >
          <span class="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded"
                [class.bg-pink-100.text-green-800.dark:bg-green-200.dark:text-green-900]="row.certificadoExpirado">{{row.certificadoExpirado ? 'EXPIRADO' : row.dataExpiracaoCertificado}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> STATUS </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="status">
          <app-toggle [classe]="row.id.toString()" (clickToggle)="openDialog(row.status, row)" [selecionado]="row.status"></app-toggle>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="opcoes">
        <mat-header-cell *matHeaderCellDef> AÇÕES </mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="opcões">
          <app-acao-empresa (abrirCertificado)="openPopup(row)" (abrirEditar)="editar(row.id)"></app-acao-empresa>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">Nenhum item encontrado</td>
      </tr>
    </mat-table>
</div>

<div class="visualizacao-mobile card-mobile">
  <div *ngIf="dataSource.data.length == 0">
    <app-tela-nenhum-resultado></app-tela-nenhum-resultado>
  </div>
  <mat-card *ngFor="let row of dataSource.data" class="mat-card-table-mobile">
    <div class="flex justify-content-between h-100">
    <div class="flex flex-column justify-content-between">
      <div class="mb-auto">
                  <span class="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded"
                        [ngClass]="{'bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900' : row.tipoEmpresa == 'MATRIZ',
                        'bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900' : row.tipoEmpresa == 'FILIAL'}"
                  >{{ row.tipoEmpresa }}</span>
      </div>
      <div class="row texto-cinza text-break"><label class="size-12">{{row.razaoSocial != null ? row.razaoSocial : '-'}}</label></div>
      <div class="row texto-cinza text-break"><label class="size-12">{{row.nomeFantasia != null ? row.nomeFantasia : '-'}}</label></div>
      <div class="row texto-cinza text-break"><label class="size-12 fw-bold">{{row.cnpj | mask: '00.000.000/0000-00'}}</label></div>
      <div class="row texto-cinza text-break"><label class="size-12 fw-bold" *ngIf="row.dataExpiracaoCertificado">CERT.EXPIRA:
        <span *ngIf="row.certificadoExpirado" class="color-secondary">EXPIRADO</span>
        <span *ngIf="!row.certificadoExpirado">{{row.dataExpiracaoCertificado}}</span></label>
      </div>
    </div>
      <div class="flex flex-column justify-content-between">
        <div>
          <app-toggle [classe]="row.id.toString()" (clickToggle)="openDialog(row.status, row)" [selecionado]="row.status"></app-toggle>
        </div>
        <div>
          <app-acao-empresa (abrirCertificado)="openPopup(row)" (abrirEditar)="editar(row.id)"></app-acao-empresa>
        </div>
      </div>
    </div>
  </mat-card>
</div>

    <app-paginator [tamanho]="tamanho" [totalElementos]="totalItens" (paginacao)="paginacao($event)"
                   [pagina]="pagina" *ngIf="dataSource.data.length > 0"></app-paginator>


<div class="modal modal-backdrop modal-form" id="exampleModal" role="dialog" [ngStyle]="{'display':displayStyleModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="needs-validation" [formGroup]="formulario" novalidate>
        <div class="modal-body">
          <mat-form-field class="example-full-width input-filtro-chave tamanho-padrao" appearance="fill">
            <mat-label>CNPJ ou Nome da Empresa</mat-label>
            <input matInput formControlName="filtro">
          </mat-form-field>
          <button mat-raised-button color="primary" class="bt-cadastro bt-modal-100" type="button"
                  (click)="filtrar()">
            <app-icone-lupa></app-icone-lupa>
            <label>Filtrar</label>
          </button>
          <button mat-button class="color-primary bt-modal-100" type="button" (click)="limparFiltros()">
            <label>Limpar Filtro</label>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

  <app-modal-certificado
    [displayStyle]="displayStyleModalCertificado"
    [dataExpiracao]="dataExpiracao"
    [empresa]="empresaSelecionada"
    (closePopup)="closePopup()"
  ></app-modal-certificado>
