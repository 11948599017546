import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {LoadingConstant} from "../../../_core/model/loading-constant";
import {NotaFiscalService} from "../../../_service/web/nota-fiscal.service";
import {StorageService} from "../../../_service/storage.service";
import {EmpresaDto} from "../../../_core/model/empresa-dto";
import {MensagemService} from "../../../_service/mensagem.service";
import {
  NotaFiscalArquivoExcecoesDTO,
  NotaFiscalChaveArquivoDto
} from "../../../_core/model/nota-fiscal-chave-arquivo-dto";
import {NotaFiscalDto} from "../../../_core/model/nota-fiscal-dto";
import {SelectionModel} from "@angular/cdk/collections";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {DocumentoService} from "../../../_service/web/documento.service";

@Component({
  selector: 'app-inserir-nota',
  templateUrl: './inserir-nota.component.html',
  styleUrls: ['./inserir-nota.component.css']
})
export class InserirNotaComponent implements OnInit {
  formulario = this.formBuilder.group({
    chave: [null, Validators.compose([
      Validators.required, Validators.minLength(44), Validators.maxLength(44)
    ])],
    arquivo: [null, Validators.required],
    nome: ['']
  });
  selecionados = new SelectionModel<string>(true, []);
  chaves: NotaFiscalDto[] = [];
  retornoArquivo: NotaFiscalChaveArquivoDto = {validas: 0, invalidas: 0, excecoes: [], notasFiscais: []};
  dataSource: MatTableDataSource<NotaFiscalDto> = new MatTableDataSource<NotaFiscalDto>();
  dataSourceExcecao: MatTableDataSource<NotaFiscalArquivoExcecoesDTO> = new MatTableDataSource<NotaFiscalArquivoExcecoesDTO>();
  empresaSelecionada: EmpresaDto | null = null;
  passo: 'inicio' | 'processando' | 'retorno' | 'lista' = "inicio"
  // @ts-ignore
  arquivoInput: File | null;
  constructor(
    public formBuilder: FormBuilder,
    public notaFiscalService: NotaFiscalService,
    public storageService: StorageService,
    public mensagemService: MensagemService,
    public documentoService: DocumentoService
  ) { }

  ngOnInit(): void {
    this.dataSource.data = [];
    this.empresaSelecionada = this.storageService.buscarEmpresa();
  }

  inserirArquivo() {
    this.chaves = [];
    if (this.formulario.controls.arquivo.valid) {
     this.passo = 'processando'
      this.notaFiscalService.insericaoManualArquivo(this.formulario.value.arquivo, this.empresaSelecionada?.cnpj).then( (response: NotaFiscalChaveArquivoDto) => {
        this.retornoArquivo = response;
        if (response.notasFiscais.length > 0) {
          this.chaves.push(...response.notasFiscais);
        }
        this.dataSourceExcecao.data = response.excecoes;
        this.dataSource.data = this.chaves;
        this.arquivoInput = null;
        this.mensagemService.sucesso("Arquivo importado com sucesso!")
        this.passo = 'retorno';
      }).catch( error => {
        this.mensagemService.falha(error);
        this.passo = 'inicio';
      })
    }
  }

  cancelarInsercao() {
    this.arquivoInput = null;
    this.dataSourceExcecao.data = [];
    this.dataSource.data = [];
    this.retornoArquivo = {validas: 0, invalidas: 0, excecoes: [], notasFiscais: []};
    this.passo = 'inicio';
  }

  selecionarArquivo(file: File) {
    this.arquivoInput = file
    this.formulario.patchValue({
      arquivo: file
    });
  }

  removerArquivo() {
    this.arquivoInput = null;
    this.formulario.patchValue({
      arquivo: null
    });
  }

  todosSelecionados() {
    const numSelected = this.selecionados.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  selecionarTodos(todosSelecionados: boolean) {
    if (!todosSelecionados) {
      this.selecionados.clear();
      return;
    }
    this.marcarTodos();
  }

  selecionarUm(chave: string) {
    this.selecionados.toggle(chave)
  }

  marcarTodos() {
    this.selecionados.select(...this.dataSource.data.filter(nota => nota.podeManifestar).map(nota => nota.chave));
  }

  limparSelecao() {
    this.selecionados.clear();
  }

  downloadExemplo() {
    LoadingConstant.loading = true;
    this.documentoService.downloadDocumento('EXEMPLO_CHAVE_MANUAL').then(response => {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(response);
      link.setAttribute('download', 'documento_exemplo_insercao_manual');
      document.body.appendChild(link);
      LoadingConstant.loading = false;
      link.click();
      document.body.removeChild(link);
    }).catch(error => {
      LoadingConstant.loading = false;
      this.mensagemService.falha(error);
    });
  }
}
