import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoadingConstant} from "../../../../_core/model/loading-constant";
import {MensagemService} from "../../../../_service/mensagem.service";
import {TipoManifestacaoDto} from "../../../../_core/model/tipo-manifestacao-dto";
import {ManifestacaoService} from "../../../../_service/web/manifestacao.service";
import {StorageService} from "../../../../_service/storage.service";
import {ExcecaoManifestacaoDto} from "../../../../_core/model/excecao-manifestacao-dto";

@Component({
  selector: 'app-modal-manifestacao',
  templateUrl: './modal-manifestacao.component.html',
  styleUrls: ['./modal-manifestacao.component.css']
})
export class ModalManifestacaoComponent implements OnInit {
  @Input() displayStyle = "none";
  @Input() chavesSelecionadas: string[] = [];
  @Input() tipos: TipoManifestacaoDto[] = [];

  @Output() closePopup = new EventEmitter();
  etapa = 1;
  excecoes: ExcecaoManifestacaoDto[] = [];
  erro = '';
  formulario: FormGroup = this.formBuilder.group({
    chaves: [[]],
    motivo: [null],
    tipoManifestacao: [null, Validators.required]
  })
  constructor(
    public formBuilder: FormBuilder,
    public manifestacaoService: ManifestacaoService,
    public mensagemService: MensagemService,
    public storageService: StorageService
  ) { }

  ngOnInit(): void {
  }

  fecharPopup() {
    this.formulario.reset();
    this.etapa = 1;
    this.chavesSelecionadas = [];
    this.excecoes = [];
    this.displayStyle = "none";
    this.erro = '';
    this.closePopup.emit();
  }

  salvar() {
    this.formulario.patchValue({
      chaves: this.chavesSelecionadas
    })
    if (this.formulario.valid) {
      let empresa = this.storageService.buscarEmpresa();
      LoadingConstant.loading = true;
      this.manifestacaoService.manifestacao(this.formulario.value, empresa.cnpj).then( (response: ExcecaoManifestacaoDto[]) => {
        this.excecoes = response;
        this.mensagemService.sucesso('Processo de manifestação finalizado!');
        this.etapa = 2;
        LoadingConstant.loading = false;
      }).catch( error => {
        let mensagem = 'Sistema temporariamente indisponivel'
        if (error.error.message != null) {
          mensagem = error.error.message;
        }
        this.erro = mensagem;
        this.etapa = 3;
        LoadingConstant.loading = false;
      })
    }
  }

  isLoading() {
    return LoadingConstant.loading;
  }
}
