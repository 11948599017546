import { Injectable } from '@angular/core';
import {BaseService} from "../common/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ExcecaoManifestacaoDto} from "../../_core/model/excecao-manifestacao-dto";
import {ManifestacaoDto} from "../../_core/model/manifestacao-dto";

@Injectable({
  providedIn: 'root'
})
export class ManifestacaoService  extends BaseService {

  constructor(public http: HttpClient
  ) {
    super(http);
  }

  manifestacao(body: any, cnpj: string): Promise<ExcecaoManifestacaoDto[]> {
    body.cnpj = cnpj;
    return super.post("manifestacoes", body);
  }

  listaManifestacao(chave: string): Promise<ManifestacaoDto[]> {
    let params = new HttpParams().append("chave", chave)
    return super.get("manifestacoes/nota", {params})
  }
}
