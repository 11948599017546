import {Injectable} from '@angular/core';
import {BaseService} from "../common/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import * as moment from "moment";
import {SortDirection} from "@angular/material/sort";

@Injectable({
  providedIn: 'root'
})
export class NotaFiscalService extends BaseService {

  constructor(public http: HttpClient
  ) {
    super(http);
  }

  listarNotasDaEmpresa(empresaId: number, chave: string, dataInicio: string, dataFim: string, status: string,
                       page: number, size: number,
                       field: string | undefined, order: SortDirection | undefined) {
    let params = this.montandoParamsListaNotas(chave, dataInicio, dataFim, status, page, size, field, order);
    return super.get(`nota-fiscal/empresa/${empresaId}`, {params})
  }

  listarNotasDaEmpresaUltimaManifestacao(empresaId: number, chave: string, dataInicio: string, dataFim: string, status: string,
                       page: number, size: number,
                       field: string | undefined, order: SortDirection | undefined) {
    let params = this.montandoParamsListaNotas(chave, dataInicio, dataFim, status, page, size, field, order);
    return super.get(`nota-fiscal/empresa/${empresaId}/manifestacao`, {params})
  }

  montandoParamsListaNotas(chave: string, dataInicio: string, dataFim: string, status: string,
                           page: number, size: number,
                           field: string | undefined, order: SortDirection | undefined): HttpParams {
    let params = new HttpParams()
      .set('chave', chave)
      .set('page', page)
      .set('size', size);

    if (status != null && status != '0') {
      params = params.set('status', status)

    }
    if (field && order) {
      params = params.set("field", field).set("order", order);
    }
    if (dataInicio != null) { params = params.set('dataInicio', moment(dataInicio).format('DD/MM/YYYY')); }
    if (dataFim != null) { params = params.set('dataFim', moment(dataFim).format('DD/MM/YYYY')); }
    return params;
  }


  public downloadDanfe(chave: string) {
    const options = {
      responseType: 'blob',
      params: new HttpParams().append('chave', chave)
    };
    return super.get(`nota-fiscal/imprimir`, options);
  }

  insericaoManualArquivo(arquivo: File, cnpj?: string) {
    const formData = new FormData();
    formData.append('arquivo', arquivo);
    if (cnpj) formData.append('cnpj', cnpj);
    return super.post(`nota-fiscal/inserir/chave/arquivo`, formData);
  }

  tiposDeManifestacao() {
    return super.get('manifestacoes/tipos');
  }

  dashboardCards(cnpj: string) {
    let params = new HttpParams().append("cnpj", cnpj);
    return super.get("nota-fiscal/dashboard/cards", {params});
  }

  listarStatus(apenasDeManifestacao?: boolean) {
    let params = new HttpParams();
    if (apenasDeManifestacao) params = params.append("apenasDeManifestacao", apenasDeManifestacao);
    return super.get("nota-fiscal/status/lista", {params});
  }

  confirmacaoVisualizacao(chave: string) {
    let body = {chave}
    return super.post('nota-fiscal/visualizar/cancelada', body);
  }


  montandoParamsListaArquivosExportados(dataInicio: string, dataFim: string, field: string | undefined, order: SortDirection | undefined): HttpParams {
    let params = new HttpParams()

    if (field && order) {
      params = params.set("field", field).set("order", order);
    }
    if (dataInicio != null) { params = params.set('dataInicio', moment(dataInicio).format('DD/MM/YYYY')); }
    if (dataFim != null) { params = params.set('dataFim', moment(dataFim).format('DD/MM/YYYY')); }
    return params;
  }

  solicitarArquivosExportados(empresaId: number, dataInicio: string, dataFim: string, field: string | undefined, order: SortDirection | undefined) {
    let params = this.montandoParamsListaArquivosExportados( dataInicio, dataFim, field, order);
    return super.get(`relatorio/empresa/${empresaId}/exportar`, {params})
  }


  listarArquivosExportados(empresaId: number,dataInicio: string, dataFim: string, field: string | undefined, order: SortDirection | undefined) {
    let params = this.montandoParamsListaArquivosExportados( dataInicio, dataFim, field, order);
    return super.get(`nota-fiscal/exportados/${empresaId}/lista`, {params});
  }

  public downloadArquivExportado(id: number) {
    const options = {
      responseType: 'blob'
    };
    return super.get(`relatorio/arquivo/${id}`,options );
  }

}
