import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent implements OnInit {
  checked: boolean = false;
  @Input() isPink: boolean = true;
  @Input() classe: string = '';
  @Input() disabled: boolean = false;
  @Output() clickToggle: EventEmitter<any> = new EventEmitter;
  constructor() { }

  ngOnInit(): void {
  }

  click(): void {
    this.clickToggle.emit();
  }

  @Input()
  set selecionado(selecionado: boolean) {
    this.checked = selecionado;
  }
}
