<div class="visualizacao-normal">
  <div *ngIf="dataSource.data.length == 0">
    <app-tela-nenhum-resultado></app-tela-nenhum-resultado>
  </div>
  <mat-table [dataSource]="dataSource"  (matSortChange)="ordenar($event)" *ngIf="dataSource.data.length > 0"
             matSort matSortDisableClear [matSortActive]="sortActive" matSortDirection="desc">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef [style]="width()"> ID </mat-header-cell>
      <mat-cell *matCellDef="let row" [style]="width()"> {{row.id}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="dataCriacao">
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao" [style]="width()"> DATA CRIAÇÃO</mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="Data Criação" [style]="width()"> {{row.dataCriacao | date: 'dd/MM/yyyy'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="geradorPor"  >
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao" [style]="width()"> GERADO POR </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="Gerado Por" [style]="width()"> {{row.geradorPor}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="dataGeracao" >
      <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao" [style]="width()"> DATA GERAÇÃO </mat-header-cell>
      <mat-cell *matCellDef="let row" data-label="data emissão" [style]="width()"> <b>{{row.dataGeracao != null ? (row.dataGeracao | date: 'dd/MM/yyyy') : '-'}} </b></mat-cell>
    </ng-container>

    <ng-container matColumnDef="status" >
      <mat-header-cell *matHeaderCellDef [style]="width()"> STATUS </mat-header-cell>

      <mat-cell *matCellDef="let row" data-label="Status" class="text-center" [style]="width()"> {{ row.status }} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="pathArquivo" >
      <mat-header-cell *matHeaderCellDef [style]="width()"> ARQUIVO </mat-header-cell>

      <mat-cell *matCellDef="let row" data-label="Status" class="text-left" [style]="width()"> {{ row.pathArquivo }} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="download" >
      <mat-header-cell *matHeaderCellDef  [style]="width()" class="text-center" > DOWNLOAD </mat-header-cell>

      <mat-cell *matCellDef="let row" data-label="Download" class="text-center"  [style]="width()">
        <button mat-fab color="primary" class="bt-form text-center" type="button" matTooltip="Download"
                (click)="downloadArquivo(row.id,row.pathArquivo )" [disabled]="row.pathArquivo == null"   >
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 13.3333L15.3334 7.91667H12.0834V0.333332H9.91669V7.91667H6.66669L11 13.3333Z"
                  [attr.fill]="row.pathArquivo ? '#1D3C85' : 'rgba(0,0,0,.26)'"/>
            <path d="M19.6667 15.5H2.33335V7.91667H0.166687V15.5C0.166687 16.6949 1.13844 17.6667 2.33335
            17.6667H19.6667C20.8616 17.6667 21.8334 16.6949 21.8334 15.5V7.91667H19.6667V15.5Z" [attr.fill]="row.pathArquivo ? '#1D3C85' : 'rgba(0,0,0,.26)'"/>
          </svg>
        </button>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="colunasExibidas"></mat-header-row>
    <mat-row *matRowDef="let row; columns: colunasExibidas;"></mat-row>


  </mat-table>
</div>

<div class="visualizacao-mobile card-mobile">
  <div *ngIf="dataSource.data.length == 0">
    <app-tela-nenhum-resultado></app-tela-nenhum-resultado>
  </div>
  <mat-card *ngFor="let row of dataSource.data" class="mat-card-table-mobile" >
    <div class="flex-column">
      <div class="row pb-2">
        <div class="col flex justify-content-start"><b>{{row.dataCriacao | date: 'dd/MM/yyyy'}} </b></div>
      </div>
      <div class="row texto-cinza text-break"><label class="size-12">{{row.geradorPor}}</label></div>
      <div class="row texto-cinza text-break"><label>
        <b class="size-12">{{row.dataGeracao != null ? (row.dataGeracao | date: 'dd/MM/yyyy') : '-'}}</b></label></div>
      </div>
    <div class="row pb-2">
      <div class="col-6 flex justify-content-start">
        {{row.status}}
      </div>
    </div>
    <div class="row pb-2">
      <div class="col flex justify-content-end">
        <button mat-fab color="primary" class="bt-form " type="button" matTooltip="Download"
                (click)="downloadArquivo(row.id, row.pathArquivo)" [disabled]="row.pathArquivo == null"   >
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 13.3333L15.3334 7.91667H12.0834V0.333332H9.91669V7.91667H6.66669L11 13.3333Z"
                  [attr.fill]="row.pathArquivo ? '#1D3C85' : 'rgba(0,0,0,.26)'"/>
            <path d="M19.6667 15.5H2.33335V7.91667H0.166687V15.5C0.166687 16.6949 1.13844 17.6667 2.33335
            17.6667H19.6667C20.8616 17.6667 21.8334 16.6949 21.8334 15.5V7.91667H19.6667V15.5Z" [attr.fill]="row.pathArquivo ? '#1D3C85' : 'rgba(0,0,0,.26)'"/>
          </svg>
        </button>
      </div>

    </div>


  </mat-card>
</div>

<app-paginator *ngIf="paginacao && dataSource.data.length > 0" [tamanho]="tamanho" [totalElementos]="totalItens" (paginacao)="paginar($event)" [pagina]="pagina"></app-paginator>



