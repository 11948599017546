<!--<button mat-fab color="primary" class="bt-form" type="button" matTooltip="Visualizar Manifestações" [disabled]="!temManifestacao" (click)="abreListaDeManifestacao()">-->
<!--  <mat-icon>manage_search</mat-icon>-->
<!--</button>-->
<button [disabled]="!podeManifestar" mat-fab color="primary" *ngIf="opcoesExibidas.includes('manifestacao')"
        class="bt-form" type="button" matTooltip="Manifestar" (click)="manifestaUm()">
  <app-icone-manifestacao [color]="!podeManifestar ? ' rgba(0,0,0,.26)' : '#1D3C85'" (click)="manifestaUm()"></app-icone-manifestacao>
</button>
<button mat-fab color="primary" class="bt-form" type="button" matTooltip="Copiar Chave"
        (click)="copiaChave()" *ngIf="opcoesExibidas.includes('chave')">
  <app-icone-chave></app-icone-chave>
</button>
<button [disabled]="!temXml" mat-fab color="primary" class="bt-form" type="button"  *ngIf="opcoesExibidas.includes('visualizacao')"
        matTooltip="Visualizar" (click)="visualizaNota()">
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12H12M6 16H9M1 3V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21
            3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391
            17 19.5304 17 19V7.342C17 7.07556 16.9467 6.81181 16.8433 6.56624C16.7399
            6.32068 16.5885 6.09824 16.398 5.912L11.958 1.57C11.5844 1.20466 11.0826 1.00007 10.56 1H3C2.46957
            1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V3Z" [attr.stroke]="temXml ? '#1D3C85' : 'rgba(0,0,0,.26)'"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 1V5C11 5.53043 11.2107 6.03914 11.5858 6.41421C11.9609 6.78929 12.4696 7 13 7H17"
          [attr.stroke]="temXml ? '#1D3C85' : 'rgba(0,0,0,.26)'" stroke-width="2" stroke-linejoin="round"/>
  </svg>

</button>
<button [disabled]="!temXml" mat-fab color="primary" class="bt-form" type="button"  *ngIf="opcoesExibidas.includes('download')"
        matTooltip="Baixar" (click)="baixaNota()">
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 13.3333L15.3334 7.91667H12.0834V0.333332H9.91669V7.91667H6.66669L11 13.3333Z"
          [attr.fill]="temXml ? '#1D3C85' : 'rgba(0,0,0,.26)'"/>
    <path d="M19.6667 15.5H2.33335V7.91667H0.166687V15.5C0.166687 16.6949 1.13844 17.6667 2.33335
            17.6667H19.6667C20.8616 17.6667 21.8334 16.6949 21.8334 15.5V7.91667H19.6667V15.5Z" [attr.fill]="temXml ? '#1D3C85' : 'rgba(0,0,0,.26)'"/>
  </svg>

</button>
<button [disabled]="!podeManifestar" mat-fab color="primary" *ngIf="opcoesExibidas.includes('confirmacao_cancelada') && cancelada"
        class="bt-form" type="button" matTooltip="Manifestar" (click)="confirmaCancelada()">
  <app-icone-confirmacao [color]="'#1D3C85'" (click)="confirmaCancelada()"></app-icone-confirmacao>
</button>
