import {Component, OnInit} from '@angular/core';
import { timeoutWorker } from 'timeout-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  status = false;
  title = 'nfe-manifest-web';

  clickEvent(){
    this.status = !this.status;
  }

  ngOnInit(): void {
    timeoutWorker.start();
  }
}
