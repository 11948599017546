import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {AutenticacaoService} from "../../_service/autenticacao.service";
import {EmpresaDto} from "../../_core/model/empresa-dto";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() booleanNavbar = false;
  @Input() clienteNome: string = '';
  @Input() empresaSelecionada: EmpresaDto | null = null;
  @Input() idSidebar = 'sidebarMenu';
  @Input() classeSidebar = '';
  @Output() fechandoNavbar = new EventEmitter();

  constructor(
    public router: Router,
    public autenticacaoService: AutenticacaoService,
  ) { }

  ngOnInit(): void {
  }

  clickLink(): void {
    this.booleanNavbar = false;
    this.fechandoNavbar.emit(false)
  }

  sair() {
    this.autenticacaoService.logout();
  }
}
