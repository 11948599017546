import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {NotaFiscalDto} from "../../../_core/model/nota-fiscal-dto";
import {SelectionModel} from "@angular/cdk/collections";
import {ListaDefaultDto} from "../../../_core/model/lista-default-dto";
import {FormBuilder, Validators} from "@angular/forms";
import {StorageService} from "../../../_service/storage.service";
import {NotaFiscalService} from "../../../_service/web/nota-fiscal.service";
import {MensagemService} from "../../../_service/mensagem.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingConstant} from "../../../_core/model/loading-constant";
import {SortDirection} from "@angular/material/sort";
import {Page} from "../../../_core/model/page-dto";
import {PaginatorDto} from "../../../_core/model/paginator-dto";
import {RelatorioService} from "../../../_service/web/relatorio.service";

@Component({
  selector: 'app-nota-fiscal-ultima-manifestacao',
  templateUrl: './nota-fiscal-ultima-manifestacao.component.html',
  styleUrls: ['./nota-fiscal-ultima-manifestacao.component.css']
})
export class NotaFiscalUltimaManifestacaoComponent implements OnInit {
  dataSource: MatTableDataSource<NotaFiscalDto> = new MatTableDataSource<NotaFiscalDto>();
  selecionados = new SelectionModel<string>(true, []);
  naoSelecionados = new SelectionModel<string>(true, []);
  colunasExibidas: string[] = ['dataGeracao', 'emissorRazaoSocial', 'emissorCnpj', 'valor',
    'status-texto', 'm.dataManifestacao', 'usuarioUltimaManifestacao', 'opcoes'];
  opcoesExibidas = ['chave']
  tamanho = this.storageService.buscarPaginacao('nota');
  totalItens = 0;
  pagina = 0;
  hoje = new Date();
  allChecked = false;
  status: ListaDefaultDto[] = [];
  displayStyleModal = "none";
  filtrado = false;
  field = 'dataGeracao';
  order: SortDirection = 'desc';
  cnpj: string = '';
  formulario = this.formBuilder.group({
    empresaId: [null, Validators.required],
    chave: [''],
    dataInicio: [new Date(this.hoje.getTime() - (30*86400000))],
    dataFim: [this.hoje],
    status: [null]
  })
  loading = false;
  constructor(
    public storageService: StorageService,
    public notaFiscalService: NotaFiscalService,
    public formBuilder: FormBuilder,
    public mensagemService: MensagemService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public relatorioService: RelatorioService
  ) { }

  ngOnInit(): void {
    this.carregarEmpresa();
  }

  carregarEmpresa(): void {
    let empresa = this.storageService.buscarEmpresa();
    if (empresa) {
      this.cnpj = empresa.cnpj;
      this.formulario.patchValue({
        empresaId: empresa.id
      });
      this.activatedRoute.params.subscribe(params => {
        if (params.chave) {
          this.filtrado = true;
          if (params.chave == 'AGUARDANDO_DANFE' || params.chave == 'AGUARDANDO_MANIFESTACAO') {
            this.formulario.patchValue({
              status: params.chave,
              dataInicio: null,
              dataFim: null
            });
          } else if (params.chave == 'MES') {
            this.formulario.patchValue({
              dataInicio: new Date(this.hoje.getFullYear(), this.hoje.getMonth(), 1)
            });
          }
        }
      });
      this.listarStatus();
    } else {
      this.router.navigate(['/cliente/selecao'])
    }
  }

  listarStatus() {
    LoadingConstant.loading = true;
    this.notaFiscalService.listarStatus(true).then( (response: ListaDefaultDto[]) => {
      this.status = [{label: 'Todos', value: '0'}];
      this.status.push(...response);
      this.buscar(false);
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  buscar(allSelected: boolean, toggle?: boolean, field?: string, order?: SortDirection ): void {
    if (toggle) { this.selecionarTodos(allSelected); }
    LoadingConstant.loading = true;
    if (field) { this.field = field }
    if (order) { this.order = order }
    this.storageService.salvarPaginacao('nota', this.tamanho);
    this.displayStyleModal = 'none';
    this.notaFiscalService.listarNotasDaEmpresaUltimaManifestacao(this.formulario.value.empresaId, this.formulario.value.chave,
      this.formulario.value.dataInicio, this.formulario.value.dataFim, this.formulario.value.status, this.pagina, this.tamanho, this.field, this.order)
      .then( (response: Page<NotaFiscalDto>) => {
        this.dataSource.data = response.content;
        this.totalItens = response.totalElements;
        if (allSelected) { this.marcarTodos(); }
        LoadingConstant.loading = false;
      }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.buscar(this.isAllSelected());
  }

  isAllSelected() {
    const numSelected = this.selecionados.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected >= numRows && this.allChecked;
  }


  selecionarTodos(todosSelecionados: boolean) {
    this.naoSelecionados.clear();
    if (!todosSelecionados) {
      this.selecionados.clear();
      this.allChecked = false;
      return;
    }
    this.allChecked = true;
    this.marcarTodos();
  }

  selecionarUm(checked: boolean, chave: string) {
    this.selecionados.toggle(chave)
    if (checked) {
      this.naoSelecionados.deselect(chave)
    } else {
      this.naoSelecionados.select(chave);
    }
  }

  marcarTodos() {
    this.selecionados.select(...this.dataSource.data.filter(nota => nota.podeManifestar).map(nota => nota.chave));
  }

  limparSelecao() {
    this.selecionados.clear();
    this.naoSelecionados.clear();
    this.allChecked = false;
  }

  limparFiltros() {
    this.formulario.patchValue({
      chave: '',
      dataInicio: new Date(this.hoje.getTime() - (30*86400000)),
      dataFim: this.hoje,
      status: null
    });
    this.limparSelecao();
    this.pagina = 0;
    this.filtrado = false;
    this.buscar(false);
  }

  exibirModalFiltro() {
    this.displayStyleModal = "block";
  }

  filtrar() {
    if (this.formulario.value.chave != '' || this.formulario.value.status != null ) {
      this.filtrado = true;
    }
    this.buscar(false, true)
  }

  alterarData(datas: {dataInicio: Date, dataFim: Date}) {
    this.formulario.patchValue({
      dataInicio: datas.dataInicio,
      dataFim: datas.dataFim
    })
  }

  downloadPlanilha() {
    this.mensagemService.sucesso("Seu relatório será baixado em breve")
    this.loading = true;
    this.relatorioService.downloadRelatorioNotasUltimasManifestacao(
      this.formulario.value.empresaId, this.formulario.value.chave,
      this.formulario.value.dataInicio, this.formulario.value.dataFim, this.formulario.value.status,
      this.field, this.order
    ).then(response => {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(response);
      link.setAttribute('download', 'ultimas-manifestacoes-' + this.cnpj);
      document.body.appendChild(link);
      this.loading = false;
      link.click();
      document.body.removeChild(link);
    }).catch(error => {
      this.loading = false;
      this.mensagemService.falha(error);
    });
  }
}
