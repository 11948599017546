<div class="row">
  <div class="col-12">
    <h1 class="titulo-pagina">últimas Manifestações</h1>
  </div>
</div>
<form class="corpo-pagina" (submit)="filtrar()" [formGroup]="formulario">
  <div class="searchcontainer visualizacao-normal">
    <div class="search-part">
      <div class="row">
        <mat-form-field class="example-full-width input-filtro-chave" appearance="fill">
          <mat-label>Chave ou Usuário</mat-label>
          <input matInput formControlName="chave">
        </mat-form-field>
        <app-date-picker-filtro-notas class="w-auto" (alterarDatas)="alterarData($event)" [label]="'Data Manifestação'"
                                      [dataInicio]="this.formulario.value.dataInicio" [dataFim]="hoje"></app-date-picker-filtro-notas>
        <mat-form-field  class="tamanho-medio" appearance="fill">
          <mat-label for="status">Status</mat-label>
          <mat-select id="status" placeholder="Escolha status" formControlName="status" panelClass="select-expandido">
            <mat-option *ngFor="let stat of status"  [value]="stat.value">{{ stat.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button class="bt-form botao-tamanho-limitado" color="primary" type="submit"
                [disabled]="formulario.invalid">
          <app-icone-lupa></app-icone-lupa>
        </button>
        <app-botao-limpar class="w-auto  me-auto" *ngIf="filtrado" (click)="limparFiltros()"></app-botao-limpar>
      </div>
    </div>
  </div>
</form>
<div class="row justify-content-end max-width-100 searchcontainer pb-3">
  <button mat-raised-button class="bt-cadastro" color="primary" type="button" *ngIf="!loading"
          (click)="downloadPlanilha()">
    <span>Exportar Excel</span>
  </button>
  <button mat-raised-button class="bt-cadastro" color="primary" type="button" *ngIf="loading"
          [disabled]="true">
    <label>Exportando Excel</label>
    <img src="./assets/images/loading.gif" alt="loading"/>
  </button>
</div>
<app-tabela-notas
  [dataSource]="dataSource" [selecionados]="selecionados" [tamanho]="tamanho"
  [totalItens]="totalItens" [pagina]="pagina" [allChecked]="allChecked" [sortActive]="'dataGeracao'"
  (limpaSelecao)="limparSelecao()" (selecionaTodos)="selecionarTodos($event)" (alteraPagina)="paginacao($event)"
  (selecionaUm)="selecionarUm($event.checked, $event.chave)" (clickModalFiltro)="exibirModalFiltro()"
  (busca)="buscar($event.selecaoTodos, $event.toggle, $event.field, $event.order)"
  (atualizarLista)="buscar($event.selecaoTodos)" [filtrado]="filtrado" [exibirFiltro]="true"
  [colunasExibidas]="colunasExibidas" [exibirBotaoAcaoLote]="false" [opcoesExibidas]="opcoesExibidas"
></app-tabela-notas>

<div class="modal modal-backdrop modal-form" id="exampleModal" role="dialog" [ngStyle]="{'display':displayStyleModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="needs-validation"  [formGroup]="formulario" novalidate>
        <div class="modal-body">
          <mat-form-field class="input-mobile" appearance="fill">
            <mat-label>Chave</mat-label>
            <input matInput formControlName="chave">
          </mat-form-field>
          <app-date-picker-filtro-notas class="w-auto" (alterarDatas)="alterarData($event)"
                                        [dataInicio]="this.formulario.value.dataInicio"
                                        [dataFim]="hoje" [classe]="'input-mobile'" [label]="'Data Emissão'"
          ></app-date-picker-filtro-notas>
          <mat-form-field  class="input-mobile" appearance="fill">
            <mat-label for="statusMobile">Status</mat-label>
            <mat-select id="statusMobile" placeholder="Escolha status" formControlName="status">
              <mat-option *ngFor="let stat of status"  [value]="stat.value">{{ stat.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button color="primary" class="bt-cadastro bt-modal-100" type="button"
                  (click)="filtrar()">
            <app-icone-lupa></app-icone-lupa>
            <label>Filtrar</label>
          </button>
          <button mat-button class="color-primary bt-modal-100" type="button" (click)="limparFiltros()">
            <label>Limpar Filtro</label>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
