<nav id="{{idSidebar}}" class="col-md-3 col-lg-2 d-md-flex sidebar {{classeSidebar}} collapse  flex-column flex-shrink-0" [ngClass]="{ 'show': booleanNavbar }">
  <div class="position-sticky">
    <ul class="nav flex-column background-white logo-sidebar">
      <li class="nav-item h-100 nav-cliente-nome">
        <a class="nav-link cursor-default flex justify-content-center m-auto">
          <img src="./assets/images/sidebar-logo.png" width="157" height="52"/>
        </a>
      </li>
    </ul>
  </div>
  <div class="position-sticky mb-auto">
    <ul class="nav flex-column background-white">
      <li class="nav-item visualizacao-mobile">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link sub-nav" routerLink="/cliente/selecao" routerLinkActive="active" aria-current="page" (click)="clickLink()">
              <span class="nav-link-text ms-2 ps-1 mt-1">
                <b>{{empresaSelecionada?.nomeFantasia}}</b><br>
                {{ empresaSelecionada != null ? (empresaSelecionada!.cnpj | mask: '00.000.000/0000-00') : '-'}}<br>
                <span class="span-empresa color-active margin-auto">Trocar empresa</span>
              </span>
            </a>
          </li>
        </ul>
        <a class="nav-link no-pointer nav-titulo disabled p-1">
        </a>
      </li>
      <li class="nav-item nav-item-principal">
        <a class="nav-link" routerLink="/cliente/dashboard" routerLinkActive="active" aria-current="page" (click)="clickLink()">
          <app-icone-dashboard></app-icone-dashboard>
          <span class="nav-link-text ms-2 ps-1 mt-1">Dashboard</span>
        </a>
      </li>
      <li class="nav-item nav-item-principal">
        <a class="nav-link no-pointer nav-titulo disabled">
          <span class="nav-link-text ms-2 ps-1 mt-1">Nota Fiscal</span>
        </a>

        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link sub-nav" routerLink="/cliente/notas/lista" routerLinkActive="active" aria-current="page" (click)="clickLink()">
              <app-icone-nota color="#1D3C85" ></app-icone-nota>
              <span class="nav-link-text ms-2 ps-1 mt-1">Lista de Notas</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link sub-nav" routerLink="/cliente/notas/manifestacao/lista" routerLinkActive="active" aria-current="page" (click)="clickLink()">
              <app-icone-nota color="#1D3C85" ></app-icone-nota>
              <span class="nav-link-text ms-2 ps-1 mt-1">Exportar Manifestações</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link sub-nav" routerLink="/cliente/notas/arquivosexportados/lista" routerLinkActive="active" aria-current="page" (click)="clickLink()">
              <app-icone-nota color="#1D3C85" ></app-icone-nota>
              <span class="nav-link-text ms-2 ps-1 mt-1">Notas Exportadas</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link sub-nav" routerLink="/cliente/notas/inserir" routerLinkActive="active" aria-current="page" (click)="clickLink()">
              <app-icone-nota color="#1D3C85" ></app-icone-nota>
              <span class="nav-link-text ms-2 ps-1 mt-1">Ações em Lote</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item nav-item-principal">
        <a class="nav-link no-pointer nav-titulo disabled">
          <span class="nav-link-text ms-2 ps-1 mt-1">Empresa</span>
        </a>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link sub-nav" routerLink="/cliente/empresas" routerLinkActive="active" aria-current="page" (click)="clickLink()">
              <app-icone-empresa></app-icone-empresa>
              <span class="nav-link-text ms-2 ps-1 mt-1">Lista de Empresas</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item nav-item-principal">
        <a class="nav-link no-pointer nav-titulo disabled">
          <span class="nav-link-text ms-2 ps-1 mt-1">Usuário</span>
        </a>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link sub-nav" routerLink="/cliente/usuarios" routerLinkActive="active" aria-current="page" (click)="clickLink()">
              <app-icone-usuarios></app-icone-usuarios>
              <span class="nav-link-text ms-2 ps-1 mt-1">Lista de Usuários</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="position-sticky">
    <ul class="nav flex-column">
      <li class="nav-item nav-item-principal">
        <a class="nav-link" (click)="sair()">
          <app-icone-sair></app-icone-sair>
          <span class="nav-link-text ms-2 ps-1 mt-1">Sair</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
