<div class="row">
  <div class="col-12">
    <h1 class="titulo-pagina">Nota Fiscal</h1>
  </div>
</div>
<form class="corpo-pagina" (submit)="filtrar()" [formGroup]="formulario">
  <div class="searchcontainer visualizacao-normal">
  <div class="search-part">
    <div class="row">
      <mat-form-field class="example-full-width input-filtro-chave" appearance="fill">
        <mat-label>Chave</mat-label>
        <input matInput formControlName="chave" mask="00000000000000000000000000000000000000000000">
      </mat-form-field>
      <app-date-picker-filtro-notas class="w-auto" (alterarDatas)="alterarData($event)"
        [dataInicio]="this.formulario.value.dataInicio" [dataFim]="hoje"></app-date-picker-filtro-notas>
        <mat-form-field  class="tamanho-medio" appearance="fill">
          <mat-label for="status">Status</mat-label>
          <mat-select id="status" placeholder="Escolha status" formControlName="status" panelClass="select-expandido">
            <mat-option *ngFor="let stat of status"  [value]="stat.value">{{ stat.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      <button mat-raised-button class="bt-form botao-tamanho-limitado" color="primary" type="submit"
              [disabled]="formulario.invalid">
        <app-icone-lupa></app-icone-lupa>
      </button>
      <button mat-raised-button class="bt-cadastro afastamento-esquerdo" color="primary" type="button" style="margin-left: 10px !important;"
              (click)="exibirConfirmacaoExportacaoModal()">
        <span>Exportar Excel</span>
      </button>
      <app-botao-limpar class="w-auto  me-auto" *ngIf="filtrado" (click)="limparFiltros()"></app-botao-limpar>
    </div>
  </div>
  </div>
</form>
<app-tabela-notas
  [dataSource]="dataSource" [selecionados]="selecionados" [tamanho]="tamanho"
  [totalItens]="totalItens" [pagina]="pagina" [allChecked]="allChecked" [sortActive]="'chave'"
  (limpaSelecao)="limparSelecao()" (selecionaTodos)="selecionarTodos($event)" (alteraPagina)="paginacao($event)"
  (selecionaUm)="selecionarUm($event.checked, $event.chave)" (clickModalFiltro)="exibirModalFiltro()"
  (busca)="buscar($event.selecaoTodos, $event.toggle, $event.field, $event.order)"
  (atualizarLista)="buscar($event.selecaoTodos)" [filtrado]="filtrado" [exibirFiltro]="true"
></app-tabela-notas>

<div class="modal modal-backdrop modal-form" id="exampleModal" role="dialog" [ngStyle]="{'display':displayStyleModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="needs-validation"  [formGroup]="formulario" novalidate>
        <div class="modal-body">
            <mat-form-field class="input-mobile" appearance="fill">
              <mat-label>Chave</mat-label>
              <input matInput formControlName="chave" mask="00000000000000000000000000000000000000000000">
            </mat-form-field>
          <app-date-picker-filtro-notas class="w-auto" (alterarDatas)="alterarData($event)"
                                        [dataInicio]="this.formulario.value.dataInicio"
                                        [dataFim]="hoje" [classe]="'input-mobile'" [label]="'Data Emissão'"
          ></app-date-picker-filtro-notas>
            <mat-form-field  class="input-mobile" appearance="fill">
              <mat-label for="statusMobile">Status</mat-label>
              <mat-select id="statusMobile" placeholder="Escolha status" formControlName="status">
                <mat-option *ngFor="let stat of status"  [value]="stat.value">{{ stat.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button color="primary" class="bt-cadastro bt-modal-100" type="button"
                    (click)="filtrar()">
              <app-icone-lupa></app-icone-lupa>
              <label>Filtrar</label>
            </button>
            <button mat-raised-button class="bt-cadastro bt-modal-100" color="primary" type="button" style="margin-top: 10px !important;"
                    (click)="exibirConfirmacaoExportacaoModal()">
              <span>Exportar Excel</span>
            </button>
            <button mat-button class="color-primary bt-modal-100" type="button" (click)="limparFiltros()">
              <label>Limpar Filtro</label>
            </button>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="modal modal-backdrop modal-form" id="modalConfirmarExportacao" role="dialog" [ngStyle]="{'display':displayStyleModalExportacao}">
  <div class="modal-dialog" role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-titulo">Exportação de Notas para Excel</h4>
      </div>
      <div class="modal-body">
        <p>Você está iniciando o processo de exportação de notas para excel.</p>
        <p>Este processo pode demorar dependendo do número de notas selecionadas na pesquisa.</p>
        <p>Acompanhe o andamento da exportação no menu lateral, opção Notas Exportadas. <br>Assim que concluída a exportação, o arquivo ficará disponível para download.</p>
      </div>
      <div class="modal-footer">
        <mat-dialog-actions align="end">
          <button mat-button mat-dialog-close (click)="cancelaConfirmacaoExportacaoModal()">Cancelar</button>
          <button mat-button  color="primary" type="button" (click)="solicitaExportacaoArquivo()">Confirmar</button>
        </mat-dialog-actions>
      </div>
    </div>

  </div>
</div>
