import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tela-nenhum-resultado',
  templateUrl: './tela-nenhum-resultado.component.html',
  styleUrls: ['./tela-nenhum-resultado.component.css']
})
export class TelaNenhumResultadoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
