import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ValidacaoSenhaService} from "../../_service/validacao/validacao-senha.service";
import {Router} from "@angular/router";
import {AutenticacaoService} from "../../_service/autenticacao.service";
import {LoadingConstant} from "../../_core/model/loading-constant";
import {MensagemService} from "../../_service/mensagem.service";
import {MensagemSucessoDTO} from "../../_core/model/mensagem-sucesso-dto";

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {
  esconderSenha = true;
  formulario = this.formBuilder.group({
    senhaAtual: [null, Validators.required],
    novaSenha: [null, Validators.required],
    confirmacaoNovaSenha: [null, Validators.required]
  }, { validators: [ValidacaoSenhaService.confirmacaoNovaSenha, ValidacaoSenhaService.confirmacaoAlterarSenha] });
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public autenticacaoService: AutenticacaoService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
  }

  enviar(): void {
    if (this.formulario.valid) {
      LoadingConstant.loading = true;
      this.autenticacaoService.alterarSenha(this.formulario.value).then( (response: MensagemSucessoDTO) => {
        LoadingConstant.loading = false;
        this.mensagemService.sucesso(response.mensagem);
        this.router.navigate(['/cliente/dashboard'])
      }).catch( error => {
        this.mensagemService.falha(error);
        LoadingConstant.loading = false;
      })
    }
  }

}
