import {Component, OnInit} from '@angular/core';
import {EmpresaDto} from "../../../_core/model/empresa-dto";
import {Router} from "@angular/router";
import {EmpresasService} from "../../../_service/web/empresas.service";
import {PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MensagemService} from "../../../_service/mensagem.service";
import {LoadingConstant} from "../../../_core/model/loading-constant";
import {MensagemSucessoDTO} from "../../../_core/model/mensagem-sucesso-dto";
import {MatDialog} from "@angular/material/dialog";
import {DialogoConfirmacaoComponent} from "../../../_componentes/dialogo-confirmacao/dialogo-confirmacao.component";
import {Sort, SortDirection} from "@angular/material/sort";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PaginatorDto} from "../../../_core/model/paginator-dto";
import {StorageService} from "../../../_service/storage.service";

@Component({
  selector: 'app-empresa-lista',
  templateUrl: './empresa-lista.component.html',
  styleUrls: ['./empresa-lista.component.css']
})
export class EmpresaListaComponent implements OnInit {
  pagina = 0;
  tamanho = this.storageService.buscarPaginacao('empresa');
  formulario: FormGroup = this.formBuilder.group({
    filtro: ['']
  })
  totalItens = 0;
  dataSource: MatTableDataSource<EmpresaDto> = new MatTableDataSource<EmpresaDto>();
  displayedColumns: string[] = ['cnpj', 'razaoSocial', 'nomeFantasia', 'tipoEmpresa', 'dataExpiracaoCertificado', 'status', 'opcoes'];
  sort: Sort = {active: 'nomeFantasia', direction: 'asc'};
  displayStyleModal = 'none';
  filtrado = false;
  displayStyleModalCertificado = "none";
  dataExpiracao: string | null = null;
  empresaSelecionada: EmpresaDto | null = null;

  constructor(
    public router: Router,
    public empresaService: EmpresasService,
    public mensagemService: MensagemService,
    public storageService: StorageService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.listarEmpresas();
  }

  listarEmpresas(field?: string, order?: SortDirection): void {
    this.displayStyleModal = 'none';
    LoadingConstant.loading = true;
    this.storageService.salvarPaginacao('empresa', this.tamanho);
    this.empresaService.paginarEmpresas(this.formulario.value, this.pagina, this.tamanho, field, order).then(response => {
      this.dataSource.data = response.content;
      this.totalItens = response.totalElements;
      LoadingConstant.loading = false;
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  editar(id: number): void {
    this.router.navigate(['/cliente/empresas/editar', id]);
  }

  cadastrar(): void{
    this.router.navigate(['/cliente/empresas/novo']);
  }

  openPopup(empresa: EmpresaDto) {
    this.displayStyleModalCertificado = "block";
    this.empresaSelecionada = empresa;
    this.dataExpiracao = empresa.dataExpiracaoCertificado;
  }

  paginacao(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.listarEmpresas();
  }

  closePopup() {
    this.displayStyleModalCertificado = "none";
    this.listarEmpresas();
  }

  alterarStatusEmpresa(operacao: 'ativar' | 'desativar', empresaId: number) {
    LoadingConstant.loading = true;
    this.empresaService.alterarStatusEmpresa(operacao, empresaId).then( (response: MensagemSucessoDTO) => {
      this.mensagemService.sucesso(response.mensagem);
      LoadingConstant.loading = false;
      this.listarEmpresas();
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  openDialog(status: boolean, empresa: EmpresaDto): void {
    let operacao: 'ativar' | 'desativar' = (status ? 'desativar' : 'ativar');
    let dialogRef = this.dialog.open(DialogoConfirmacaoComponent, {
      data: {
        texto: 'Você deseja confirmar a operação de ' + operacao + ' a empresa ' + ( empresa.nomeFantasia || empresa.razaoSocial) + '?',
        titulo: operacao[0].toUpperCase() + operacao.slice(1) + ' empresa',
        color: operacao == 'ativar' ? 'success' : 'danger',
        operacao: operacao.toUpperCase()
      },
    });

    dialogRef.afterClosed().subscribe((retorno: boolean) => {
      if (retorno) {
        this.alterarStatusEmpresa(operacao, empresa.id);
      }else {
        this.listarEmpresas(this.sort.active, this.sort.direction);
      }
    });
  }

  ordenacao(sortState: Sort) {
    this.sort = sortState;
    this.listarEmpresas(sortState.active, sortState.direction)
  }

  limparFiltros() {
    this.formulario.patchValue({
      filtro: ''
    });
    this.filtrado = false;
    this.pagina = 0;
    this.listarEmpresas();
  }

  filtrar() {
    if (this.formulario.value.filtro != '' ) {
      this.filtrado = true;
    }
    this.ordenacao(this.sort);
  }

  openModal() {
    this.displayStyleModal = 'block';
  }
}
