import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-acao-empresa',
  templateUrl: './acao-empresa.component.html',
  styleUrls: ['./acao-empresa.component.css']
})
export class AcaoEmpresaComponent implements OnInit {
  @Output() abrirCertificado = new EventEmitter();
  @Output() abrirEditar = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  abreCertificado() {
    this.abrirCertificado.emit();
  }

  abreEditar() {
    this.abrirEditar.emit();
  }
}
