<div class="row justify-content-center pt-5">
  <div class="col-12 col-md-6 text-center flex-column justify-content-center m-auto">
  <svg width="94" height="82" viewBox="0 0 94 82" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
    <path d="M21.8975 5.3125H7.04455C5.96572 5.3156 4.92075 5.68941 4.08473 6.37128C3.24872 7.05314 2.67247 8.00164 2.45255 9.05781L2.15958 10.4855C-0.646311 24.0675 -0.548001 38.0894 2.44806 51.6307C2.68196 52.6718 3.2621 53.6029 4.09367 54.2717C4.92524 54.9404 5.95907 55.3074 7.02619 55.3125H21.8975C23.1402 55.3111 24.3317 54.8167 25.2104 53.938C26.0892 53.0592 26.5835 51.8678 26.585 50.625V10C26.5836 8.75723 26.0893 7.56575 25.2105 6.68698C24.3317 5.8082 23.1402 5.3139 21.8975 5.3125ZM20.335 49.0625H8.29123C5.74119 36.7533 5.73813 24.0523 8.28224 11.7418L8.31896 11.5625H20.335V49.0625ZM93.2569 27.6707L79.3404 3.21211C78.927 2.48702 78.3292 1.88409 77.6077 1.46441C76.8862 1.04473 76.0665 0.823198 75.2318 0.822266H49.5287C48.6879 0.822369 47.8622 1.04667 47.1369 1.47207L33.0721 9.72285V16.9689L49.9424 7.07227H74.3461L87.625 30.4102V32.2209L83.7774 42.4805H58.7188L55.5938 45.6055V55.1717L55.8707 56.4578L58.0174 61.2119C59.1136 63.6408 59.2879 66.3857 58.5076 68.9337C57.7272 71.4816 56.0457 73.6582 53.7774 75.0566L37.6213 39.6875H32.9375V44.375L48.7592 79.1121C49.1396 79.9365 49.7484 80.6346 50.5134 81.1236C51.2785 81.6126 52.1676 81.872 53.0756 81.8711C53.7255 81.8714 54.3683 81.7361 54.9629 81.4738C57.0438 80.5576 58.9226 79.2381 60.4906 77.5916C62.0587 75.9451 63.2849 74.0041 64.0985 71.881C64.9121 69.7578 65.2969 67.4945 65.2307 65.2217C65.1645 62.949 64.6487 60.7119 63.7129 58.6396L61.8438 54.499V48.7305H84.832C85.7935 48.7275 86.7313 48.4327 87.5216 47.8852C88.3119 47.3376 88.9174 46.5631 89.258 45.6641L93.5725 34.159C93.7719 33.6282 93.8739 33.0658 93.8736 32.4988V30.0078C93.8736 29.1885 93.6611 28.3833 93.2569 27.6707Z" fill="#1D3C85"/>
  </svg>
    <h3 class="titulo-nenhum-resultado"><b>Nenhum resultado</b></h3>
  <label>
    De acordo com os filtros escolhidos, não conseguimos encontrar nenhum resultado.
    Modifique seu filtro e tente novamente!</label>
  </div>
</div>
