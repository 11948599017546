import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MensagemErroDTO} from "../_core/model/mensagem-erro-dto";
import {AutenticacaoService} from "./autenticacao.service";
import {SnackbarComponent} from "../_componentes/material/snackbar/snackbar.component";

@Injectable({
  providedIn: 'root'
})
export class MensagemService {

  constructor(
    public snackBar: MatSnackBar,
    public autenticacaoService: AutenticacaoService
  ) { }

  sucesso(mensagem: string){
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 6000,
      panelClass: ['green-snackbar'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: {
        text: mensagem,
        icon: "check_circle"
      }
    })
  }

  falha(error: MensagemErroDTO){
    let mensagem = 'Sistema temporariamente indisponivel'
    if (error.error.message != null) {
      mensagem = error.error.message;
    }
    if (error.error.exception == "SESSAO") {
      mensagem = "Sua sessão expirou";
      this.autenticacaoService.logout();
    }
    this.falhaSimples(mensagem);
  }

  falhaSimples(mensagem: string){
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 6000,
      panelClass: ['red-snackbar'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: {
        text: mensagem,
        icon: "cancel"
      }
    })
  }
}
