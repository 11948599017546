import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoadingConstant} from "../../_core/model/loading-constant";
import {UsuarioDto} from "../../_core/model/usuario-dto";
import {environment} from "../../../environments/environment";
import {FormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AutenticacaoService} from "../../_service/autenticacao.service";
import {StorageService} from "../../_service/storage.service";
import {MensagemService} from "../../_service/mensagem.service";
import {TokenService} from "../../_service/token.service";
import {InatividadeService} from "../../_service/inatividade.service";

@Component({
  selector: 'app-formulario-login',
  templateUrl: './formulario-login.component.html',
  styleUrls: ['./formulario-login.component.css']
})
export class FormularioLoginComponent implements OnInit {
  @Output() abrirRecuperacaoSenha: EventEmitter<any> = new EventEmitter<any>();
  @Input() callback = null;
  formulario = this.formBuilder.group({
    email: [null, Validators.required],
    senha: [null, Validators.required]
  });

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public autenticacaoService: AutenticacaoService,
    public storageService: StorageService,
    public mensagemService: MensagemService,
    public tokenService: TokenService,
    public inatividadeService: InatividadeService
  ) { }

  ngOnInit(): void {
  }


  entrar(): void {
    LoadingConstant.loading = true;
    this.autenticacaoService.autenticar(this.formulario.value).then( (response: UsuarioDto) => {
      this.storageService.salvarUsuario(response);
      this.tokenService.token = response.token;
      this.inatividadeService.startTimer(environment.tempoInatividade);

      this.inatividadeService.watcher().subscribe((res) => {
        if( res ) {
          this.autenticacaoService.logout();
        }
      });
      LoadingConstant.loading = false;
      let url = '/cliente/selecao';
      if (this.callback) {
        url = this.callback;
      }
      this.router.navigate([url]);
    }).catch( error => {
      LoadingConstant.loading = false;
      this.mensagemService.falha(error);
    })
  }

  abreRecuperacaoSenha() {
    this.abrirRecuperacaoSenha.emit();
  }
}
