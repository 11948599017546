import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "../../../_service/storage.service";
import {CriacaoUsuarioDto} from "../../../_core/model/criacao-usuario-dto";
import {LoadingConstant} from "../../../_core/model/loading-constant";
import {UsuarioService} from "../../../_service/web/usuario.service";
import {MensagemService} from "../../../_service/mensagem.service";

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.css']
})
export class UsuarioFormComponent implements OnInit {
  idUsuario: number | null = null;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public storageService: StorageService,
    public usuarioService: UsuarioService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.idUsuario = params.id;
      }
    });
  }

  cadastrar(formulario: CriacaoUsuarioDto) {
    LoadingConstant.loading = true;
    this.usuarioService.criarUsuario(formulario).then( response => {
      this.mensagemService.sucesso(response.mensagem);
      LoadingConstant.loading = false;
      this.voltar()
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  editar(formulario: CriacaoUsuarioDto) {
    LoadingConstant.loading = true;
    this.usuarioService.editarUsuario(formulario, formulario.idUsuario).then( response => {
      this.mensagemService.sucesso(response.mensagem);
      LoadingConstant.loading = false;
      this.voltar()
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  voltar(): void {
    this.router.navigate(['/cliente/usuarios'])
  }
}
