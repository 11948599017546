import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MensagemService} from "../../_service/mensagem.service";

@Component({
  selector: 'app-input-arquivo',
  templateUrl: './input-arquivo.component.html',
  styleUrls: ['./input-arquivo.component.css']
})
export class InputArquivoComponent implements OnInit {
  @Output() inserindoArquivo: EventEmitter<File> = new EventEmitter<File>();
  @Output() enviandoArquivo: EventEmitter<File> = new EventEmitter<File>();
  @Output() removendoArquivo: EventEmitter<any> = new EventEmitter<any>();
  // @ts-ignore
  @Input() file: File | null;
  @Input() exibirBotaoEnviar = false;
  @Input() tiposAceitosMime: string[] = [];
  @Input() tiposAceitosExtensao: string[] = [];
  @Input() icone: 'arquivo' | 'certificado' = 'arquivo'
  constructor(
    public mensagemService: MensagemService
  ) {
  }

  ngOnInit(): void {
  }

  // @ts-ignore
  @ViewChild("fileDropRef", {static: false}) fileDropEl: ElementRef;

  arrastarArquivo($event: any) {
    this.prepararArquivo($event);
  }

  escolherArquivoManualmente(target: any) {
    this.prepararArquivo(target.files[0]);
  }

  removerArquivo() {
    this.file = null;
    this.removendoArquivo.emit();
  }

  selecionarArquivo() {
    if (this.file != null) {
      this.inserindoArquivo.emit(this.file);
    }
  }

  enviarArquivo() {
    if (this.file != null) {
      this.enviandoArquivo.emit(this.file);
    }
  }

  prepararArquivo(file: File) {
    if (this.validarTipoArquivo(file)) {
      this.file = file
      this.fileDropEl.nativeElement.value = "";
      this.selecionarArquivo();
    } else {
      this.mensagemService.falha( {
        error:
          {message: 'Tipo de arquivo inválido', exception: ''}
      })
    }
  }

  formatBytes(bytes: number | undefined, decimals = 2) {
    if (bytes) {
      if (bytes === 0) {
        return "0 Bytes";
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
    return "0 Bytes";
  }

  validarTipoArquivo(file: File): boolean {
    return this.tiposAceitosMime.includes(file.type);
  }
}
