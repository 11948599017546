import {Component, OnInit} from '@angular/core';
import {StorageService} from "../_service/storage.service";
import {EmpresaDto} from "../_core/model/empresa-dto";
import {Router} from "@angular/router";
import {MensagemService} from "../_service/mensagem.service";
import {AutenticacaoService} from "../_service/autenticacao.service";

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {
  navbarOpen = false;
  clienteNome: string = '';
  empresaSelecionada: EmpresaDto | null = null;

  constructor(
    public storageService: StorageService,
    public router: Router,
    public mensagemService: MensagemService,
    public autenticacaoService: AutenticacaoService
  ) { }

  ngOnInit(): void {
    let empresa = this.storageService.buscarEmpresa();
    let usuario = this.storageService.buscarUsuario();
    this.clienteNome = usuario.empresa;
    if (empresa) {
      this.empresaSelecionada = empresa;
    }
    this.storageService.empresaObservable$.subscribe(response => {
      if (response) {
        this.empresaSelecionada = response;
      }
    });
  }

  isHabilitadaSemSelecao(): boolean {
    return this.router.url == "/cliente/selecao";
  }

  fechandoNavbar(booleanNavbar: boolean) {
    this.navbarOpen = booleanNavbar;
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  trocarEmpresa() {
    this.router.navigate(['/cliente/selecao'])
  }
}
