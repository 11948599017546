import {Injectable} from '@angular/core';
import {BaseService} from "../common/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {EmpresaDto} from "../../_core/model/empresa-dto";
import {CertificadoDto} from "../../_core/model/certificado-dto";
import {SortDirection} from "@angular/material/sort";

@Injectable({
  providedIn: 'root'
})
export class EmpresasService extends BaseService {

  constructor(public http: HttpClient
  ) { super(http); }

  listarEmpresas(exibirApenasMatriz?: boolean) {
    let params = new HttpParams();
    if (exibirApenasMatriz) {
      params = params.append("exibirApenasMatriz", exibirApenasMatriz);
    }
    return super.get('empresas/cliente', {params})
  }

  listarEmpresasSelecao(pagina: number, tamanho: number, filtro: string,
                        exibirApenasPendentes?: boolean, status?: string, exibirApenasMatriz?: boolean
      ) {
    let params = new HttpParams();
    if (exibirApenasMatriz) {
      params = params.append("exibirApenasMatriz", exibirApenasMatriz);
    }
    if (exibirApenasPendentes) {
      params = params.append("exibirApenasPendentesManifestacao", exibirApenasPendentes);
    }
    if (status) {
      params = params.append("status", status);
    }
    if (filtro) {
      params = params.append("filtro", filtro)
    }
    params = params.append("page", pagina).append("size", tamanho)
    return super.get('empresas/cliente/selecao', {params})
  }

  paginarEmpresas(formulario: any, page: number, size: number, field: string | undefined, order: SortDirection | undefined) {
    let params = new HttpParams()
      .append("page", page)
      .append("size", size)
    if (field && order) {
      params = params.append("field", field).append("order", order);
    }
    params = params.append("filtro", formulario.filtro);
    return super.get('empresas/cliente/paginado', {params: params})
  }

  criarEmpresa(empresa: EmpresaDto, certificado: CertificadoDto) {
    const formData = new FormData();
    formData.append('arquivo', certificado.arquivo);
    formData.append('empresa', new Blob([JSON.stringify(empresa)], {
      type: 'application/json'
    }))

    return super.post('empresas', formData);
  }

  editarEmpresa(empresa: EmpresaDto, id: number) {
    return super.put(`empresas/editar/${id}`, empresa)
  }

  buscarEmpresaPeloId(id: number) {
    return super.get(`empresas/dados/${id}`)
  }

  buscarEmpresaPeloCnpj(cnpj: string) {
    return super.get(`empresas/cnpj/${cnpj}`)
  }

  buscarDadosCertificado(certificado: CertificadoDto) {
    const formData = new FormData();
    formData.append('arquivo', certificado.arquivo);
    formData.append('senha', certificado.senha);
    return super.put(`empresas/certificado/dados`, formData);
  }

  inserirCertificado(certificado: CertificadoDto, empresaId: number) {
    const formData = new FormData();
    formData.append('arquivo', certificado.arquivo);
    formData.append('senha', certificado.senha);
    return super.post(`empresas/certificado/${empresaId}`, formData);
  }

  alterarStatusEmpresa(operacao: 'ativar' | 'desativar', empresaId: number) {
    return super.put(`empresas/${operacao}/${empresaId}`, null);
  }
}
