import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-info-mobile',
  templateUrl: './modal-info-mobile.component.html',
  styleUrls: ['./modal-info-mobile.component.css']
})
export class ModalInfoMobileComponent implements OnInit {
  @Input() displayStyle = "none";
  @Output() closePopup = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  closeModal() {
    this.closePopup.emit();
  }
}
