<h1 class="titulo-pagina">Trocar Senha</h1>

<main class="form-signin p-5 col-md-5 m-auto text-start">
  <form (submit)="enviar()" [formGroup]="formulario">
    <label for="senha" class="block mb-2 pt-4 text-sm font-medium text-gray-900 dark:text-gray-300">Digite sua senha atual *</label>
      <mat-form-field appearance="fill" class="select-sem-label pb-0">
        <input id="senha" matInput [type]="esconderSenha ? 'password' : 'text'" formControlName="senhaAtual">
        <button mat-icon-button matSuffix (click)="esconderSenha = !esconderSenha" [attr.aria-label]="'Esconder Senha'" [attr.aria-pressed]="esconderSenha">
          <mat-icon>{{esconderSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <label class="block mb-2 pt-4  text-sm font-medium text-gray-900 dark:text-gray-300">Digite a nova senha *</label>
      <mat-form-field appearance="fill" class="select-sem-label pb-0">
        <input id="novaSenha" matInput [type]="esconderSenha ? 'password' : 'text'" formControlName="novaSenha">
        <button mat-icon-button matSuffix (click)="esconderSenha = !esconderSenha" [attr.aria-label]="'Esconder Senha'" [attr.aria-pressed]="esconderSenha">
          <mat-icon>{{esconderSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <div class="invalid-feedback"
           *ngIf="formulario.errors?.nova && (formulario.controls.novaSenha?.touched || formulario.controls.novaSenha?.dirty)">
        Nova senha igual a senha anterior.
      </div>

      <label class="block mb-2 pt-4  text-sm font-medium text-gray-900 dark:text-gray-300">Re-digite a nova senha *</label>
      <mat-form-field appearance="fill" class="select-sem-label pb-0">
        <input for="confirmarSenha" matInput [type]="esconderSenha ? 'password' : 'text'" formControlName="confirmacaoNovaSenha">
        <button mat-icon-button matSuffix (click)="esconderSenha = !esconderSenha" [attr.aria-label]="'Esconder Senha'" [attr.aria-pressed]="esconderSenha">
          <mat-icon>{{esconderSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <div class="invalid-feedback"
           *ngIf="formulario.errors?.confirmacao && (formulario.controls.confirmacaoNovaSenha?.touched || formulario.controls.confirmacaoNovaSenha?.dirty)">
        Senhas não conferem.
      </div>

    <button class="bt-cadastro m-auto mt-4" mat-raised-button color="primary" type="submit" [disabled]="formulario.invalid">
      <label>TROCAR SENHA</label>
      <app-icone-chave [fill]="formulario.invalid ? '#6c757d' : 'white'"></app-icone-chave>
    </button>

  </form>
</main>
