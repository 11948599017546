<h1 class="titulo-pagina">Usuários</h1>
<form class="corpo-pagina" (submit)="filtrar()" [formGroup]="formulario">
  <div class="searchcontainer visualizacao-normal">
    <div class="search-part">
      <div class="justify-content-between flex w-100">
        <mat-form-field class="example-full-width input-filtro-chave tamanho-padrao" appearance="fill">
          <mat-label>Nome do usuário</mat-label>
          <input matInput formControlName="filtro">
        </mat-form-field>
        <button mat-raised-button class="bt-form me-2 ms-2 botao-tamanho-limitado" color="primary" type="submit" [disabled]="formulario.invalid">
          <app-icone-lupa></app-icone-lupa>
        </button>
        <div class="me-auto">
          <app-botao-limpar *ngIf="filtrado" (click)="limparFiltros()"></app-botao-limpar>
        </div>
        <button mat-raised-button color="primary" class="bt-cadastro" type="button" (click)="cadastrar()">
          <label>CADASTRAR</label>
          <app-icone-usuarios color="white"></app-icone-usuarios>
        </button>
      </div>
    </div>
  </div>


  <div class="flex-principal justify-content-between visualizacao-mobile pb-4">
    <button mat-raised-button color="primary" class="bt-cadastro" type="button" (click)="cadastrar()">
      <label>CADASTRAR</label>
      <app-icone-usuarios color="white"></app-icone-usuarios>
    </button>
    <app-icone-filtro class="mt-auto mb-auto" (click)="openModal()" [filtroAtivo]="filtrado"></app-icone-filtro>
  </div>
</form>

<div class="visualizacao-normal">
  <div *ngIf="dataSource.data.length == 0">
    <app-tela-nenhum-resultado></app-tela-nenhum-resultado>
  </div>
<mat-table [dataSource]="dataSource" (matSortChange)="ordenar($event)" *ngIf="dataSource.data.length > 0"
           matSort matSortDisableClear [matSortActive]="'nome'" matSortDirection="asc">

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.id}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="nome"  >
    <mat-header-cell *matHeaderCellDef mat-sort-header disableClear [disabled]="!ordenacao"> NOME </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="nome"> {{row.nome}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header=""> EMAIL </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="email"> {{row.email}} </mat-cell>
  </ng-container>


  <ng-container matColumnDef="perfil">
    <mat-header-cell *matHeaderCellDef mat-sort-header=""> PERFIL </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="perfil"> {{row.perfil}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> STATUS </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="status">
      <app-toggle [classe]="row.id.toString()" [disabled]="row.email == usuarioLogado.email"
                  (clickToggle)="openDialog(row.status, row.email, row.id)" [selecionado]="row.status"></app-toggle>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="opcoes">
    <mat-header-cell *matHeaderCellDef class="justify-content-center"> AÇÕES </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="opcões" class="justify-content-center">
      <app-acoes-usuarios (editar)="editar(row.id)"></app-acoes-usuarios>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="7">Nenhum usuário encontrado</td>
  </tr>
</mat-table>
</div>

<div class="visualizacao-mobile card-mobile">
  <div *ngIf="dataSource.data.length == 0">
    <app-tela-nenhum-resultado></app-tela-nenhum-resultado>
  </div>
  <mat-card *ngFor="let row of dataSource.data" class="mat-card-table-mobile">
    <div class="flex-column">
        <div class="flex justify-content-end">
          <app-toggle [classe]="row.id.toString()" (clickToggle)="openDialog(row.status, row.email, row.id)" [selecionado]="row.status"></app-toggle>
        </div>
      <div class="row texto-cinza text-break"><label class="size-12">{{row.nome}}</label></div>
      <div class="row texto-cinza text-break"><label class="size-12">{{row.email}}</label></div>
      <div class="flex justify-content-end">
        <app-acoes-usuarios (editar)="editar(row.id)"></app-acoes-usuarios>
      </div>
    </div>
  </mat-card>
</div>

<app-paginator [tamanho]="tamanho" [totalElementos]="totalItens" (paginacao)="paginar($event)"
               [pagina]="pagina" *ngIf="dataSource.data.length > 0"></app-paginator>

<div class="modal modal-backdrop modal-form" id="exampleModal" role="dialog" [ngStyle]="{'display':displayStyleModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="needs-validation" [formGroup]="formulario" novalidate>
        <div class="modal-body">
          <mat-form-field class="example-full-width input-filtro-chave tamanho-padrao" appearance="fill">
            <mat-label>Nome do usuário</mat-label>
            <input matInput formControlName="filtro">
          </mat-form-field>
            <button mat-raised-button color="primary" class="bt-cadastro bt-modal-100" type="button"
                    (click)="filtrar()">
              <app-icone-lupa></app-icone-lupa>
              <label>Filtrar</label>
            </button>
            <button mat-button class="color-primary bt-modal-100" type="button" (click)="limparFiltros()">
              <label>Limpar Filtro</label>
            </button>
        </div>
      </form>
    </div>
  </div>
</div>
