<div class="container-fluid">
  <div class="row flex">
    <div class="modal modal-backdrop visualizacao-mobile" (click)="navbarOpen = false"
         id="exampleModal" role="dialog" [ngStyle]="{'display':navbarOpen ? 'block' : 'none'}">
      <app-sidebar [booleanNavbar]="navbarOpen" [empresaSelecionada]="empresaSelecionada"  *ngIf="!isHabilitadaSemSelecao()"
                 (fechandoNavbar)="fechandoNavbar($event)" [clienteNome]="clienteNome"
                 classeSidebar="sidebar-background">
      </app-sidebar>
    </div>
    <div class="sidebar-div col-md-3 col-lg-2 no-padding-bottom" *ngIf="!isHabilitadaSemSelecao()">
      <app-sidebar [classeSidebar]="'sidebar-fixo'" [idSidebar]="'sidebar-fixo'"
        [booleanNavbar]="navbarOpen" [empresaSelecionada]="empresaSelecionada" (fechandoNavbar)="fechandoNavbar($event)" [clienteNome]="clienteNome"></app-sidebar>
    </div>
      <div class="col background-customizado div-page ps-0 pe-0">
        <app-navbar
          [empresaSelecionada]="empresaSelecionada"
          (emitirToggle)="toggleNavbar()"
          (emitirTrocaEmpresa)="trocarEmpresa()"></app-navbar>
        <div class="ms-4 ms-md-5 me-4 me-md-5 mt-5">
          <router-outlet></router-outlet>
        </div>
      </div>
  </div>
</div>
