import { Component, OnInit } from '@angular/core';
import {NotaFiscalService} from "../../_service/web/nota-fiscal.service";
import {MensagemService} from "../../_service/mensagem.service";
import {LoadingConstant} from "../../_core/model/loading-constant";
import {StorageService} from "../../_service/storage.service";
import {ReportDto} from "../../_core/model/report-dto";
import {Router} from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loading = true;
  cards: ReportDto[] = [];
  cardsFilial: ReportDto[] = [];
  constructor(
    public notaFiscalService: NotaFiscalService,
    public mensagemService: MensagemService,
    public storageService: StorageService,
    public router: Router
  ) { }

  ngOnInit(): void {
    let empresa = this.storageService.buscarEmpresa();
    LoadingConstant.loading = true;
    this.notaFiscalService.dashboardCards(empresa.cnpj).then( (response: ReportDto[]) => {
      this.cards = response.filter(card => card.chave);
      this.cardsFilial = response.filter(card => !card.chave);
      LoadingConstant.loading = false;
    }).catch(error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  abrirCard(chave: String) {
    if (chave) {
      this.router.navigate(['/cliente/notas/lista/', chave])
    }
  }
}
