import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(public http: HttpClient) { }

  get(url: string, options?: any): Promise<any> {
    return this.http.get(`${ environment.api.url }${url}`, options).toPromise();
  }

  post(url: string, body: any, options?: any): Promise<any> {
    return this.http.post(`${ environment.api.url }${url}`, body, options).toPromise();
  }

  put(url: string, body: any, options?: any): Promise<any> {
    return this.http.put(`${ environment.api.url }${url}`, body, options).toPromise();
  }

  delete(url: string, options?: any): Promise<any> {
    return this.http.delete(`${ environment.api.url }${url}`, options).toPromise();
  }
}
