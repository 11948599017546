import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ValidacaoSenhaService} from "../../_service/validacao/validacao-senha.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AutenticacaoService} from "../../_service/autenticacao.service";
import {MensagemService} from "../../_service/mensagem.service";
import {StorageService} from "../../_service/storage.service";
import {LoadingConstant} from "../../_core/model/loading-constant";
import {ValidacaoSenhaDto} from "../../_core/model/validacao-senha-dto";

@Component({
  selector: 'app-formulario-nova-senha',
  templateUrl: './formulario-nova-senha.component.html',
  styleUrls: ['./formulario-nova-senha.component.css']
})
export class FormularioNovaSenhaComponent implements OnInit {
  exibirFormulario = false;
  textoErro = 'Carregando';
  email = '';
  nome = '';
  formulario = this.formBuilder.group({
    tokenRecuperacao: [null],
    idUsuario: [null],
    novaSenha: [null, Validators.required],
    confirmacaoNovaSenha: [null, Validators.required]
  }, { validators: ValidacaoSenhaService.confirmacaoNovaSenha });
  @Output() retornarLogin = new EventEmitter;
  @Output() concluirNovaSenha = new EventEmitter;
  constructor(
    public formBuilder: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public autenticacaoService: AutenticacaoService,
    public mensagemService: MensagemService,
    public storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.storageService.limparUsuario();
        this.formulario.patchValue({
          idUsuario: params.id,
          tokenRecuperacao: params.token
        });
        this.validarToken(params.token, params.id);
      }
    });
  }

  enviar(): void {
    if (this.formulario.valid) {
      LoadingConstant.loading = true;
      this.autenticacaoService.alterarSenhaRecuperacao(this.formulario.value).then( response => {
        LoadingConstant.loading = false;
        this.mensagemService.sucesso("Senha registrada com sucesso!")
        this.finalizar();
      }).catch( error => {
        this.mensagemService.falha(error);
        LoadingConstant.loading = false;
      })
    }
  }

  validarToken(token: string, usuarioId: number): void {
    LoadingConstant.loading = true;
    this.autenticacaoService.validarTokenRecuperaSenha(token, usuarioId).then( (response: ValidacaoSenhaDto) => {
      this.nome = response.nome;
      this.email = response.email;
      if (response.valido) {
        this.exibirFormulario = true;
      } else {
        this.erro(response);
      }
      LoadingConstant.loading = false;
    }).catch( error => {
      this.textoErro = error.error.message;
      this.exibirFormulario = false;
      this.mensagemService.falha(error)
      LoadingConstant.loading = false;
    })
  }

  erro(response: ValidacaoSenhaDto) {
    this.textoErro = response.mensagem
    this.exibirFormulario = false;
  }

  retornarParaLogin(): void {
    this.retornarLogin.emit();
  }

  finalizar(): void {
    this.concluirNovaSenha.emit()
  }
}
