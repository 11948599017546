import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {ClienteComponent} from "./cliente/cliente.component";
import {AutenticacaoGuard} from "./_guard/autenticacao.guard";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'nova-senha/:token/:id', component: LoginComponent},
  { path: 'cliente', canActivate: [AutenticacaoGuard], data: {expectedRole: ['GERENTE', 'USUARIO_COMUM']},
    loadChildren: () => import('./cliente/cliente.module').then( m => m.ClienteModule ), component: ClienteComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
