<header class="navbar navbar-light sticky-top bg-light flex-md-nowrap shadow header-topo justify-content-between flex"  *ngIf="!isSelecao()">
  <div class="navbar-selecao-empresa ps-md-4 ps-lg-4 pe-md-2 pe-lg-2 col-md-7 col-12
          cursor-pointer" (click)="trocarEmpresa()">
    <div class="texto-selecao-empresa justify-content-center justify-content-lg-start justify-content-md-start flex ">
      <app-icone-empresa color="#1D3C85" class="icone-header-empresa"></app-icone-empresa>
      <h4 class="mb-0 text-break mr-2">
        <b>{{ empresaSelecionada?.nomeFantasia || empresaSelecionada?.razaoSocial }}</b> - {{empresaSelecionada != null ? (empresaSelecionada.cnpj | mask : '00.000.000/0000-00') : '' }}
      </h4>
      <span class="span-empresa color-primary margin-auto">Trocar empresa</span>
    </div>
  </div>
  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" (click)="toggleNavbar()" aria-label="Toggle navigation">
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 1H17.5M1.5 7H17.5M1.5 13H8.5" stroke="#6B7280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
  <div class="col-md-5 col-12 pe-3 flex justify-content-end">
    <button mat-button class="button-user" [matMenuTriggerFor]="menu" #triggerMenu="matMenuTrigger">
      <mat-icon class="icon-user" color="primary">account_circle</mat-icon><label class="ps-3 nome-usuario cursor-pointer">Olá, {{ usuarioNome }}</label>
      <mat-icon class="icon-user icone-seta icone-sm" color="primary">{{ triggerMenu.menuOpen ? 'expand_less' : 'expand_more'}}</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <a routerLink="/cliente/alterar-senha" aria-current="page">
        <button mat-menu-item class="flex">
          <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="icone">
            <path d="M16.5 7.66665H18.6667C18.954 7.66665 19.2295 7.78078 19.4327 7.98395C19.6359 8.18711 19.75 8.46266 19.75 8.74998V21.75C19.75 22.0373 19.6359 22.3128 19.4327 22.516C19.2295 22.7192 18.954 22.8333 18.6667 22.8333H1.33333C1.04602 22.8333 0.770465 22.7192 0.567301 22.516C0.364137 22.3128 0.25 22.0373 0.25 21.75V8.74998C0.25 8.46266 0.364137 8.18711 0.567301 7.98395C0.770465 7.78078 1.04602 7.66665 1.33333 7.66665H3.5V6.58331C3.5 4.85941 4.18482 3.20611 5.40381 1.98712C6.62279 0.768132 8.27609 0.083313 10 0.083313C11.7239 0.083313 13.3772 0.768132 14.5962 1.98712C15.8152 3.20611 16.5 4.85941 16.5 6.58331V7.66665ZM2.41667 9.83331V20.6666H17.5833V9.83331H2.41667ZM8.91667 14.1666H11.0833V16.3333H8.91667V14.1666ZM4.58333 14.1666H6.75V16.3333H4.58333V14.1666ZM13.25 14.1666H15.4167V16.3333H13.25V14.1666ZM14.3333 7.66665V6.58331C14.3333 5.43404 13.8768 4.33184 13.0641 3.51918C12.2515 2.70653 11.1493 2.24998 10 2.24998C8.76873 2.24998 7.74853 2.70653 6.93587 3.51918C6.12321 4.33184 5.66667 5.43404 5.66667 6.58331V7.66665H14.3333Z" fill="#1D3C85"/>
          </svg>
          <span></span>
          Trocar minha senha
        </button></a>
    </mat-menu>
  </div>
</header>
