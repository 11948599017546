import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Sort, SortDirection} from "@angular/material/sort";
import {FormBuilder, FormGroup} from "@angular/forms";
import {UsuarioDto} from "../../_core/model/usuario-dto";
import {DialogoConfirmacaoComponent} from "../dialogo-confirmacao/dialogo-confirmacao.component";
import {MatDialog} from "@angular/material/dialog";
import {StorageService} from "../../_service/storage.service";
import {PaginatorDto} from "../../_core/model/paginator-dto";

export interface BuscaUsuarioDTO {field: string, order: SortDirection, filtro: string, pageIndex: number, pageSize: number }

@Component({
  selector: 'app-tabela-usuarios',
  templateUrl: './tabela-usuarios.component.html',
  styleUrls: ['./tabela-usuarios.component.css']
})
export class TabelaUsuariosComponent implements OnInit {
  @Output() busca: EventEmitter<BuscaUsuarioDTO> = new EventEmitter<BuscaUsuarioDTO>();
  @Output() edita: EventEmitter<number> = new EventEmitter<number>();
  @Output() cadastra: EventEmitter<any> = new EventEmitter<any>();
  @Output() alteraStatus: EventEmitter<{operacao: 'ativar' | 'desativar', idUsuario: number}> = new EventEmitter<{operacao: 'ativar' | 'desativar', idUsuario: number}>();
  @Input() dataSource: MatTableDataSource<UsuarioDto> = new MatTableDataSource<UsuarioDto>();
  @Input() totalItens = 0;
  @Input() pagina = 0;
  @Input() paginacao = true;
  @Input() ordenacao = true;
  @Input() tamanho = 15;
  displayStyleModal = 'none';
  filtrado = false;
  formulario: FormGroup = this.formBuilder.group({
    filtro: ['']
  })
  sort: Sort = {active: 'nome', direction: 'asc'};
  displayedColumns: string[] = ['nome', 'email', 'status', 'opcoes'];
  email = '';
  usuarioLogado = this.storageService.buscarUsuario();
  constructor(
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.email = this.storageService.buscarUsuario().email;
  }

  ordenar(sortState: Sort) {
    this.displayStyleModal = 'none';
    this.busca.emit({
      field: sortState.active, order: sortState.direction,
      pageIndex: this.pagina, pageSize: this.tamanho,
      filtro: this.formulario.value.filtro})
  }

  paginar(evento: PaginatorDto): void {
    this.pagina = evento.pagina;
    this.tamanho = evento.tamanho;
    this.ordenar(this.sort);
  }

  editar(id: number) {
    this.edita.emit(id)
  }

  cadastrar() {
    this.cadastra.emit()
  }

  limparFiltros() {
    this.formulario.patchValue({
      filtro: ''
    });
    this.filtrado = false;
    this.ordenar(this.sort);
  }

  filtrar() {
    if (this.formulario.value.filtro != '' ) {
      this.filtrado = true;
    }
    this.ordenar(this.sort);
  }

  openDialog(status: boolean, email: string, id: number): void {
    let operacao: 'ativar' | 'desativar' = status ? 'desativar' : 'ativar' ;
    let dialogRef = this.dialog.open(DialogoConfirmacaoComponent, {
      data: {
        texto: 'Você deseja confirmar a operação de ' + operacao + ' o usuário ' + email + '?',
        titulo: operacao[0].toUpperCase() + operacao.slice(1) + ' usuário',
        color: operacao == 'ativar' ? 'success' : 'danger',
        operacao: operacao.toUpperCase()
      },
    });

    dialogRef.afterClosed().subscribe((retorno: boolean) => {
      if (retorno) {
        this.alteraStatus.emit({operacao, idUsuario: id});
      }else {
        this.ordenar(this.sort);
      }
    });
  }

  openModal() {
    this.displayStyleModal = 'block';
  }
}
