import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icone-nota',
  templateUrl: './icone-nota.component.html',
  styleUrls: ['./icone-nota.component.css']
})
export class IconeNotaComponent implements OnInit {
  @Input() color = 'white';
  constructor() { }

  ngOnInit(): void {
  }

}
