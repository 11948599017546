import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {environment} from "../../environments/environment";
import { timeoutWorker } from 'timeout-worker';

@Injectable({
  providedIn: 'root'
})
export class InatividadeService {
  private sessaoExpirada = false;
  // @ts-ignore
  private timer: NodeJS.Timeout;
  private tempoEmSegundos = environment.tempoInatividade;

  detector: Subject<boolean>;

  constructor() {
    this.detector = new Subject();
  }

  watcher() {
    return this.detector.asObservable();
  }

  startTimer(timeInSeconds: number) {
    timeoutWorker.clearTimeout(this.timer);
    this.sessaoExpirada = false;
    this.tempoEmSegundos = timeInSeconds;
    this.timer = timeoutWorker.setTimeout(() => {
      this.sessaoExpirada = true;
      this.detector.next(this.sessaoExpirada);
    }, this.tempoEmSegundos);
  }

  resetTimer() {
    if(!this.sessaoExpirada) {
      this.startTimer(this.tempoEmSegundos);
    }
  }

  clearTimer() {
    timeoutWorker.clearTimeout(this.timer);
  }
}
