import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Sort, SortDirection} from "@angular/material/sort";
import {NotaFiscalDto} from "../../../../_core/model/nota-fiscal-dto";
import {LoadingConstant} from "../../../../_core/model/loading-constant";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {NotaFiscalService} from "../../../../_service/web/nota-fiscal.service";
import {MensagemService} from "../../../../_service/mensagem.service";
import {TipoManifestacaoDto} from "../../../../_core/model/tipo-manifestacao-dto";
import {Clipboard} from '@angular/cdk/clipboard';
import {PaginatorDto} from "../../../../_core/model/paginator-dto";
import {DialogoConfirmacaoComponent} from "../../../../_componentes/dialogo-confirmacao/dialogo-confirmacao.component";
import {MatDialog} from "@angular/material/dialog";
import {MensagemSucessoDTO} from "../../../../_core/model/mensagem-sucesso-dto";
import {MensagemErroDTO} from "../../../../_core/model/mensagem-erro-dto";
import {MatCheckbox} from "@angular/material/checkbox";

export interface BuscaDTO {selecaoTodos: boolean, toggle: boolean, field: string, order: SortDirection}
@Component({
  selector: 'app-tabela-notas',
  templateUrl: './tabela-notas.component.html',
  styleUrls: ['./tabela-notas.component.css']
})
export class TabelaNotasComponent implements OnInit {
  @Output() busca: EventEmitter<BuscaDTO> = new EventEmitter<BuscaDTO>();
  @Output() limpaSelecao: EventEmitter<any> = new EventEmitter<any>();
  @Output() selecionaTodos: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() alteraPagina: EventEmitter<PaginatorDto> = new EventEmitter<PaginatorDto>();
  @Output() selecionaUm: EventEmitter<{checked: boolean, chave: string}> = new EventEmitter<any>();
  @Output() atualizarLista: EventEmitter<{selecaoTodos: boolean}> = new EventEmitter<{selecaoTodos: boolean}>();
  @Output() clickModalFiltro: EventEmitter<any> = new EventEmitter<any>();
  @Input() dataSource: MatTableDataSource<NotaFiscalDto> = new MatTableDataSource<NotaFiscalDto>();
  @Input() selecionados = new SelectionModel<string>(true, []);
  @Input() tamanho = 5;
  @Input() totalItens = 0;
  @Input() pagina = 0;
  @Input() allChecked = false;
  @Input() paginacao = true;
  @Input() ordenacao = true;
  @Input() textoVazio = 'Nenhuma nota fiscal encontrada para os parâmetros de busca';
  @Input() local: 'lista' | 'insercao' = 'lista';
  @Input() sortActive = '';
  @Input() filtrado = false;
  @Input() exibirFiltro = false;
  @Input() exibirBotaoAcaoLote = true;
  @Input() opcoesExibidas = ['manifestacao', 'chave', 'visualizacao', 'download', 'confirmacao_cancelada']

  tipos: TipoManifestacaoDto[] = [];

  @ViewChild('checkbox') checkbox: any;

  @Input() colunasExibidas: string[] = ['select', 'dataGeracao', 'emissorRazaoSocial', 'emissorCnpj', 'valor', 'status-texto', 'opcoes'];
  displayStyleModalManifestacao = "none";
  displayStylePopupMobileInfo = 'none';
  displayStyleListaManifestacao = 'none';
  chavesSelecionadas: string[] = [];
  chaveListaManifestacao: string = '';

  constructor(
    public notaFiscalService: NotaFiscalService,
    public mensagemService: MensagemService,
    public clipboard: Clipboard,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  baixarNota(nota: NotaFiscalDto): void {
    if (nota.temXml) {
      LoadingConstant.loading = true;
      this.notaFiscalService.downloadDanfe(nota.chave).then(response => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        link.setAttribute('download', nota.chave + '.pdf');
        document.body.appendChild(link);
        LoadingConstant.loading = false;
        link.click();
        document.body.removeChild(link);
      }).catch(error => {
        LoadingConstant.loading = false;
        this.mensagemService.falha(error);
      });
    }
  }

  visualizarNota(nota: NotaFiscalDto): void {
    if (nota.temXml) {
      LoadingConstant.loading = true;
      this.notaFiscalService.downloadDanfe(nota.chave).then(response => {
        const url = window.URL.createObjectURL(response);
        LoadingConstant.loading = false;
        let w = window.open(url, '_blank');
      }).catch(error => {
        LoadingConstant.loading = false;
        if (error.status == 400) {
          error.error = {
            message: 'Falha ao baixar arquivo',
            exception: 'ARQUIVO'
          }
        }
        this.mensagemService.falha(error);
      });
    }
  }

  copiarChave(chave: string) {
    this.clipboard.copy(chave);
    this.mensagemService.sucesso("Chave copiada com sucesso!")
  }

  paginar(evento: PaginatorDto): void {
    this.limparSelecao();
    this.alteraPagina.emit(evento);
  }

  isAllSelected() {
    const numSelected = this.selecionados.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected >= numRows && this.allChecked;
  }


  selecionarTodos() {
    this.selecionaTodos.emit(true);
  }

  selecionarUm(checked: boolean, chave: string) {
    this.selecionaUm.emit({checked, chave})
  }

  selecionarUmMobile(chave: string, podeManifestar: boolean) {
    if (podeManifestar) {
      this.selecionarUm(!this.selecionados.isSelected(chave), chave);
    } else {
      this.mensagemService.falhaSimples('Você não consegue realizar ação de manifestação nessa nota')
    }
  }

  ordenar(sortState: Sort) {
    this.busca.emit({selecaoTodos: this.isAllSelected(), toggle: false, field: sortState.active, order: sortState.direction})
  }

  limparSelecao() {
    this.checkbox.checked = false;
    this.selecionados.clear();
    this.allChecked = false;
    this.limpaSelecao.emit();
  }

  openPopup(notas: string[]) {
    LoadingConstant.loading = true;
    this.notaFiscalService.tiposDeManifestacao().then( (response: TipoManifestacaoDto[]) => {
      this.tipos = response;
      this.displayStyleModalManifestacao = "block";
      this.chavesSelecionadas = notas;
      LoadingConstant.loading = false;
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }

  manifestarUm(nota: NotaFiscalDto) {
    let lista: string[] = [];
    lista.push(nota.chave)
    this.openPopup(lista);
  }

  acaoLote() {
    if (this.selecionados.selected.length == 0) {
      this.openPopupMobileInfo()
    } else {
      this.openPopup(this.selecionados.selected);
    }
  }

  closePopup() {
    this.displayStyleModalManifestacao = 'none';
    this.limparSelecao();
    this.atualizarLista.emit({selecaoTodos: this.isAllSelected()});
  }

  openPopupMobileInfo() {
    this.displayStylePopupMobileInfo = 'block';
  }

  visualizarManifestacoes(chave: string) {
    this.chaveListaManifestacao = chave;
    this.displayStyleListaManifestacao = 'block';
  }

  closePopupManifestacao() {
    this.displayStyleListaManifestacao = 'none'
    this.chaveListaManifestacao = '';
  }

  exibirModalFiltro() {
    this.clickModalFiltro.emit();
  }

  exibirCampo(campo: string): boolean {
    return (this.colunasExibidas.indexOf(campo) > -1)
  }

  width() {
    const total = 32 * this.opcoesExibidas.length;
    let minimo = 60;
    if (total > minimo) {
      minimo = total;
    }
    return 'max-width: ' + minimo + 'px !important';
  }

  confirmarVisualizacao(chave: string): void {
    let dialogRef = this.dialog.open(DialogoConfirmacaoComponent, {
      data: {
        texto: 'Você deseja confirmar a visualização desta nota (' + chave + ') cancelada ?',
        titulo: 'Confirmação de visualização de NFe cancelada',
        color: 'success',
        operacao: "CONFIRMAR"
      },
    });

    dialogRef.afterClosed().subscribe((retorno: boolean) => {
      if (retorno) {
        LoadingConstant.loading = true;
        this.notaFiscalService.confirmacaoVisualizacao(chave).then( (response: MensagemSucessoDTO) => {
          this.mensagemService.sucesso(response.mensagem);
          LoadingConstant.loading = false;
          this.atualizarLista.emit({selecaoTodos: this.isAllSelected()});
        }).catch( (error: MensagemErroDTO) => {
          this.mensagemService.falha(error);
          LoadingConstant.loading = false;
        })
      }
    });
  }
}
