<div class="modal modal-backdrop modal-form" id="exampleModal" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="needs-validation" [formGroup]="formulario" novalidate>

        <div class="flex total-modal text-center text-md-start">
          <div class="div-maior-icone-modal flex visualizacao-normal">
            <div class="div-icone-modal div-icone-success me-0">
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="m-auto">
                <path d="M10 7V9M10 13H10.01M3.07183 17H16.9282C18.4678 17 19.4301 15.3333 18.6603 14L11.7321
        2C10.9623 0.666667 9.03778 0.666667 8.26798 2L1.33978 14C0.56998 15.3333 1.53223 17 3.07183
        17Z" class="svg-success" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
          <div class="w-100">
            <h1 mat-dialog-title class="text-break titulo">Manifestação em Lote</h1>
            <div *ngIf="etapa == 1">
              <h2 mat-dialog-content class="text-break">
                {{ chavesSelecionadas.length }} notas selecionadas
              </h2>
              <div mat-dialog-content class="text-break mb-3">
                Escolha a ação que deseja executar nas notas selecionadas
              </div>
            </div>
            <div *ngIf="etapa == 2">
              <h2 mat-dialog-content class="text-break">
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" class="visualizacao-mobile m-auto">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M28 0.5C12.8125 0.5 0.5 12.8125 0.5 28C0.5 43.1875 12.8125 55.5 28
                  55.5C43.1875 55.5 55.5 43.1875 55.5 28C55.5 12.8125 43.1875 0.5 28 0.5ZM39.92 23.35C40.1395 23.0991
                  40.3066 22.8068 40.4115 22.4904C40.5163 22.174 40.5569 21.8398 40.5307 21.5075C40.5045 21.1751 40.4121
                  20.8514 40.2589 20.5553C40.1058 20.2592 39.895 19.9968 39.6389 19.7834C39.3828 19.5699 39.0866 19.4099
                  38.7677 19.3127C38.4489 19.2154 38.1138 19.1829 37.7822 19.2171C37.4506 19.2512 37.1292 19.3514 36.8369
                  19.5116C36.5445 19.6718 36.2872 19.8889 36.08 20.15L25.33 33.0475L19.7675 27.4825C19.296 27.0271 18.6645
                  26.7751 18.009 26.7808C17.3535 26.7865 16.7265 27.0494 16.263 27.513C15.7994 27.9765 15.5365 28.6035 15.5308
                  29.259C15.5251 29.9145 15.7771 30.546 16.2325 31.0175L23.7325 38.5175C23.9781 38.763 24.2722 38.9546 24.596 39.0802C24.9198
                  39.2057 25.2662 39.2624 25.6132 39.2466C25.9601 39.2309 26.2999 39.143 26.611 38.9886C26.9221 38.8342 27.1976
                  38.6167 27.42 38.35L39.92 23.35Z" fill="#6BAA45"/>
                </svg>

                Ação executada
              </h2>
              <div mat-dialog-content class="text-break mb-3">
                A ação foi executada com sucesso
              </div>
              <div mat-dialog-content class="text-break mb-3" *ngIf="excecoes.length > 0">
                <label class="label-chaves"> Relatório de Erros:</label>
                <div class="div-chave red" *ngFor="let excecao of excecoes" >
                  <b>{{ excecao.chave }} - {{ excecao.excecao }}</b>
                </div>
              </div>
            </div>
            <div *ngIf="etapa == 3">
              <div mat-dialog-content class="text-break mb-3">
                <div class="div-chave">
                  <b class="red">Ocorreu um erro no servidor ao tentar executar sua solicitação: <br></b>
                  <b>{{erro}}</b>

                </div>
              </div>
            </div>
            <div *ngIf="etapa == 1">
              <mat-form-field appearance="fill">
                <mat-label for="tipoManifestacao">Tipo de Manifestação</mat-label>
                <mat-select id="tipoManifestacao" placeholder="Selecione o tipo" formControlName="tipoManifestacao">
                  <mat-option *ngFor="let tipo of tipos"  [value]="tipo.codigo">{{ tipo.descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div  *ngIf="formulario.controls.tipoManifestacao.value == '210240'">
              <label>Motivo</label>
              <mat-form-field class="select-sem-label" appearance="outline">
                <textarea matInput formControlName="motivo"></textarea>
              </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div mat-dialog-actions class="flex justify-content-center">
          <div class="pe-2" *ngIf="etapa == 1">
            <button mat-button class="bt-form color-primary" color="light" type="button" (click)="fecharPopup()">Cancelar</button>
          </div>
          <div class="pe-2" *ngIf="etapa == 3">
            <button mat-button class="bt-form color-primary" color="light" type="button" (click)="fecharPopup()">Voltar</button>
          </div>
          <div *ngIf="etapa == 1">
            <button mat-raised-button class="bt-cadastro" color="primary" type="button" (click)="salvar()"
                    [disabled]="formulario.invalid || isLoading()"><label>Manifestar</label>
              <app-icone-confirmacao class="m-auto"></app-icone-confirmacao>
            </button>
          </div>
          <div *ngIf="etapa == 2">
            <button mat-raised-button class="bt-cadastro" color="primary" type="button"
                    (click)="fecharPopup()"><label>Finalizar</label>
              <app-icone-confirmacao class="m-auto"></app-icone-confirmacao>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


