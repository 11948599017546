import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginatorDto} from "../../../_core/model/paginator-dto";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  totalElementosHtml = 0;
  tamanhoHtml: number = 15;
  totalPaginas: any[] = [];
  @Input() listaTamanho: number[] = [15, 25, 100]
  @Input() pagina = 0;
  @Input() exibirSelecaoTamanho: boolean = true;

  @Output() paginacao: EventEmitter<PaginatorDto> = new EventEmitter;
  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  set totalElementos(totalElementos: number) {
    if (totalElementos != null) {
      this.totalElementosHtml = totalElementos;
      this.calcularTotalPaginas();
    }
  }

  @Input()
  set tamanho(size: number) {
    if (size != null) {
      this.tamanhoHtml = size;
      this.calcularTotalPaginas();
    }
  }

  itemFinal(): number {
    const final = this.tamanhoHtml * (this.pagina + 1);
    if (final > this.totalElementosHtml) {
      return this.totalElementosHtml;
    }
    return final;
  }

  itemInicial(): number {
    if (this.totalElementosHtml === 0) {
      return 0;
    }
    return (this.tamanhoHtml * this.pagina) + 1;
  }

  calcularTotalPaginas() {
    const totalPaginas = [];
    let numero = 0;
    while (numero < this.totalElementosHtml / this.tamanhoHtml) {
      numero++;
      totalPaginas.push(numero);
    }
    if (this.tamanhoHtml * numero < this.totalElementosHtml) {
      totalPaginas.push(numero++);
    }
    this.totalPaginas = totalPaginas;
  }

  pageEmit() {
    this.paginacao.emit({pagina: this.pagina, tamanho: this.tamanhoHtml});
  }

  listaPaginas(): any {
    const totalPaginas = [];
    if (this.totalPaginas.length >= 7) {
      let paginaInicial = this.pagina >= 4 ? this.pagina - 2 : 1;
      totalPaginas.push(paginaInicial);
      for (let i = 0; i < 5; i++) {
        paginaInicial++;
        if (paginaInicial <= this.totalPaginas.length) {
          totalPaginas.push(paginaInicial);
        }
      }
      return totalPaginas;
    }
    return this.totalPaginas;
  }

  paginar(pagina: any){
    this.pagina = pagina;
    this.pageEmit();
  }

  reduzirPagina(pagina: number): void {
    if (this.pagina !== 0) {
      this.paginar(pagina);
    }
  }

  aumentarPagina(pagina: number): void {
    if (this.pagina !== this.totalPaginas.length - 1) {
      this.paginar(pagina);
    }
  }

  selecionandoTamanho(evento: MatSelectChange) {
    this.tamanhoHtml = evento.value;
    this.pageEmit();
  }
}
