import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {NotaFiscalArquivoExcecoesDTO} from "../../_core/model/nota-fiscal-chave-arquivo-dto";

@Component({
  selector: 'app-tabela-excecao-arquivo',
  templateUrl: './tabela-excecao-arquivo.component.html',
  styleUrls: ['./tabela-excecao-arquivo.component.css']
})
export class TabelaExcecaoArquivoComponent implements OnInit {
  @Input() dataSource: MatTableDataSource<NotaFiscalArquivoExcecoesDTO> = new MatTableDataSource<NotaFiscalArquivoExcecoesDTO>();
  displayedColumns: string[] = ['linha', 'chave', 'erro'];

  constructor() { }

  ngOnInit(): void {
  }

}
