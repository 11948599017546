import { Injectable } from '@angular/core';
import {BaseService} from "./common/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {UsuarioLoginDto} from "../_core/model/usuario-login-dto";
import {UsuarioDto} from "../_core/model/usuario-dto";
import {StorageService} from "./storage.service";
import {TokenService} from "./token.service";

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService extends BaseService {

  constructor(public http: HttpClient,
              public router: Router,
              public storageService: StorageService,
              public tokenService: TokenService
  ) { super(http); }

  autenticar(usuario: UsuarioLoginDto): Promise<UsuarioDto> {
    return super.post('usuarios/autenticar/', usuario)
  }

  alterarSenhaRecuperacao(formulario: any): Promise<any> {
    return super.put('usuarios/recuperar-senha/alterar', formulario)
  }

  alterarSenha(formulario: any): Promise<any> {
    return super.put('usuarios/alterar-senha', formulario)
  }

  solicitarSenhaRecuperacao(formulario: any): Promise<any> {
    return super.post('usuarios/recuperar-senha/solicitar', formulario)
  }

  validarTokenRecuperaSenha(token: string, usuarioId: number) {
    let params = new HttpParams()
      .append('token', token)
      .append('usuarioId', usuarioId)
    return super.get('usuarios/recuperar-senha/validade', {params: params})
  }

  logout() {
    this.tokenService.clear();
  }
}
