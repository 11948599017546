import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {ReactiveFormsModule} from "@angular/forms";
import {ClienteModule} from "./cliente/cliente.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpInterceptorService} from "./_core/http-interceptor.service";
import {ComponentsModule} from "./_componentes/components.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginatorImpl} from "./_core/mat-paginator-impl";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {CdkColumnDef} from "@angular/cdk/table";
import {MatCardModule} from "@angular/material/card";
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from "@angular/common";
import {DetectorDirective} from './_core/diretivas/detector.directive';
import localePt from '@angular/common/locales/pt';
import {FormularioEsqueceuSenhaComponent} from './login/formulario-esqueceu-senha/formulario-esqueceu-senha.component';
import {FormularioLoginComponent} from './login/formulario-login/formulario-login.component';
import {EmailSenhaEnviadoComponent} from './login/email-senha-enviado/email-senha-enviado.component';
import {FormularioNovaSenhaComponent} from './login/formulario-nova-senha/formulario-nova-senha.component';
import { SenhaAlteradaComponent } from './login/senha-alterada/senha-alterada.component';


registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DetectorDirective,
        FormularioEsqueceuSenhaComponent,
        FormularioLoginComponent,
        EmailSenhaEnviadoComponent,
        FormularioNovaSenhaComponent,
        SenhaAlteradaComponent,
     ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        ClienteModule,
        ComponentsModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatCardModule
    ],
    providers: [
        [{provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true, HttpClientModule}],
        {provide: MatPaginatorIntl, useClass: MatPaginatorImpl},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: LOCALE_ID, useValue: 'pt-BR'},
        CdkColumnDef
    ],
    exports: [

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
