import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icone-filtro',
  templateUrl: './icone-filtro.component.html',
  styleUrls: ['./icone-filtro.component.css']
})
export class IconeFiltroComponent implements OnInit {
  @Input() filtroAtivo = false;

  constructor() { }

  ngOnInit(): void {
  }

}
