import {Directive, HostListener} from '@angular/core';
import {InatividadeService} from "../../_service/inatividade.service";

@Directive({
  selector: '[detector]'
})
export class DetectorDirective {

  constructor(
    private inatividadeService: InatividadeService
  ) { }

  @HostListener('keydown', ['$event'])
  onKeydown(event: any) {
    this.inatividadeService.resetTimer();
  }

  @HostListener('mousemove', ['$event'])
  onMove(event: any) {
    this.inatividadeService.resetTimer();
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    this.inatividadeService.resetTimer();
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenuClick(event: any) {
    this.inatividadeService.resetTimer();
  }

  @HostListener('wheel', ['$event'])
  onWheelClick(event: any) {
    this.inatividadeService.resetTimer();
  }
}
