<div class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-6 div-paginator">
  <div class="flex flex-1 justify-between sm:hidden pb-3">
    <a (click)="reduzirPagina(pagina - 1)" [class.isDisabled]="pagina == 0" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Anterior</a>
    <a (click)="aumentarPagina(pagina + 1)" [class.isDisabled]="pagina == totalPaginas.length - 1 || this.totalElementosHtml == 0" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Próximo</a>
  </div>
  <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between align-self-center">
    <div>
      <label class="text-sm text-gray-700">
        Mostrando
        <span class="font-medium">{{itemInicial()}}</span>
        a
        <span class="font-medium">{{itemFinal()}}</span>
        de
        <span class="font-medium">{{totalElementosHtml}}</span>
        resultados
      </label>
    </div>

    <div *ngIf="exibirSelecaoTamanho">
      <mat-form-field appearance="fill" class="select-sem-label tamanho-padrao select-paginator">
        <mat-select id="status" [value]="tamanhoHtml" (selectionChange)="selecionandoTamanho($event)">
          <mat-option *ngFor="let tamanho of listaTamanho"  [value]="tamanho">Mostrar {{ tamanho }} registros
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <a (click)="reduzirPagina(pagina - 1)" [class.isDisabled]="pagina == 0"
          class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
          <span class="sr-only">Anterior</span>
          <!-- Heroicon name: mini/chevron-left -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
          </svg>
        </a>

        <a class="relative inline-flex items-center border px-3 py-2 text-sm font-medium focus:z-20"
           [ngClass]="pagina == paginaLista - 1 ? ('z-10 border-indigo-500 bg-indigo-50 text-indigo-600') :
              ('border-gray-300 bg-white text-gray-500 hover:bg-gray-50')"
           *ngFor="let paginaLista of listaPaginas()" (click)="paginar(paginaLista - 1)">{{paginaLista}}</a>

        <a (click)="aumentarPagina(pagina + 1)" [class.isDisabled]="pagina == totalPaginas.length - 1 || this.totalElementosHtml == 0"
          class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">
          <span class="sr-only">Próximo</span>
          <!-- Heroicon name: mini/chevron-right -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
          </svg>
        </a>
      </nav>
    </div>
  </div>
</div>
