
<form (submit)="aplicandoFiltros()" [formGroup]="formulario">

<div class="searchcontainer visualizacao-normal">
  <div class="search-part p-3">
    <div class="row flex w-100">
      <div class="titulo-pagina w-auto">
        <img src="./assets/images/sidebar-logo.png" width="157" height="52"/>
      </div>
      <mat-form-field class="example-full-width input-filtro-chave tamanho-padrao" appearance="fill">
        <mat-label>CNPJ ou Razão Social</mat-label>
        <input matInput formControlName="filtro">
      </mat-form-field>
      <mat-form-field appearance="fill" class="select-sem-label tamanho-medio">
        <mat-select id="status" formControlName="status">
          <mat-option *ngFor="let stat of status"  [value]="stat.value">{{ stat.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="w-auto flex manifestacao-pendente">
        <div class="w-auto manifestacao-pendente align-self-center p-0 pe-2">
          Manifestações Pendentes
        </div>
        <div class="w-auto align-self-center">
          <app-toggle [selecionado]="formulario.controls.exibirApenasPendentes?.value"
                      (clickToggle)="toggleManifestacaoPendente()" [isPink]="false"></app-toggle>
        </div>
      </div>
      <button mat-raised-button class="botao-icone" color="primary" type="submit" [disabled]="formulario.invalid">
        <app-icone-lupa></app-icone-lupa>
      </button>
      <app-botao-limpar class="w-auto me-auto" *ngIf="filtrado" (click)="limparFiltros()"></app-botao-limpar>
      <div class="w-auto manifestacao-pendente align-self-center cursor-pointer flex fw-bold ms-auto" (click)="logout()">
        <app-icone-sair class="me-2"></app-icone-sair>
        Sair
      </div>
    </div>
  </div>
</div>

  <div class="flex-principal justify-content-between visualizacao-mobile">
    <h1 class="titulo-pagina w-auto flex align-items-center">
      <img class="logo-responsiva me-2" src="./assets/images/responsiva-logo.png"/>
      Empresas</h1>
    <h1 class="flex align-items-center">
      <app-icone-filtro (click)="abrirModalPesquisa()" [filtroAtivo]="filtrado"></app-icone-filtro>
    </h1>
  </div>
</form>

<div class="row max-vw-100 no-margin">
  <div *ngIf="empresas.length == 0 && pesquisado">
    <app-tela-nenhum-resultado></app-tela-nenhum-resultado>
  </div>
  <div class=" col-12 col-lg-4 col-md-6 div-card p-md-2" *ngFor="let empresa of empresas">
    <mat-card class="card-selecao-empresa background-white cursor-pointer" (click)="selecionarEmpresa(empresa)">
      <div class="flex h-100">
        <div class="div-icone align-self-center justify-content-start" >
          <div class="color-white flex matriz-background"
               [ngClass]="empresa.certificadoExpirado ? 'bg-certificado-expirado' : 'bg-certificado-valido'"
               [class.card-empresa-inativa]="!empresa.status">
            <app-icone-empresa color="white" class="m-auto" *ngIf="!empresa.certificadoExpirado"></app-icone-empresa>
            <app-icone-certificado-expirado  class="m-auto" *ngIf="empresa.certificadoExpirado"></app-icone-certificado-expirado>
          </div>
        </div>
        <div class="w-auto">
          <mat-card-title-group>
            <mat-card-title class="mb-0">
              <h5 class="card-title titulo-card-selecao fw-bold no-margin-bottom">
                {{ empresa.nomeFantasia || empresa.razaoSocial}}
                {{ empresa.matrizId != null ? '(MATRIZ - ' + (empresa.matrizNomeFantasia || empresa.razaoSocial) + ')' : ''}}
              </h5>
            </mat-card-title>
            <mat-card-subtitle class="mt-0 mb-0">
              <h4 class="mb-0 fw-bold color-black">
                CNPJ: {{ empresa.cnpj | mask: '00.000.000/0000-00'}}
              </h4>
                <p class="card-title pb-0">
                  <small class="text-muted" *ngIf="empresa.pendentesManifestacao > 0"><b class="color-secondary">Manifestações pendentes</b></small>
                  <small class="text-muted" *ngIf="empresa.pendentesManifestacao <= 0"><b >Nenhuma manifestação pendente</b></small>
                  <br>
                  <small class="text-muted" *ngIf="empresa.dataExpiracaoCertificado">
                    <span *ngIf="!empresa.certificadoExpirado">Certificado expira em: </span>
                    <span class="color-secondary" *ngIf="empresa.certificadoExpirado">Certificado expirado em: </span>
                    {{ empresa.dataExpiracaoCertificado}}</small>
                </p>
            </mat-card-subtitle>
          </mat-card-title-group>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<div class="row">
  <app-paginator [totalElementos]="totalItens" [pagina]="pagina" [tamanho]="tamanho" [exibirSelecaoTamanho]="false"
                 (paginacao)="paginar($event)"></app-paginator>
</div>


<div class="modal modal-backdrop modal-form" id="exampleModal" role="dialog" [ngStyle]="{'display':displayStyleModal}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="needs-validation" [formGroup]="formulario" novalidate>
        <div class="modal-body">
            <mat-form-field class="input-mobile" appearance="fill">
              <mat-label>CNPJ ou Razão Social</mat-label>
              <input matInput formControlName="filtro">
            </mat-form-field>
            <mat-form-field appearance="fill" class="select-sem-label input-mobile">
              <mat-select id="status" formControlName="status">
                <mat-option *ngFor="let stat of status"  [value]="stat.value">{{ stat.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="w-100 flex manifestacao-pendente justify-content-center">
              <div class="w-auto manifestacao-pendente align-self-center p-0 pe-2">
                Manifestações Pendentes
              </div>
              <div class="w-auto align-self-center">
                <app-toggle [selecionado]="formulario.controls.exibirApenasPendentes?.value"
                            (clickToggle)="toggleManifestacaoPendente()" [isPink]="false"></app-toggle>
              </div>
            </div>
            <button mat-raised-button color="primary" class="bt-cadastro bt-modal-100" type="button" (click)="aplicandoFiltros()"
                    >
              <app-icone-lupa></app-icone-lupa>
              <label>Filtrar</label>
            </button>
            <button mat-button class="color-primary bt-modal-100" type="button" (click)="limparFiltros()">
              <label>Limpar Filtro</label>
            </button>
        </div>
      </form>
    </div>
  </div>
</div>
