import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoadingConstant} from "../../../../_core/model/loading-constant";
import {ManifestacaoService} from "../../../../_service/web/manifestacao.service";
import {ManifestacaoDto} from "../../../../_core/model/manifestacao-dto";
import {MensagemService} from "../../../../_service/mensagem.service";

@Component({
  selector: 'app-modal-lista-manifestacao',
  templateUrl: './modal-lista-manifestacao.component.html',
  styleUrls: ['./modal-lista-manifestacao.component.css']
})
export class ModalListaManifestacaoComponent implements OnInit {
  @Input() displayStyle = "none";
  @Output() closePopup = new EventEmitter();

  _chave = ''
  manifestacoes: ManifestacaoDto[] = [];
  constructor(
    public manifestacaoService: ManifestacaoService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
  }

  @Input()
  set chave(chave: string) {
    if (chave) {
      this._chave = chave;
      this.buscarManifestacoes(chave);
    }
  }

  buscarManifestacoes(chave: string) {
    LoadingConstant.loading = true;
    this.manifestacaoService.listaManifestacao(chave).then( response => {
      this.manifestacoes = response;
      LoadingConstant.loading = false;
    }).catch( error => {
      this.mensagemService.falha(error);
      LoadingConstant.loading = false;
    })
  }


  fecharPopup() {
    this.chave = '';
    this.manifestacoes = [];
    this.displayStyle = "none";
    this.closePopup.emit();
  }
}
