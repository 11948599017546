import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-senha-alterada',
  templateUrl: './senha-alterada.component.html',
  styleUrls: ['./senha-alterada.component.css']
})
export class SenhaAlteradaComponent implements OnInit {
  @Output() retornarLogin: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  retornaLogin() {
    this.retornarLogin.emit();
  }
}
