import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EmpresasService} from "../../_service/web/empresas.service";
import {MensagemService} from "../../_service/mensagem.service";
import {LoadingConstant} from "../../_core/model/loading-constant";
import {MensagemSucessoDTO} from "../../_core/model/mensagem-sucesso-dto";
import {EmpresaDto} from "../../_core/model/empresa-dto";

@Component({
  selector: 'app-modal-certificado',
  templateUrl: './modal-certificado.component.html',
  styleUrls: ['./modal-certificado.component.css']
})
export class ModalCertificadoComponent implements OnInit {
  @Input() displayStyle = "none";
  @Input() dataExpiracao: string | null = null;
  @Input() empresa: EmpresaDto | null = null;
  @Output() closePopup = new EventEmitter();
  // @ts-ignore
  arquivoInput: File | null;
  esconderSenha = true;
  arquivo: File | null = null;
  formulario: FormGroup = this.formBuilder.group({
    arquivo: [null, Validators.required],
    senha: [null, Validators.required],
    nome: ['']
  })
  constructor(
    public formBuilder: FormBuilder,
    public empresaService: EmpresasService,
    public mensagemService: MensagemService
  ) { }

  ngOnInit(): void {
  }

  fecharPopup() {
    this.formulario.reset();
    this.arquivoInput = null;
    this.displayStyle = "none";
    this.closePopup.emit();
  }

  selecionarArquivo(file: File) {
    this.arquivoInput = file
    this.formulario.patchValue({
      arquivo: file
    });
  }

  removerArquivo() {
    this.arquivoInput = null;
    this.formulario.patchValue({
      arquivo: null
    });
  }

  salvar() {
    if (this.formulario.valid && this.empresa != null) {
      LoadingConstant.loading = true;
        this.empresaService.inserirCertificado(this.formulario.value, this.empresa.id).then( (response: MensagemSucessoDTO) => {
          this.mensagemService.sucesso(response.mensagem);
          this.formulario.reset();
          this.fecharPopup();
          LoadingConstant.loading = false;
        }).catch( error => {
          this.mensagemService.falha(error);
          LoadingConstant.loading = false;
        })
    }
  }

  isLoading() {
    return LoadingConstant.loading;
  }
}
