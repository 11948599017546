import { Injectable } from '@angular/core';
import {FormControl} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class ValidacaoSenhaService {

  constructor() { }

  static confirmacaoNovaSenha(controle: FormControl): { confirmacao: boolean } | null {
    if (controle.value.novaSenha !== controle.value.confirmacaoNovaSenha) {
      return {confirmacao: true};
    } else {
      return null;
    }
  }

  static confirmacaoAlterarSenha(controle: FormControl): { nova: boolean } | null {
    if (controle.value.novaSenha == controle.value.senha) {
      return {nova: true};
    } else {
      return null;
    }
  }
}
