import {Injectable} from '@angular/core';
import {Observable, Subject, Subscription, timer} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {shareReplay, switchMap, takeUntil} from "rxjs/operators";
import {RefreshTokenResponse} from "../_core/model/refresh-token-response";
import {StorageService} from "./storage.service";
import {environment} from "../../environments/environment";
import {InatividadeService} from "./inatividade.service";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private myTokenCache: Observable<RefreshTokenResponse> | null = null;
  private readonly CACHE_SIZE = 1;
  private readonly REFRESH_INTERVAL = 60000; //45 sec
  private myTokenSubscription: Subscription | null = null;
  private stopTimer: Subject<boolean> | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
    private inatividadeService: InatividadeService
  ) { }

  set token(token: string) {
    this.storageService.setTokenUsuario(token);
    if(token && !this.myTokenCache) {
      const myStopTimer = new Subject<boolean>();
      this.stopTimer = myStopTimer;
      const myTimer = timer(0, this.REFRESH_INTERVAL);
      this.myTokenCache = myTimer.pipe(
        takeUntil(myStopTimer),
        switchMap(() => this.refreshToken()),
        shareReplay(this.CACHE_SIZE)
      );
      this.myTokenSubscription = this.myTokenCache.subscribe(newToken =>
        this.storageService.setTokenUsuario(newToken.refreshToken), () => null
      );
    }
  }

  private refreshToken(): Observable<RefreshTokenResponse> {
    return this.http.get<RefreshTokenResponse>(environment.api.url + 'usuarios/refreshToken');
  }

  public clear() {
    if(this.myTokenSubscription) {
      this.myTokenSubscription.unsubscribe();
    }
    if(this.stopTimer) {
      this.stopTimer.next(true);
      this.stopTimer = null;
    }
    this.myTokenCache = null;
    this.storageService.limparEmpresa();
    this.storageService.limparUsuario();
    this.inatividadeService.clearTimer();
    this.router.navigate(['login']);
  }
}
