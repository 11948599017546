export class UF {
  ufs = [
    {value: 'AC', label: 'AC'},
    {value: 'AL', label: 'AL'},
    {value: 'AP', label: 'AP'},
    {value: 'AM', label: 'AM'},
    {value: 'BA', label: 'BA'},
    {value: 'CE', label: 'CE'},
    {value: 'DF', label: 'DF'},
    {value: 'ES', label: 'ES'},
    {value: 'GO', label: 'GO'},
    {value: 'MA', label: 'MA'},
    {value: 'MT', label: 'MT'},
    {value: 'MS', label: 'MS'},
    {value: 'MG', label: 'MG'},
    {value: 'PA', label: 'PA'},
    {value: 'PB', label: 'PB'},
    {value: 'PR', label: 'PR'},
    {value: 'PE', label: 'PE'},
    {value: 'PI', label: 'PI'},
    {value: 'RJ', label: 'RJ'},
    {value: 'RN', label: 'RN'},
    {value: 'RS', label: 'RS'},
    {value: 'RO', label: 'RO'},
    {value: 'RR', label: 'RR'},
    {value: 'SC', label: 'SC'},
    {value: 'SP', label: 'SP'},
    {value: 'SE', label: 'SE'},
    {value: 'TO', label: 'TO'}
  ];
// {value: '12', label: 'AC'},
// {value: '27', label: 'AL'},
// {value: '16', label: 'AP'},
// {value: '13', label: 'AM'},
// {value: '29', label: 'BA'},
// {value: '23', label: 'CE'},
// {value: '53', label: 'DF'},
// {value: '32', label: 'ES'},
// {value: '52', label: 'GO'},
// {value: '21', label: 'MA'},
// {value: '51', label: 'MT'},
// {value: '50', label: 'MS'},
// {value: '31', label: 'MG'},
// {value: '15', label: 'PA'},
// {value: '25', label: 'PB'},
// {value: '41', label: 'PR'},
// {value: '26', label: 'PE'},
// {value: '22', label: 'PI'},
// {value: '33', label: 'RJ'},
// {value: '24', label: 'RN'},
// {value: '43', label: 'RS'},
// {value: '11', label: 'RO'},
// {value: '14', label: 'RR'},
// {value: '42', label: 'SC'},
// {value: '35', label: 'SP'},
// {value: '28', label: 'SE'},
// {value: '17', label: 'TO'}
}
